
.newsletter-panel {
  background-color: $medium-blue;


  color: #ffffff;

  //background-image:url(#{$images}/optimized/sign-up_bg.jpg);
  //background-repeat: no-repeat;
  //background-size: cover;
  //background-position: center center;

  padding-top: 1em;
  padding-bottom: 1em;

  .email {
    font-size: 2em;
    svg {
      color: $light-blue;
      opacity: 0.6;
    }
  }


  .buttons {
    //background-color:$light-blue;
    & > div {
      //margin: 0em 4em;

      a {

        //display: inline-block;
        background-color: $light-blue;

        border-radius: 0.5em;

        text-align: left;

        width: 100%;
        padding: 1em;

        height: auto;
        font-size: 120%;

        .button-text {
          padding: 0.2em;
        }
        span {
          font-size: 130%;
        }


      }
    }

  }

  ul.highlights {
    font-size: 1.2em;
    list-style: none;
    margin: 0;
    padding: 0.6em 0 !important;

    font-weight: bold;

    li {

      list-style-type: none !important;
      margin: 0;
      padding: 0;
      i {
        font-size: 0.2em;
      }
    }
  }

}


@media (max-width: 40em) {
  // Newsletter panel styling
  .newsletter-panel {
    h2 {
      padding-bottom: 0.5em;
    }
  }

}


//@media (min-width: 52em) {
//
//  .newsletter-panel {
//
//    //padding-left: 0;
//    //padding-right: 0;
//    //padding-bottom: 0.5rem;
//    text-align: left;
//
//
//  }
//}
