@charset "UTF-8";

// Arts Club Styles
body {
	&.domain-academymusicgroup-com-artsclubliverpool {
		.accordion-page-container {
			.content {
				.field-name-field-accordion-item {
					.field-name-field-accordion-title {
						color:$arts-medium-grey;
					}
				}
			}
		}
	}
}

.accordion-page-container {
	.top-hero-image {
		position:relative;
		img {
			object-fit: cover;
			height: 200px;
		}
		.hero-content-wrap {
		    width: 100%;
		    margin: auto;
		    position: absolute;
		    top: 0;
		    left: 0;
		    bottom: 0;
		    right: 0;
		    height: 45%;
			h1 {
				color:#FFF;
				font-size:36px;
				text-align:center;
				margin:10px 0;
			}
			.tagline {
				color:#FFF;
				text-align:center;
				display:none;
			}
		}
	}
	.accordion-list {
		.body-copy {
			width:100%;
			margin:0 auto;
			p {
				font-size:90%;
			}
		}
		>.content {
			width:100%;
			margin:0 auto;
		}
	}
	.content {
		.field-name-field-accordion-item {
			>.field-items {
				> .field-item {
					padding:2em 0;
					border-bottom:1px solid #e6e6e6;
					&:last-child {
						border:none;
					}
				}
			}
			.field-name-field-accordion-title {
				font-size:18px;
				color:$medium-blue;
				font-family:$bold-font;
				position:relative;
				cursor:pointer;
				padding-right:50px;
				&:after {
					font-family: "amg" !important;
					content: attr(data-icon);
					font-style: normal !important;
					font-weight: normal !important;
					font-variant: normal !important;
					text-transform: none !important;
					speak: none;
					line-height: 1;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
			  		content: "f";
			  		position:absolute;
			  		right:0;
			  		top:5px;
				}
				&.active-accordion {
					&:after {
						content: "d";
					}
				}
			}
			.field-name-field-accordion-text {
				display:none;
				color:$grey-font;
				padding-top:2em;
			}
		}
	}
}

@media (min-width: 40em) {
	.accordion-page-container {
		.top-hero-image {
			img {
				height:300px;
			}
			.hero-content-wrap {
				h1 {
					font-size:75px;
					margin:20px 0;
				}
				.tagline {
					display:block;
				}
			}
		}
		.accordion-list {
			.body-copy {
				width:70%;
			}
			>.content {
				width:70%;
				.field-name-field-accordion-item {
					.field-name-field-accordion-title {
						font-size:24px;
					}
				}
			}
		}
	}
}

@media (min-width: 52em) {
.accordion-page-container {
	.top-hero-image {
		img {
			height:400px;
		}
	}
}
}
