@charset "UTF-8";


#content {
  .event-item {
    .event-date {
      //text-align: center;
      padding-left: 1em;

      span {
        color: $light-blue;
        font-weight: bold;
        line-height: 1;
      }

      .day {
        margin-bottom: 3px;
      }

      .date {
        font-size: 54px;
        font-family: $regular-font;
      }
    }

    .event-info {
      color: #808080;

      h3 {
        margin-bottom: 3px;

        a {
          text-decoration: none;
          color: $dark-blue;
          font-family: $bold-font;
        }
      }

      .field-name-field-support-acts {
        font-size: 90%;
        margin-bottom: 3px;

        .field-label {
          display: none;
        }
      }



      .field-name-field-event-location {
        font-size: 90%;
        margin-bottom: 3px;
      }
    }

    .event-actions {
      padding-right: 1em;
    }

  }
}

.field-name-field-support-acts {
  .field-label {
    color: $dark-blue;
  }
}



.event-header {
  .flag-wishlist {
    height: 36px;
    line-height: 36px;
    font-size: 80%;
    background-color: #FFF;
    color: $light-blue;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 0 10px;
    font-family: $bold-font;
    float: right;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    transition: all 0.1s linear;
    width: 100%;
    margin-bottom: 8px;

    a {
      color: $light-blue;
    }

    &:hover {
      background-color: $light-blue;
      border-color: $light-blue;
      color: #FFF;

      a {
        color: #FFF !important;
      }
    }
  }
}


.page-events {
  &.page-events-month-grid {
    #content {
      .top-panel {
        .view-options {
          a {
            &.grid-view {
              color: $light-blue;
            }

            &.list-view {
              color: #ccc;
            }
          }
        }
      }
    }
  }

  .date-heading {
    h3 {
      float: left;
    }
  }

  .top-panel {
    border-bottom: 2px solid $light-blue;
    margin-bottom: 20px;

    h1 {
      font-size: 42px;
    }

    .view-options {
      //display:none;
      a {
        float: left;
        color: #ccc;
        font-size: 30px;

        i {
          display: inline-block;

          &.icon-calendar {
            &:before {
              font-size: 27px;
              vertical-align: top;
            }
          }
        }

        &.grid-view {
          margin-right: 10px;
          color: #ccc;
        }

        &.list-view {
          color: $light-blue;
        }
      }
    }
  }

  .calendar-view {
    .view-content {
      .item-list {
        &:first-child {
          h3 {
            margin-top: 0;
          }
        }

        > h3 { // Month and year title
          margin: 2em 0 1em;
          padding-left: 0.9em;
          padding-bottom: 0.4em;
          font-size: 1.6em;
          position: relative;
          border-bottom: 2px solid #0b1b54;
        }

        ul {
          padding-left: 0;

          li {
            display: inline-block;
            width: 100%;
            border-bottom: 1px solid #e6e6e6;
            margin: 0;
          }
        }
      }
    }

    .bottom-pager {
      .date-heading {
        display: none;
      }

      .pager {
        .left {
          margin-left: 0;
        }

        li {
          text-transform: capitalize;
        }
      }
    }
  }

  .view-header {
    margin-bottom: 1em;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 1em;
  }

  .bottom-pager {
    margin-bottom: 25px;
    margin-top: 0;
  }
}

.youtube-panel {
  .header {
    margin-bottom: 10px;
    border-bottom: 1px solid $medium-blue;
  }
}

.single-day[iehint="1"] {
  display: none;
}

// Events Calendar View
.page-events-month-grid {
  #content {
    .calendar-calendar {
      .month-view {
        table.full {
          thead {
            display: none;
          }

          tbody {
            tr {
              background-color: #f2f2f2;

              td {
                border: 5px solid #fff;
                border-bottom: 10px solid #fff;
                border-top: 0;
                box-shadow: none;
                height: 200px;
                max-height: 200px;

                &.today {
                  border: 5px solid #fff;
                  border-bottom: 10px solid #fff;
                  border-top: 0;
                }

                &.empty {
                  background-color: #fafafa;

                  &:before {
                    background-color: #fafafa;
                    color: #ccc;
                  }

                  .day {
                    color: #ccc;
                  }
                }

                .day {
                  color: $light-blue;
                  font-size: 18px;
                  font-family: $bold-font;
                  font-weight: normal;
                  float: left;

                  a {
                    color: $light-blue;
                    font-family: $bold-font;
                    font-weight: normal;
                  }
                }

                &.multi-day {
                  .calendar {
                    &.monthview {
                      .contents {
                        position: initial;
                        width: auto;
                      }

                      .cutoff {
                        display: none;
                      }
                    }
                  }
                }
              }

              &.single-day, &.multi-day {
                .monthview {
                  height: auto;
                  background: none;
                  margin-bottom: 10px;

                  a {
                    color: $medium-blue;
                    font-size: 1em;
                    font-family: $bold-font;
                    font-weight: normal;
                  }
                }
              }

              &.date-box {
                background-color: #f2f2f2;
              }
            }
          }
        }
      }
    }
  }
}

/* BEGIN Calendar responsive CSS - tablet portrait */
.page-events-month-grid {
  #content {
    .calendar-calendar {
      .month-view {
        table.full {
          tbody {
            td.today {
              background-color: $medium-blue;

              &:before {
                background-color: $medium-blue;
              }

              .monthview {
                a {
                  color: #FFF;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Force table to not be like tables anymore */
.calendar-from-month table,
.calendar-from-month tbody,
.calendar-from-month th,
.calendar-from-month td,
.calendar-from-month tr {
  display: block;
}

/* Hide table headers (but not display: none;, for accessibility) */
.calendar-from-month thead,
.calendar-from-month thead tr,
.calendar-from-month tr.date-box {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.calendar-from-month tr td.no-entry {
  border-bottom: 1px solid #ccc;
}

.calendar-from-month td:before { /* Format & Label the day headings */
  /* Now like a table header */
  width: 100%;
  white-space: nowrap;
  clear: both;
  display: block;
  box-sizing: border-box;
  background-color: #f2f2f2;
  padding: 10px 10px 0 10px;
  content: attr(headers) "\0020" attr(data-day-of-month);
  height: auto;
  color: #32a3d9;
  text-align: left;
  font-family: $bold-font;
}

.calendar-from-month td.multi-day:before {
  content: attr(headers) "\0020" attr(data-day-of-month);
}

.calendar-calendar.calendar-from-month .month-view .full tr.multi-day {
  height: 200px;
  max-height: 200px;
}

.calendar-from-month td.today,
.calendar-from-month .full tr td.single-day.today,
.calendar-from-month .full tr td.multi-day.today {
  border-top: 2px solid #0074ab;
  -webkit-box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.25);
}

.calendar-from-month .full td, .calendar-calendar .month-view .full td, .calendar-calendar .week-view .full td, .calendar-calendar .day-view td {
  padding: 0;
}

.calendar-from-month td,
.calendar-from-month td.calendar-agenda-items {
  /* Behave  like a "row" */
  border: none;
  position: relative;
  width: 100%;
}

.calendar-from-month td.no-entry,
.calendar-from-month td.empty {
  display: none;
}

.calendar-from-month td.calendar-agenda-items div.calendar {
  padding: 0;
}

.calendar-from-month div.calendar,
.calendar-from-month .date-display-single,
.calendar-from-month td span.date-display-single {
  font-size: 14px;
  font-weight: normal;
  margin: 0 !important;
}

.calendar-from-month .inner .item {
  padding: 10px 10px 0;
}

.calendar-from-month .inner .item:last-child {
  padding-bottom: 10px;
}

.calendar-from-month .full tr.single-day .no-entry, .calendar-from-month .full tr.multi-day .no-entry {
  height: 44px !important;
  line-height: 24px;
}

.calendar-from-month .full tr.single-day .no-entry .inner,
.calendar-from-month .full tr.multi-day .no-entry .inner,
.calendar-from-month .no-entry .inner {
  height: auto !important;
  line-height: 1px;
}

.calendar-from-month .inner .views-field-title-field {
  display: inline-block;
}

/* END Calendar responsive CSS - tablet portrait */


#content {
  .view-calendar {
    .view-empty {
      border-bottom: 1px solid #e6e6e6;
      padding-bottom: 2em;
      padding-top: 1em;
      text-align: center;

      p {
        margin: 0;
        font-size: 1.2em;
        color: #0b1b54;
        font-weight: 600;
      }
    }

    ul {
      padding-left: 0;

      &.pager {
        li {
          padding: 0;
        }
      }

      li {
        list-style-type: none;
        padding: 30px 0 15px 0;

        &.views-row {
          display: inline-flex;
        }

        &.prev a, &.next a {
          height: 36px;
          line-height: 36px;
          padding: 0 10px;
          z-index: 999;
          position: relative;
        }
      }
    }
  }
}

/** Nosto Styling **/
#content {
  .nosto-header {
    margin-bottom: 2em;
  }

  .nosto-product-list {
    .nosto-product-list-item {
      list-style-type: none;

      .nosto-product-info {
        .event-details {
          padding: 0;
        }
      }

      .event-date {
        font-size: 50px;
        color: $light-blue;
        font-family: $bold-font;
        text-align: center;
        line-height: 1;

        &.time {
          font-size: 17px;
          line-height: 1;
        }
      }
    }
  }
}

#content {
  .bottom-pager {
    .pager {
      padding-left: 0;
    }
  }
}

#content {
  .related-gallery-event-block {
    .view-gallery {
      .view-content {
        .views-row {
          height: auto;
        }
      }
    }
  }
}


.events-calendar {
  .ajax-progress .throbber {
    position: relative;
    top: 2px;
    margin: 0 5px;
  }
}

h2.buy-wrap {
  font-size: 100%;
}

h3.location-wrap {
  font-size: 100%;
  margin: 0;
  color: gray;
  font-family: $regular-font;
}

@media (min-width: 40em) {
  .event-header {
    .flag-wishlist {
      width: 120px;
      margin-left: 1em;
    }
  }
  .page-events-month-grid {
    .calendar-calendar {
      .month-view {
        table.full {
          tbody {
            tr {
              td {
                width: 25%;
                float: left;
                background-color: #f2f2f2;
                height: 250px;
                max-height: 250px;
                overflow: hidden;

                &.no-entry {
                  height: 250px !important;
                  line-height: 1 !important;
                }
              }
            }
          }
        }
      }
    }
  }
  #content {
    .view-calendar {
      ul {
        .all-events-wrap {
          position: absolute;
          text-transform: uppercase;
          font-size: 120%;
        }
      }
    }
  }
  .calendar-from-month td.no-entry,
  .calendar-from-month td.empty {
    display: block;
  }
  #content {
    .related-gallery-event-block {
      .view-gallery {
        .view-content {
          .views-row {
            width: 24.2%;
            margin-right: 1%;
            max-height: 118px;
            overflow: hidden;
            min-height: 0;
            margin-bottom: 0.5em;

            &:nth-of-type(4n+4) {
              margin-right: 0;
            }

            &:nth-of-type(3n+3) {
              margin-right: 1%;
            }
          }
        }
      }
    }
  }
}


@media (min-width: 52em) {
  .page-events {
    #content {
      .calendar-view {
        .view-content {
          .item-list {
            ul {
              -webkit-flex-wrap: wrap;
              -ms-flex-wrap: wrap;
              flex-wrap: wrap;
              display: -webkit-box;
              display: -webkit-flex;
              display: -ms-flexbox;
              display: flex;

              li {
                //width: 48%;
                margin: 0 1%;
              }
            }
          }
        }
      }
    }
  }
  .youtube-panel {
    .inner {
      height: 610px;
    }
  }
  .related-gallery-event-block {
    .inner {
      height: 610px;
    }
  }
  .page-events-month-grid {
    .calendar-calendar {
      .month-view {
        table.full {
          tbody {
            tr {
              td {
                width: 14.2%;
                float: left;
                background-color: #f2f2f2;
                height: 200px;
                max-height: 200px;

                &.no-entry {
                  height: 200px !important;
                }
              }
            }
          }
        }
      }
    }
  }
  #content {
    .nosto-product-list {
      .nosto-product-list-item {
        padding: 30px 0 15px;
        margin: 0;
      }
    }
  }
}

body {
  &.page-events-all {
    .all-events-wrap {
      display: none;
    }
  }

  .view-display-id-announced_listings {
    table {
      width: 100%;
    }
  }
}

@media (max-width: 40em) {
  .page-events-month {
    .px3.view-calendar {
      padding-left: 0.5em;
      padding-right: 0.5em;
    }
  }
  .calendar-calendar.calendar-from-month {
    .month-view {
      .full {
        tr {
          &.multi-day {
            height: auto;
            max-height: inherit;
          }
        }
      }
    }
  }
  #content .view-calendar {
    ul {
      li {
        padding: 1.5em 0;

        .btn.sml {
          //margin: 0 auto;
          height: auto;
          padding: .8em 8px;
          line-height: 1.3;
          font-size: .7em;
          //max-width: 100px;
          float: none;
          display: block !important;
          //&.priority, &.more {
          //	margin-bottom: .5em;
          //}
        }
      }
    }
  }
  #content {
    .nosto-product-list {
      .nosto-product-list-item {
        .event-date {
          font-size: 36px;

          &.time {
            font-size: 11px;
          }
        }

        .nosto-product-info {
          padding-left: 0.5em;

          .event-details {
            font-size: 0.8em;

            .nosto-product-name {
              font-size: 1.3em;
            }
          }

          .buttons {
            a {
              min-width: auto;

              &.buy-btn {
                margin-bottom: 0.5em;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 64em) {
  .event-header {
    .flag-wishlist {
      width: auto;
    }
  }
}
