

ul.amg-user-menu {
  li, li:last-child {
    margin: 0 0 0 1em;



    a {
      font-family: "FrutigerLTPro-Bold",sans-serif;
      font-size: 90%;

      color: #ffffff;

      &:hover,
      &.active {
        color: #cccccc;
      }
    }
  }
}