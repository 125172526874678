@charset "UTF-8";

.list-item-container {
	.top-hero-image {
		position:relative;
		img {
			object-fit: cover;
			height: 200px;
		}
		.hero-content-wrap {
		    width: 100%;
		    margin: auto;
		    position: absolute;
		    top: 0;
		    left: 0;
		    bottom: 0;
		    right: 0;
		    height: 45%;
			h1 {
				color:#FFF;
				font-size:36px;
				text-align:center;
				margin:10px 0;
			}
			.tagline {
				color:#FFF;
				text-align:center;
				display:none;
			}
		}
	}
	.body-copy {
		p {
			font-size:90%;
		}
	}
	.content {
		.field-name-field-list-item {
			>.field-items {
				float:left;
				width:100%;
				>.field-item {
					width:100%;
					float:left;
					padding-bottom:2em;
				}
			}
		}
		.field-item {
			.field-collection-view {
				color:$grey-font;
				font-size:90%;
				margin:0;
				img {
					height:auto;
				}
				.field-name-field-list-item-title {
					color:$medium-blue;
					font-size:24px;
					font-family:$bold-font;
					margin-bottom:5px;
				}
			}
		}
	}
}

@media (min-width: 40em) {
.list-item-container {
	.top-hero-image {
		img {
			height:300px;
		}
		.hero-content-wrap {
			h1 {
				font-size:48px;
				margin:20px 0;
			}
			.tagline {
				display:block;
			}
		}
	}
	.content {
		.field-name-field-list-item {
			>.field-items {
				>.field-item {
					width:24%;
					margin-right:1.33%;
					&:nth-of-type(4n+4) {
						margin:0;
					}
				}
			}
		}

	}
}
}

@media (min-width: 52em) {
.list-item-container {
	.top-hero-image {
		img {
			height:400px;
		}
	}
}
}
