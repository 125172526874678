@charset "UTF-8";

body {
  &.page-wknd {
    background-image: url(#{$images}/optimized/WKND-fireplace.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100%;

    header#header .top-links {
      background-color: transparent;
    }
  }

  &.page-new-years-eve-2017 {
    background-image: url(#{$images}/optimized/nye-fireplace.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100%;
    background-color: #151618;

    header#header .top-links {
      background-color: transparent;
    }
  }
}


.front, body.homepage-panel {
  // Strange grey box issue fix
  .flexslider .slides > li {
    overflow: hidden;
  }

  #hero-head {
    overflow-x: hidden;
  }

  // Youtube channel styling
  #youtubechannel-player {
    margin-bottom: 0;
  }

  #youtubechannel-list ul {
    padding: 0;
  }

  #youtubechannel-list {
    padding-left: .5em;
    padding-right: .5em;
    padding-top: 1em;

    ul {
      display: block;

      li {
        text-align: center;
      }
    }

    p {
      font-size: 0.8em;
      margin: 1em .5em;
      text-align: center;
    }

    img {
      border-color: rgba(255, 255, 255, 0.15);
    }
  }

  .top-col-right-wrap {
    padding: 0 0.5em;
  }

  .notifications {
    position: relative;

    .close-notification {
      position: absolute;
      right: 30px;
      top: 30px;
      z-index: 99;
      font-size: 30px;
      cursor: pointer;

      &:after {
        font-family: "amg" !important;
        content: attr(data-icon);
        font-style: normal !important;
        font-weight: 700 !important;
        font-variant: normal !important;
        text-transform: none !important;
        speak: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "t";
        color: #FFF;
      }
    }

    .inside {
      padding: 0 0.5em;
    }

    .pane-bundle-notification-panel {
      background-color: $light-blue;
      margin-top: 0.5em;
      color: #FFF;
      float: left;
      width: 100%;
      padding: 2em;
      text-transform: uppercase;
      padding-right: 50px;

      h2 {
        float: left;
        margin: 0;
        font-size: 18px;
        line-height: 18px;
      }

      p {
        color: #FFF;
      }

      a {
        color: $dark-blue !important;
      }

      .pane-content {
        float: left;
        font-size: 16px;
        line-height: 16px;
        padding: 0;
        line-height: 1.2;

        .field-label {
          color: $medium-blue;
          font-family: $bold-font;
          padding: 0 !important;
          font-weight: normal;
          margin-right: 0.5em;
          margin-bottom: 10px;
        }
      }
    }
  }

  .group-event-link-wrap {
    float: left;
    z-index: 9;
    position: relative;
  }

  // Homepage hero slider
  .hero-header {
    overflow: hidden;

    .event-image {
      &:after {
        transition: opacity .3s linear;
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        background-color: #00032d;
        //opacity: 0.3;
      }
    }

    .flexslider {
      border: 0;
      position: relative;
      margin-bottom: 0;
    }

    .slides {
      font-size: 100%;
      padding: 0;

      > li {
        position: relative;
      }
    }

    .btn {
      //width:120px;
    }

    .event-header {
      max-height: 611px;
    }

    .event-details {
      position: absolute;
      bottom: 30px;
      color: #FFF;
      z-index: 9;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .event-wrap {
      position: relative;
    }

    .body-copy {
      color: #FFF;
    }

    .event-date {
      background-color: $light-blue;
      margin-bottom: 60px;
      font-size: 17px;
      padding-right: 12px;
    }

    .event-info {
      position: absolute;
      right: 2em;
      bottom: 0;
    }

    p {
      color: #FFF;
      margin: 0;
    }

    h1 {
      color: #FFF;
      font-size: 36px;
      text-align: right;
    }

    .flex-control-nav {
      bottom: 20px;

      li {
        margin: 0 10px;

        a {
          background: rgba(0, 0, 0, 0.05);
          border: 2px solid #dfdfdf;
          box-shadow: none;
          border-radius: 10px !important;

          &.flex-active {
            background-color: #FFF;
            border-color: #999999;
          }
        }
      }
    }
  }

  .pane-calendar {
    position: relative;

    .view-header {
      padding: 30px 0;

      h3 {
        text-align: left;
      }
    }
  }

  #content {
    .view-calendar {
      .view-content {
        .item-list {
          ul {
            border-top: 1px solid $grey-border;
            padding-left: 0;
            margin: 20px 0.5em 0;
            padding-left: 0;
          }
        }
      }

      .item-list {
        ul {
          &.pager {
            padding-left: 0;
            margin-top: 20px;
            margin-bottom: 0;
          }
        }
      }

      .bottom-pager {
        .date-heading {
          display: none;
        }
      }

      .view-header {
        padding: 20px 0;

        .pager {
          display: none;
        }

        .date-nav-wrapper {
          .date-nav {
            padding-bottom: 0;
            float: left;
          }
        }
      }
    }
  }

  .homepage-panel {
    .pane-bundle-event-panel {
      .pane-title {
        display: none;
      }
    }

    .pane-bundle-advert {
      img {
        width: 100%;
        object-fit: cover;
      }
    }

    .pane-bundle-article-panel {
      .field-name-field-article-link {
        color: $light-blue;
        font-family: $bold-font;
        position: relative;

        &:after {
          content: "f";
          font-family: amg !important;
          font-style: normal !important;
          font-weight: 400 !important;
          font-variant: normal !important;
          text-transform: none !important;
          speak: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          display: inline-block;
          position: absolute;
          right: 0;
          top: 2px;
        }
      }

      a {
        &.read-more-link {
          &:hover {
            color: $light-blue;
          }
        }
      }
    }
  }

  .panel-amg-homepage {
    .panel-col-last {
      .inside {
        margin: 0 0 0.5em 0.5em;
      }
    }
  }

  .top-house-ad {
    .group-advert-wrap {
      img {
        float: left;
        // width:100%;
        // height:auto;
        height: 250px;
        width: 250px;
      }
    }
  }

  .top-house-ad {
    margin-top: 0.5em;
    background: #000;
  }

  .panel-amg-homepage {
    .house-ads {
      padding: 0 0.5em;

      img {
        height: auto;
      }

      .left-ad-space {
        padding: 0.5em 0;
      }
    }

    .panel-col-first {
      &.top-spotlight {
        .inside {
          margin-right: 0;
          margin-bottom: 0.5em;
        }
      }
    }
  }

  .top-events {
    .panel-panel {
      .inside {
        //height:100%;
        height: 200px;

        .homepage-panel {
          height: 100%;
        }
      }
    }

    .right-top-event {
      .inside {
        .homepage-panel {
          height: 200px;
        }
      }
    }
  }

  .homepage-panel {
    overflow: hidden;
  }

  .pane-bundle-event-panel {
    position: relative;
    height: 100%;

    div {
      height: 100%;
    }

    a.group-event-link-wrap {
      width: 100%;
      height: 100%;

      &:after {
        transition: opacity 0.3s linear;
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        background-color: $dark-blue;
      }

      &:hover {
        &:after {
          opacity: 0.5;
        }

        .event-panel-background {
          .group-event-details {
            bottom: 60px;
            z-index: 99;
          }
        }

        .event-panel-background {
          //background-size:150%;
        }
      }
    }
  }


  .event-panel-background {
    background-size: cover;
    background-position: center;
    position: relative;
    //transition: all 3s linear;
    .group-event-details {
      position: absolute;
      left: 20px;
      right: 20px;
      bottom: 20px;
      color: #FFF;
      transition: bottom 0.2s linear;
      height: auto;

      .field-name-field-event-link {
        color: $light-blue;
        position: absolute;
        bottom: -35px;
        height: auto;
        display: inline-block;
        width: 100%;

        &:after {
          content: "f";
          font-family: "amg" !important;
          font-style: normal !important;
          font-weight: normal !important;
          font-variant: normal !important;
          text-transform: none !important;
          speak: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          display: inline-block;
          position: absolute;
          right: 0;
          top: 4px;
        }

        a {
          color: $light-blue;
        }
      }

      .field-name-field-event-name {
        font-size: 32px;
        line-height: 1.2;
        font-family: $bold-font;
        margin-bottom: 10px;
      }
    }

    img {
      visibility: hidden;
    }
  }

  .article-panel-background {
    background-size: cover;
    position: relative;
    height: 220px;

    .group-article-details {
      position: absolute;
      left: 20px;
      right: 20px;
      bottom: -20px;
      color: #FFF;
      z-index: 999;
      transition: bottom 0.2s linear;

      .icon-chevron-right-after:after {
        font-size: 100%;
        float: right;
      }

      .field-name-field-article-date {
        margin-bottom: 10px;
      }

      .field-name-field-article-title {
        font-size: 32px;
        line-height: 1.2;
        margin-bottom: 10px;
        font-family: $bold-font;
      }

      a {
        color: $light-blue;

        &:hover {
          color: $light-blue !important;
        }
      }
    }

    img {
      visibility: hidden;
    }

    &:after {
      transition: opacity 0.3s linear;
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      background-color: $dark-blue;
    }

    &:hover {
      .group-article-details {
        bottom: 20px;
      }

      &:after {
        opacity: 0.5;
      }
    }
  }

  .event-spotlights {
    padding: 0 1em 1em;
  }

  .events-calendar {
    padding: 1em;

    .more-link {
      .view-all {
        height: 50px;
        line-height: 50px;
        padding: 0 20px;
      }
    }

    .all-listings {
      display: none;
    }

    .view-content {
      .item-list {
        ul {
          li {
            display: inline-block;
            //float:left;
            margin: 0;
            width: 100%;
            border-bottom: 1px solid #e6e6e6;
            //margin:0 1%;
          }
        }
      }
    }

    .date-heading {
      margin: 5px 0;

      h3 {
        font-size: 16px;
        padding-left: 12px;
      }

      span {
        color: $light-blue;
      }
    }
  }


  .youtube-channel, .related-videos-panel {
    background-color: $dark-blue;
    color: #FFF;

    .inside {
      margin: 0;

      h2 {
        margin: 20px;
        color: #FFF;
      }

      p {
        font-size: 90%;
        color: #FFF;
      }
    }
  }

  .bottom-spotlight-row {
    .bottom-spotlight {
      margin-bottom: 0.5em;

      .top-row {
        .inside {
          margin-left: 0;
        }
      }
    }
  }

  .secondary-events {
    .first {
      .inside {
        .homepage-panel {
          height: 200px;
        }
      }
    }

    .second {
      img {
        width: 100%;
      }
    }
  }


  #content .view-calendar ul li.next a, #content .view-calendar ul li.prev a {
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    display: inline-block;

    a {
      font-family: $bold-font;
    }
  }
}

#content {
  .view-calendar {
    ul {
      li {
        &.next {
          a {
            display: inline-block;

            &:after {
              font-family: "amg" !important;
              content: attr(data-icon);
              font-style: normal !important;
              font-weight: normal !important;
              font-variant: normal !important;
              text-transform: none !important;
              speak: none;
              line-height: 1;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              content: "f";
              margin-left: 5px;
              vertical-align: middle;
            }
          }
        }

        &.prev {
          a {
            display: inline-block;

            &:before {
              font-family: "amg" !important;
              content: attr(data-icon);
              font-style: normal !important;
              font-weight: normal !important;
              font-variant: normal !important;
              text-transform: none !important;
              speak: none;
              line-height: 1;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              content: "e";
              margin-right: 5px;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
}

#content {
  .flexslider {
    ul {
      padding: 0;
    }
  }
}

#youtubechannel-list {
  img {
    vertical-align: top;
  }
}

article.node.node-event-calendar.event-container {
  overflow: hidden;
  transform: translateZ(0);
}


.page-amg-landing {
  .hero-header {
    position: relative;
    max-height: 600px;

    .logo {
      position: absolute;
      top: 41%;
      left: 5%;
      height: auto !important;
      width: 230px;
      z-index: 10;
    }

    .pane-bundle-hero-panel img {
      object-fit: cover;
      height: 300px;
      width: 100%;
    }

    .pane-title {
      width: 100%;
      top: 100px;
      position: absolute;
      text-align: center;
      color: #FFF;
      font-size: 32px;
      padding: 10px;
      z-index: 99;
    }

    .field-name-field-panel-hero-text {
      position: absolute;
      top: 60%;
      width: 100%;
      text-align: center;
      color: #FFF;
      padding: 10px;
      display: none;

      .field-items {
        position: relative;
        z-index: 10;
      }
    }
  }

  .field-name-field-cta-small-image, .field-name-field-cta-image {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
      flex-shrink: 0;
      min-width: 100%;
      min-height: 100%;
    }
  }

  .field-name-field-cta-small-image {
    height: 295px;
  }

  .field-name-field-cta-image {
    img {
      max-height: 420px;
      height: auto;
      float: left;
    }
  }

  .field-name-field-cta-description {
    padding: 0 20px 20px;
    color: #FFF;
    float: left;
    width: 100%;
  }

  #content {
    .field-name-field-cta-link {
      float: right;
      margin: 20px;

      a {
        border-radius: 3px;
        background-color: #FFF;
        height: 35px;
        line-height: 35px;
        padding: 0 15px;
        float: left;
        font-size: 80%;
        color: $arts-medium-grey;
        font-family: $bold-font;

        &:hover {
          background-color: $arts-light-grey;
          color: #FFF;
        }
      }
    }

    .group-cta-background-image {
      height: 295px;
      width: 100%;
      position: relative;
      background-size: cover;
    }

    .spotlight-wrap {
      .bottom-spotlight-landing {
        &.panel-col-last {
          .first {
            .inside {
              margin-left: 0;
            }
          }
        }
      }

      .panel-col-last {
        overflow: hidden;

        .spotlight-small {
          .inside {
            margin-left: 0;
          }
        }
      }

      .spotlight-small, .spotlight {
        &.panel-col-last {
          .inside {
            margin-left: 0;
          }
        }

        .inside {
          position: relative;
          margin-bottom: 0;
          height: 295px;
          width: 100%;
          float: left;

          .group-cta-small-details {
            position: absolute;
            color: #FFF;
            left: 20px;
            z-index: 9;
            bottom: 20px;

            .field-name-field-cta-small-title {
              font-family: $bold-font;
              font-size: 28px;
            }

            a {
              color: #FFF;
            }
          }
        }
      }

      .spotlight {
        .inside {
          background-color: $arts-medium-grey;
          height: 600px;
          float: left;
        }
      }
    }
  }

  a.group-cta-small-link-wrap {
    position: relative;
    width: 100%;
    float: left;

    .group-cta-background-image {
      &:after {
        transition: opacity 0.3s linear;
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        background-color: #1a1a1a;
      }
    }

    &:hover {
      .group-cta-background-image {
        &:after {
          opacity: 0.5;
        }
      }
    }
  }

  a.group-cta-link-wrap {
    position: relative;
    float: left;

    .field-name-field-cta-image {
      position: relative;

      &:after {
        transition: opacity 0.3s linear;
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        background-color: #1a1a1a;
      }
    }

    img {
      transition: all 0.3s;
    }

    &:hover {
      .field-name-field-cta-image {
        &:after {
          opacity: 0.5;
        }
      }

      img {
        transform: scale(1.1);
      }
    }
  }

  .field-name-field-cta-title {
    font-family: $bold-font;
    font-size: 28px;
    padding: 20px;
    width: 100%;
    color: #FFF;
    float: left;
  }

  .bottom-spotlight-landing {
    &.panel-col-last {
      .first {
        .inside {
          margin-left: 0;
        }
      }
    }
  }

  .bottom {
    .spotlight-small {
      overflow: hidden;
    }
  }

  #content {
    .view-content {
      .views-row {
        color: $grey-font;

        .views-field-field-venue-name {
          a {
            color: $dark-blue;
          }
        }
      }
    }
  }

  .view-venue-list {
    > .view-content {
      > .views-row {
        width: 100%;
        float: left;
        margin-bottom: 40px;

        img {
          // height: 200px;
          object-fit: cover;
          width: 100%;
        }

        .views-field-field-venue-name {
          color: $medium-blue;
          font-family: $bold-font;
          font-size: 20px;
          margin-bottom: 10px;
          margin-top: 5px;
        }

        .views-field-field-local-address {
          //text-align:left;
          //padding:0 60px;
        }
      }
    }
  }

  .main-view-content {
    &.px3 {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      padding-top: 0.5rem;


    }

    .view-venue-list {
      .views-row {

        padding-right: 0.5rem;

        &:nth-child(4n+4) {
          padding-right: 0;
        }

        .views-field {
          overflow: hidden;
          max-height: 200px;
        }

        .views-field-field-venue-name {
          text-align: left;
          padding-left: 0.5rem;
          padding-top: 1.5rem;
          margin-bottom: 5px;

          a {
            font-family: $bold-font;
          }
        }

        .views-field-field-local-address {
          font-size: 90%;
          text-align: left;
          padding-left: 0.5rem;
          line-height: 1.5;
        }
      }
    }

    .pane-title {
      border-bottom: 1px solid $grey-border;
      padding-bottom: 30px;
      margin-bottom: 30px;
      display: none;
    }
  }

  footer {

    .social-media {
      display: none;
    }

    .menu-1053 {
      display: none;
    }

    .menu-3758 {
      height: 100%;
      margin: 0;
    }
  }

}


.popup-cards h3 {
  color: $light-blue !important;
}

.popup-cards .info .user .username {
  max-width: 65% !important;
}

// Homepage adverts always on top
.front, body.homepage-panel {
  .panel-amg-homepage {
    .panel-col-top {
      .lazyloader-icon {
        z-index: 1;
      }
    }
  }
}


// Backup images for homepage panels
.front.page-academyislington {
  .panel-panel {
    .tall-box {
      .inside.no-content {
        background-image: url(#{$images}/optimized/islington-club-de-fromage300x600.jpg);
        background-size: cover;
      }
    }

    .sml-box {
      .inside.no-content {
        background-image: url(#{$images}/optimized/islington-club-de-fromage300x300.jpg);
        background-size: cover;
      }
    }

    .wide-box {
      .inside.no-content {
        background-image: url(#{$images}/optimized/islington-club-de-fromage600x300.jpg);
        background-size: cover;
      }
    }
  }

  .house-ads {
    .sml-box {
      .inside.no-content {
        background-image: url(#{$images}/optimized/islington-club-de-fromage300x300.jpg);
        background-size: cover;
        overflow: hidden;
      }

      .inside.no-content {
        margin-bottom: 0 !important;
      }
    }

    .wide-box {
      .inside.no-content {
        background-image: url(#{$images}/optimized/islington-club-de-fromage300x600.jpg);
        background-size: cover;
        overflow: hidden;
      }

      .inside.no-content {
        margin-bottom: 0 !important;
      }
    }
  }

  .bottom-spotlight-row {
    .panel-col-last {
      .inside.no-content {
        overflow: hidden;
      }
    }
  }
}

.front.page-academybirmingham {
  .panel-panel {
    .tall-box {
      .inside.no-content {
        background-image: url(#{$images}/optimized/birmingham-propaganda300x600.jpg);
        background-size: cover;
      }
    }

    .sml-box {
      .inside.no-content {
        background-image: url(#{$images}/optimized/birmingham-propaganda300x300.jpg);
        background-size: cover;
      }
    }

    .wide-box {
      .inside.no-content {
        background-image: url(#{$images}/optimized/birmingham-propaganda600x300.jpg);
        background-size: cover;
      }
    }
  }

  .house-ads {
    .sml-box {
      .inside.no-content {
        background-image: url(#{$images}/optimized/birmingham-propaganda300x300.jpg);
        background-size: cover;
        overflow: hidden;
      }

      .inside.no-content {
        margin-bottom: 0 !important;
      }
    }

    .wide-box {
      .inside.no-content {
        background-image: url(#{$images}/optimized/birmingham-propaganda600x300.jpg);
        background-size: cover;
        overflow: hidden;
      }

      .inside.no-content {
        margin-bottom: 0 !important;
      }
    }
  }

  .bottom-spotlight-row {
    .panel-col-last {
      .inside.no-content {
        overflow: hidden;
      }
    }
  }
}

.front.page-academybristol {
  .panel-panel {
    .tall-box {
      .inside.no-content {
        background-image: url(#{$images}/optimized/brisol-ramshackle300x600.jpg);
        background-size: cover;
      }
    }

    .sml-box {
      .inside.no-content {
        background-image: url(#{$images}/optimized/brisol-ramshackle300x300.jpg);
        background-size: cover;
      }
    }

    .wide-box {
      .inside.no-content {
        background-image: url(#{$images}/optimized/brisol-ramshackle600x300.jpg);
        background-size: cover;
      }
    }
  }

  .house-ads {
    .sml-box {
      .inside.no-content {
        background-image: url(#{$images}/optimized/brisol-ramshackle300x300.jpg);
        background-size: cover;
        overflow: hidden;
      }

      .inside.no-content {
        margin-bottom: 0 !important;
      }
    }

    .wide-box {
      .inside.no-content {
        background-image: url(#{$images}/optimized/brisol-ramshackle600x300.jpg);
        background-size: cover;
        overflow: hidden;
      }

      .inside.no-content {
        margin-bottom: 0 !important;
      }
    }
  }

  .bottom-spotlight-row {
    .panel-col-last {
      .inside.no-content {
        overflow: hidden;
      }
    }
  }
}

.front.page-abcglasgow {
  .panel-display {
    .tall-box {
      .inside.no-content {
        background-image: url(#{$images}/optimized/glasgow-propaganda300x600.jpg);
        background-size: cover;
      }
    }

    .sml-box {
      .inside.no-content {
        background-image: url(#{$images}/optimized/glasgow-propaganda300x300.jpg);
        background-size: cover;
      }
    }

    .wide-box {
      .inside.no-content {
        background-image: url(#{$images}/optimized/glasgow-propaganda600x300.jpg);
        background-size: cover;
      }
    }
  }

  .house-ads {
    .sml-box {
      .inside.no-content {
        background-image: url(#{$images}/optimized/glasgow-propaganda300x300.jpg);
        background-size: cover;
        overflow: hidden;
      }

      .inside.no-content {
        margin-bottom: 0 !important;
      }
    }

    .wide-box {
      .inside.no-content {
        background-image: url(#{$images}/optimized/glasgow-propaganda600x300.jpg);
        background-size: cover;
        overflow: hidden;
      }

      .inside.no-content {
        margin-bottom: 0 !important;
      }
    }
  }

  .bottom-spotlight-row {
    .panel-col-last {
      .inside.no-content {
        overflow: hidden;
      }
    }
  }
}

.front {
  .panel-display {
    .tall-box {
      .inside.no-content {
        background-image: url(#{$images}/optimized/nme300x600.gif);
        background-size: cover;
      }
    }

    .sml-box {
      overflow-y: hidden;

      .inside.no-content {
        background-image: url(#{$images}/optimized/work-for-us300x300.png);
        background-size: cover;
        background-position: center;
        height: 300px;
      }
    }

    .wide-box {
      .inside.no-content {
        background-image: url(#{$images}/optimized/giftcards_600x300.jpg);
        background-size: cover;
        height: 300px;
      }
    }
  }

  .bottom-spotlight-row {
    .bottom-row {
      .inside.no-content {
        height: 306px;
      }
    }
  }
}

.front {
  .panel-display {
    .house-ads {
      .sml-box {
        .inside.no-content {
          background-image: url(#{$images}/optimized/work-for-us300x300.png);
          background-size: cover;
          overflow: hidden;
          background-size: contain;
          background-repeat: no-repeat;
          width: 100%;
          height: 0;
          padding-top: 98.5%;
        }

        .inside.no-content {
          margin-bottom: 0 !important;
        }
      }

      .wide-box {
        .inside.no-content {
          background-image: url(#{$images}/optimized/giftcards_600x300.jpg);
          background-size: cover;
          overflow: hidden;
        }

        .inside.no-content {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

.bottom-spotlight-row {
  .panel-col-last {
    .inside.no-content {
      //height:300px;
      overflow: hidden;
    }
  }
}

@media (min-width: 40em) {
  .front .panel-display .left-top-event .panel-col-first.tall-box .inside.no-content {
    height: 600px;
  }
}

@media (max-width: 52em) {
  .front.page-academyislington {
    .panel-display {
      .tall-box {
        .inside.no-content {
          background-image: url(#{$images}/optimized/islington-club-de-fromage300x300.jpg);
          background-size: cover;
        }
      }
    }
  }
  .front.page-academybirmingham {
    .panel-display {
      .tall-box {
        .inside.no-content {
          background-image: url(#{$images}/optimized/birmingham-propaganda300x300.jpg);
          background-size: cover;
        }
      }
    }
  }
  .front.page-academybristol {
    .panel-display {
      .tall-box {
        .inside.no-content {
          background-image: url(#{$images}/optimized/brisol-ramshackle300x300.jpg);
          background-size: cover;
        }
      }
    }
  }
  .front.page-abcglasgow {
    .panel-display {
      .tall-box {
        .inside.no-content {
          background-image: url(#{$images}/optimized/glasgow-propaganda300x300.jpg);
          background-size: cover;
        }
      }
    }
  }
  .front {
    .panel-display {
      .tall-box {
        .inside.no-content {
          background-image: url(#{$images}/optimized/giftcards_300x300.jpg);
          background-size: cover;
        }
      }
    }
  }
}


@media (min-width: 40em) {
  .front, body.homepage-panel {
    .events-calendar {
      .date-heading {
        h3 {
          font-size: 26px;
        }
      }
    }

    .event-container {
      .event-header {
        .event-image {
          img {
            height: 600px;
          }
        }
      }
    }

    .hero-header {
      .event-header {
        .event-details {
          bottom: 0px;

          .body-copy {
            padding-bottom: 6em;
          }

          .ticket-details {
            padding-bottom: 1em;
          }

          .title-container {

            max-height: 230px;
            width: 600px;
            overflow: hidden;
            background: rgba(0, 0, 0, 0.1);
            padding: 20px 0;

            span.event-slide-title {
              color: #FFF;
              font-family: $bold-font;
              font-size: 70px;
              line-height: 1.05;
              text-align: left;
            }
          }

          h1 {
            font-size: 70px;
            margin-bottom: 40px;
            line-height: 1;
            text-align: left;
          }
        }
      }
    }

    #content {
      .pane-calendar {
        .more-link {
          float: right;
          //position: absolute;
          //right: 0px;
          //top: 18px;
          //z-index: 999;
        }
      }

      .homepage-panel {
        .article-panel-background {
          height: 100%;
        }
      }
    }

    .top-events {
      .panel-panel {
        .inside {
          height: 100%;
        }
      }
    }

    .panel-amg-homepage {
      .top-spotlight {
        //height:auto;
        height: 100%;
      }

      .panel-col-top {
        position: relative;

        .advert-panel {
          position: relative;
          z-index: 3;
          background-color: #fff;
          width: 308px;
          float: right;
          //margin-right: .5em;
          padding-left: 8px;
        }

        .top-house-ad {
          //margin-left:0.5em;
          //margin-top:0.5em;
        }
      }

      .advert-wrap {
        padding-left: 0;
        padding-right: 0;

        .inside {
          margin-right: 0.5em;
        }
      }

      .panel-col-first {
        &.top-spotlight {
          .inside {
            margin-right: 0.5em;
            //margin-bottom:0;
          }
        }
      }

      .panel-col-middle {
        .bottom-row {
          .inside {
            margin-bottom: 0;
          }
        }
      }

      .center-wrapper {
        overflow: hidden;

        .panel-col-middle {
          .top-row {
            height: 298px;
            float: left;
            margin-bottom: 0.5em;
            overflow: hidden;

            .inside {
              //margin-bottom:0.25em;
            }
          }

          .bottom-row {
            height: 298px;
            float: left;
            //margin-top:0.25em;
            overflow: hidden;

            .inside {
              //margin-top:0.25em;
            }
          }
        }
      }
    }

    .bottom-spotlight-row {
      .bottom-row {
        .inside {
          margin-bottom: 0;
        }
      }
    }
  }
  .page-amg-landing {
    .view-venue-list {
      > .view-content {
        > .views-row {
          height: 380px;
        }
      }
    }
  }
  .events-calendar, .view-calendar {
    .date-heading {
      h3 {
        font-size: 26px;
      }
    }

    .btn.sml {
      //max-width: 110px;
      float: right;
      clear: both;

      &.all-listings {
        float: none;
        //max-width:120px;
      }
    }
  }
}


@media (min-width: 52em) {
  .front, body.homepage-panel {
    .event-header {
      .event-wrap {
        .event-info {
          padding-bottom: 2em;
        }
      }

      .body-copy {
        padding-bottom: 2rem !important;
      }

      .ticket-details {
        padding-bottom: 0 !important;
      }
    }

    .events-calendar {
      .view-content {
        .item-list {
          ul {
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;

            //li {
            //  width: 48%;
            //  margin: 0 1%;
            //}
          }
        }
      }
    }

    .event-info {
      .ticket-details {
        .btn {
          clear: both;
          //margin-right:10px;
        }
      }
    }

    #content {
      .notifications {
        .pane-bundle-notification-panel {
          .field-label {
            margin-bottom: 0px;
          }
        }
      }
    }

    .panel-amg-homepage {
      .house-ads {
        .left-ad-space {
          padding: 0;
        }
      }

      .center-wrapper {
        margin-bottom: 0.5em;
      }

      .center-wrapper.bottom-spotlight-row {
        margin-bottom: 0;
      }

      .secondary-events {
        .first {
          .inside {
            margin-bottom: 0.5em;
          }
        }

        .col {
          height: 295px;

          &:first-child {
            .inside {
              margin: 0 0.5em 0 0;
            }
          }
        }

        .second {
          img {
            height: 295px;
            object-fit: cover;
          }
        }

        .priority-panel {
          background-image: url(#{$images}/optimized/priority.jpg);
          background-size: cover;
          padding: 120px 28px 20px;
          position: relative;
          height: 295px;

          p {
            color: #FFF;
          }

          a {
            position: absolute;
            bottom: 20px;
            color: #FFF;
          }
        }
      }

      .bottom-spotlight-row {
        .bottom-spotlight {
          .top-row {
            .inside {
              margin-left: 0.5em;
            }
          }
        }
      }
    }

    .secondary-events {
      .first {
        .inside {
          .homepage-panel {
            height: 100%;
          }
        }
      }
    }

    .top-events {
      height: 600px;

      .right-top-event {
        .inside {
          .homepage-panel {
            height: 100%;
          }
        }
      }

      .spotlight-left {
        // width:100%;
        // padding-right:300px;
      }

      .advert-panel {
        // width:300px;
        // position:absolute;
        // right:0;
        // margin-right:1em;
        .ad-wrapper {
          margin-left: 0.5em;
        }
      }
    }
  }
  .page-amg-landing {
    .sml-box {
      &.first-sml {
        .inside {
          margin-left: 0;
        }
      }
    }

    #content {
      .bottom-spotlight-landing {
        > .inside {
          height: 600px;
        }

        &.panel-col-last {
          .first {
            .inside {
              margin: 0 0 .5em .5em;
            }
          }
        }
      }

      .spotlight-wrap {
        .panel-col-last {
          .spotlight-small {
            .inside {
              margin: 0 0 .5em .5em;
            }
          }
        }

        .spotlight-small, .spotlight {
          &.panel-col-last {
            .inside {
              margin: 0 0 .5em .5em;
            }
          }
        }
      }
    }


    .panel-col-top {
      margin: 0;

      .hero-header {
        padding-right: 0;
        padding-left: 0;

        img {
          height: 600px;
        }
      }
    }

    .view-venue-list {
      > .view-content {
        > .views-row {
          width: 25%;
          height: 380px;
        }
      }
    }
  }
  .page-amg-landing {
    .hero-header {
      .pane-title {
        position: absolute;
        font-size: 64px;
      }

      .field-name-field-panel-hero-text {
        display: block;
      }
    }
  }
  .page-amg-landing {
    .hero-header {
      .pane-title {
        top: 180px;
      }

      .field-name-field-panel-hero-text {
        top: 350px;
      }
    }
  }
}


@media (min-width: 64em) {
  .front, body.homepage-panel {
    .panel-amg-homepage {
      .panel-col-top {
        .advert-panel {
          margin-top: 0;
          margin-right: 0;
        }

        .top-house-ad {
          margin-top: 0;
          margin-left: 0;
          height: 340px;
        }
      }
    }

    .top-col-right-wrap {
      padding: 0;
    }

    .panel-amg-homepage {
      .secondary-events {
        .col {
          //height:295px;
        }

        .priority-panel {
          //height:295px;
        }
      }
    }

    .top-house-ad {
      .group-advert-wrap {
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  .hero-header {
    .flexslider {
      .flex-viewport {
        ul {
          li {
            .event-header {
              height: 600px;
            }
          }
        }
      }
    }
  }
  .panel-col-top {
    margin-top: 8px;
    position: relative;

    .top-house-ad {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
    }

    .hero-header {
      //width:100%!important;
      padding-right: 316px;
      //padding-top: 8px;
      padding-left: 8px;
    }

    .top-col-right-wrap {
      width: 300px;
      position: absolute;
      right: 8px;
      top: 0;
      bottom: 0;
    }
  }
  .top-events {
    position: relative;

    .left-top-event {
      //width: 100%!important;
      padding-right: 308px;
    }

    .right-top-event {
      width: 300px;
      position: absolute;
      right: 8px;
      top: 0;
      bottom: 0;

      .inside {
        margin: 0 !important;
      }
    }
  }
}

.front {
  #ad-slot-502 {
    iframe {
      float: left;
    }
  }
}

@media (max-width: 64em) {
  .page-amg-landing .panel-col-top .top-col-right-wrap {
    //display: none;
  }
  .page-amg-landing .panel-col-top .top-house-ad {
    float: left;
    margin-top: 0;
  }
  .page-amg-landing .hero-header .field-name-field-panel-hero-text {
    display: block;
  }
  .page-amg-landing header#header .top-links {
    display: block;
  }
  .house-ads img {
    object-fit: cover;
    height: auto;
  }
}

@media (max-width: 52em) {
  .page-amg-landing .hero-header .logo {
    top: 29%;
  }
  .bottom-spotlight-row.px1, .secondary-events.px1, .top-events.px1 {
    padding-left: .5em;
    padding-right: .5em;
  }
  body.homepage-panel .panel-amg-homepage .right-top-event .top-spotlight .inside {
    margin-left: 0;
  }
  .front {
    .house-ads {
      .panel-col-last {
        .sml-box {
          &:first-child {
            .inside {
              margin: 0 0.25em 0 0;
            }
          }

          .inside {
            margin: 0 0 0 0.25em;
          }
        }
      }
    }
  }
  .front .duplicate-panel-wrap .right-top-event.duplicate-panel .inside {
    margin-right: 0.5em;
    margin-left: 0;
  }
  .front .duplicate-panel .inside .homepage-panel {
    height: 300px;
  }
  .front .pane-bundle-event-panel .event-panel-background {
    background-position: center 30%;
    background-repeat: no-repeat;
  }
  .front .event-panel-background .group-event-details .field-name-field-event-name {
    font-size: 20px;
  }
  .secondary-events {
    .group-advert-wrap {
      img {
        height: auto;
      }
    }

  }
}

@media (max-width: 40em) {
  .front {
    .duplicate-panel-wrap {
      .second-duplicate {
        margin-bottom: 0.5em;
      }
    }
  }
  .front {
    .top-house-ad {
      width: 100%;
    }
  }
  .front .panel-display .sml-box.second-duplicate .inside.no-content {
    height: auto;
  }
  .front .panel-display .sml-box .inside.no-content {
    height: 240px;
  }
  .front .top-events .panel-panel .inside, body.homepage-panel .top-events .panel-panel .inside {
    height: 240px;
  }
  .front .top-events .panel-panel .bottom-row .inside, body.homepage-panel .top-events .panel-panel .bottom-row .inside {
    height: 160px;
  }
  .page-amg-landing .top-col-right-wrap .advert-panel {
    margin-bottom: 0.5em;
  }
  .house-ads .left-ad-space {
    height: auto;
  }
  .house-ads img {
    height: auto;
  }
  .front .top-events .top-row .first-top .inside {
    margin-right: 0.5em;
    height: 160px;
  }
  .front .top-events .right-top-event .inside .homepage-panel, body.homepage-panel .top-events .right-top-event .inside .homepage-panel {
    height: 300px;
  }
  .front .secondary-events .first .inside .homepage-panel, body.homepage-panel .secondary-events .first .inside .homepage-panel {
    height: 300px;
  }
  .front .duplicate-panel .inside .homepage-panel {
    height: 160px;
  }
  .page-amg-landing .hero-header .logo {
    top: 37%;
    width: 180px;
  }
  .page-amg-landing .main-view-content .view-venue-list .views-row {
    padding-right: 0;
  }
  .front .duplicate-panel .inside .homepage-pane {
    height: 160px;
  }

  .bottom-spotlight-row.px1, .secondary-events.px1, .top-events.px1 {
    padding-left: .5em;
    padding-right: .5em;
  }
  .panel-amg-homepage .top-events .panel-col-middle .second .inside {
    margin: 0 0 .5em 0;
    height: 160px;
  }
  // Homepage slider
  .front {
    .event-container {
      .event-image {
        img {
          height: 32em !important;
        }
      }
    }
  }
  .event-container {
    .event-header {
      border: none;

      .event-details h1 {
        font-size: 2.6em;
        text-align: left;
        padding-left: 0.5em;
        padding-right: 0.5em;
        margin-bottom: 0.3em;
      }

      .event-details {
        .title-container {
          padding-left: 1.5em;
          padding-right: 1.5em;
        }
      }

      .event-details span.event-slide-title {
        font-family: $bold-font;
        color: #FFF;
        font-size: 2em;
        text-align: left;
        padding-left: 0em;
        padding-right: 0em;
        margin-bottom: 0.3em;
      }

      .event-details {
        .event-wrap {
          .ticket-details {
            text-align: left;
            padding-left: 1em;
          }
        }
      }
    }
  }
  .front, body.homepage-panel {

    .bottom-spotlight-row .panel-col-last.bottom-spotlight {
      margin-bottom: 0;
    }

    .panel-amg-homepage .house-ads {
      margin-bottom: .5em;
    }

    .hero-header {
      .btn {
        width: auto;
        padding: 0 2em;
        float: none;
        margin: 0 .5em;
        font-size: 0.9em;
        vertical-align: top;
      }

      .event-info {
        right: 0;
      }

      .event-date {
        margin-bottom: 1em;
        padding-left: 1.2em;

        p {
          font-size: 0.8rem;
          line-height: 1rem;
          font-family: $bold-font;
        }
      }

      .body-wrap {
        padding-left: 1.5em;
        padding-right: 1.5em;
      }

      .body-copy {
        padding-bottom: 5em;
      }

      p {
        font-size: 0.85rem;
      }

      .flex-control-nav li {
        margin: 0 .4em;

        a {
          width: 6px;
          height: 6px;
          border-width: 1px;
        }

      }
    }

    .article-panel-background .group-article-details,
    .event-panel-background .group-event-details {
      left: 1em;
      right: 1em;
    }

    // Events calender styling
    .events-calendar {
      padding: .5em;

      .date-heading {
        h3 {
          font-size: 2em;
        }

        span {
          display: block;
        }
      }

      .more-link .view-all {
        height: 40px;
        line-height: 40px;
      }

      .view-header {
        padding-bottom: 0 !important;
      }
    }

    #content .view-calendar .item-list ul.pager {
      margin-top: 5px;
    }

    #content .view-calendar .view-content .item-list ul {
      margin: 0;
      padding: 0;
    }
  }
  #content .view-calendar ul li.next, #content .view-calendar ul li.prev {
    float: left;
    width: 50%;
    font-size: 0.8em;
    border: none;
  }
  #content .event-item {
    .event-info {
      font-size: 0.8em;

      h3 {
        font-size: 1.3em;
      }
    }

    .event-date {
      .date.col {
        font-size: 36px;
      }

      .time {
        font-size: 11px;
      }

      .day {
        font-size: 11px;
      }
    }
  }
}
