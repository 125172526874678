@charset "UTF-8";

// objectFit fix for IE etc
.no-objectfit {

	.objectfitFix {
		min-width: 100%;
		min-height: 100%;
		height: auto;
		width: 100%;
	}
	#content .lazyloader-icon {
		min-height: 0 !important;
		min-width: 0 !important;
	}
	.about-container .top-hero-image img {
		@extend .objectfitFix;
	}
	.accordion-page-container .top-hero-image img {
		@extend .objectfitFix;
	}
	.basic-page-container .top-hero-image img {
		@extend .objectfitFix;
	}
	.work-for-us-container .top-hero-image img {
		@extend .objectfitFix;
	}
	.event-container .event-header .event-image img {
		@extend .objectfitFix;
	}
	#content .view-gallery .field-name-field-gallery-hero img {
		@extend .objectfitFix;
	}
	#content .view-gallery .view-content .views-row .views-field-node-gallery-media img {
		@extend .objectfitFix;
	}
	#content .gallery-page .related-galleries .views-row img {
		@extend .objectfitFix;
	}
	.page-amg-landing .hero-header  .pane-bundle-hero-panel img {
		@extend .objectfitFix;
	}
	.page-amg-landing .view-venue-list >.view-content >.views-row img {
		@extend .objectfitFix;
	}
	.page-amg-landing .secondary-events .second img {
		@extend .objectfitFix;
	}
	.list-item-container  .top-hero-image  img {
		@extend .objectfitFix;
	}
	#content .on-o2-container .top-hero-image img {
		@extend .objectfitFix;
	}
	#content .on-o2-container .content .field-item .field-collection-view img {
		@extend .objectfitFix;
	}
	.venue-list-content >.views-row img {
		@extend .objectfitFix;
	}
	.contact-page-container .top-hero-image img {
		@extend .objectfitFix;
	}

}
