@charset "UTF-8";

// Arts Club Styling
body {
  &.domain-academymusicgroup-com-artsclubliverpool {
    #content {
      .event-item {
        .event-date {
          span {
            color: $arts-medium-grey;
          }
        }

        .event-info {
          h3 {
            a {
              color: $arts-medium-grey;
            }
          }
        }
      }

      .event-container {
        .event-header {
          .flag-wishlist {
            border-color: $arts-medium-grey;

            &:hover {
              background-color: $arts-medium-grey;
            }
          }

          .event-details {
            .label, h1 {
              color: $arts-medium-grey;
            }
          }

          .support-container {
            .field-name-field-support-acts {
              .field-label {
                color: $arts-light-grey;
              }
            }

            .field-name-field-support-acts {
              color: $arts-medium-grey;
            }
          }
        }

        .bottom-spotlights {
          .youtube-panel {
            background-color: $arts-medium-grey;

            .header {
              border-color: #313131;
            }
          }

          .right-col {
            .inner {
              background-color: $arts-medium-grey;
            }
          }
        }
      }
    }
  }
}

.event-container {
  .event-header {
    .event-image {
      img {
        height: auto;
        width: 100%;
        float: left;
        object-fit: cover;
      }
    }

    .event-details {
      padding-left: 1em;
      padding-right: 1em;
      padding-top: 0.5em;

      h1 {
        font-size: 30px;
      }

      .ticket-details {
        padding-left: 0;
      }

      .share-links {
        margin-bottom: 30px;
        color: $grey-font;
        font-size: 0.95em;
      }

      .share-list {
        display: inline-block;
        margin-bottom: 0;

        li {
          display: inline-block;
          color: $light-blue;
          margin: 0 6px;
          vertical-align: middle;
        }
      }

      .field-label {
        font-weight: normal;
      }

      .body-copy {
        color: $grey-font;
      }

      .support-details {
        margin-top: 1.5em;

        .support-container {
          border-top: 1px solid #e6e6e6;
          padding-top: 1em;

          .field-name-field-support-acts {
            color: $light-blue;
            font-family: $bold-font;
          }

          .field-label {
            margin-bottom: 0.2em;
          }
        }
      }

      .label {
        color: $dark-blue;
        font-family: $bold-font;
        font-size: 16px;

        &.icon-pin-map, &.icon-clock {
          &:before {
            margin-right: 13px;
            position: relative;
            top: 1px;
          }
        }
      }

      .ww-fields {
        p, .field {
          padding-left: 2em;
          padding-top: .5em;
          margin: 0;
          line-height: 1.5;
        }
      }
    }
  }

  .event-ad-panel {
    .external-ads {
      img {
        width: 100%;
      }
    }

    .first {
      .ad-inner {
        padding-right: 0.25em;
      }
    }

    .second {
      .ad-inner {
        padding-left: 0.25em;
      }
    }

    .house-event-ads {
      .inner {
        float: left;
      }
    }

    img {
      width: 100%;
      height: 100%;
      vertical-align: top;
    }

    .title {
      display: none;
    }
  }

  .bottom-spotlights {
    h3 {
      color: #FFF;
    }

    .youtube-panel {
      background-color: $dark-blue;
      margin-bottom: 0.5rem;

      iframe {
        width: 100%;
        height: 350px;
        border: 0;
      }
    }

    .right-col {
      .inner {
        background-color: $dark-blue;
      }
    }
  }

  .related-gallery-event-block {
    .inner {
      float: left;
      width: 100%;
    }
  }
}


#content {
  .bottom-spotlights {
    a {
      &.view-all {
        &:hover {
          color: #FFF
        }
      }
    }
  }
}

// Event node wrapper
.event-container {
  .age-policy {
    font-style: italic;
  }
  .tickets-from {
    line-height: 1.4em;

    time {
      padding-top: 8px;
      font-weight: bold;
      font-size: 1.1em;
    }
  }
}

// Events calendar view
.view-calendar {
  .tickets-from {
    line-height: 1.4em;
    text-align: right;
    font-size: 80%;

    time {
      padding-top: 8px;
      font-weight: bold;
      font-size: 1.1em;
    }
  }
}

//
.btn {

  &.amg-standard {
    background-color: $light-blue;
    color: #ffffff !important;
  }
  &.barclaycard {
    background-color: $barclaycard;
    color: #ffffff !important;
  }
  &.o2priority {
    background-color: $purple;
    color: #ffffff !important;
  }

}




@media (min-width: 40em) {
  .event-container {
    .event-header {
      .event-details {
        .ticket-details {
          padding-left: 0;

          .flag-wishlist {
            float: right;
            clear: both;
          }

          .tickets-from {
            float: right;
            clear: both;
          }

          .btn {
            float: right;
            clear: both;
          }
        }
      }
    }
  }
  #content {
    .event-ad-panel {
      .first {
        .ad-inner {
          padding-right: 0.25em;
        }
      }

      .second {
        .ad-inner {
          padding-left: 0.25em;
        }
      }
    }
  }
}

@media (min-width: 52em) {
  .event-container {
    .event-header {
      .event-image {
        img {
          //height: 450px;
        }
      }
    }
  }
  article.node.node-event-calendar.event-container {
    padding-bottom: 0;
  }
  .event-head-wrap {
    position: relative;

    .event-info {
      padding: 0.5em 0 0;
    }
  }
  #content {
    .bottom-spotlights {
      .youtube-panel {
        margin-bottom: 0;
      }

      .right-col {
        .inner {
          margin-left: 0.5em;
        }
      }
    }

    .event-header {
      .event-details {
        //padding-top:1em;
        .field-name-field-event-location {
          font-size: 90%;
        }

        .tickets-from {
          text-align: right;
          float: right;
        }
      }
    }

    .event-head-wrap {
      .body-copy {
        p {
          font-size: 90%;
        }
      }
    }

    .related-gallery-event-block {
      .inner {
        width: initial;
        float: none;
      }
    }

    .event-ad-panel {
      .house-event-ads {
        .inner {
          padding-left: 0.5em;
        }
      }

      .house-event-ads {
        max-height: 300px;
        overflow: hidden;
      }

      .external-ads {
        max-height: 300px;
        overflow: hidden;
      }
    }
  }
}

@media (max-width: 40em) {
  .flex-viewport {
    .event-header {
      .btn.sml {
        width: auto;
      }
    }
  }
  .event-header {
    .btn.sml {
      width: 100%;
      font-size: 120%;
    }
  }
  .section-events {
    .event-header {
      .event-details {
        .flag-wishlist {
          width: auto;
        }
      }
    }
  }
}

@media (max-width: 52em) {
  .section-events {
    .youtube-panel a.view-all {
      margin-top: 3px;
    }

    .related-gallery-event-block .view-all {
      top: 1.7em;
    }

    .bottom-spotlights {
      margin: 0;
    }

    .event-container .event-header {
      border: none;

      .col.col-12.md-col-6.px3 {
        padding: 0;
      }

      .event-details {
        padding: 0.5em;

        .share-links {
          margin: 0 0 1.5em;
        }
      }

      .nosto-product-info {
        .event-details {
          padding: 0.5em;
        }
      }

      .event-info {
        padding-top: 1.5em;
      }
    }

    #content .body-copy p, #content .event-header .event-details .field-name-field-event-location {
      font-size: 100%;
    }

    #content .event-header .event-details .field-name-field-event-location {
      padding-bottom: 1.5em;
    }

    .event-container .event-header .event-details h1 {
      padding-left: 0;
      padding-right: 0;
    }

    .event-container .event-header .ticket-details {
      text-align: left;
      padding: 0;
    }

    .event-header .flag-wishlist {
      display: inline-block;
      width: 120px;
      float: none;
    }

    .event-header {
      .btn.sml {
        display: inline-block;
        float: none;
      }
    }
  }
  .house-event-ads {
    margin-top: 0.5em;
  }
}
