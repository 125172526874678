@charset "UTF-8";

.domain-academymusicgroup-com	.field-name-field-download-image {
	display: none;
}
.domain-academymusicgroup-com .body-copy {
	margin-bottom: 0;
	padding-bottom: 0;
	border-bottom: none;
}
.domain-academymusicgroup-com .corporate-venue-info-container .info-separator .info-separator {
	margin-bottom: 0;
	padding-bottom: 0;
	border-bottom: 0;
}
.domain-academymusicgroup-com .corporate-venue-info-container .field-collection-item-field-download-item {
	margin-bottom: 50px;
}

.domain-academymusicgroup-com .google-map {
	position: relative;
	float: left;
	//height: 300px;
	width: 100%;
	margin-bottom: 4em;

	.map-mask {
		position: absolute;
		bottom: 0; left: 0;
		width: 100%;
		height: 300px;
	}
}

.page-corporate-landing, .corporate-venue-page {
	.panel-col-top {
		.hero-header {
			padding-right: 8px;
		}
	}
}

.corporate-venue-page {
	.body-copy p {
		text-align: left;
		color: #1a1a1a !important;
	}
}

.view-corporate-venue-info {
	.block-link {
		display: inline-block;
		clear: both;
		background: #151515;
		top: 15px;
		position: relative;
		padding: 30px 10px;
		margin-bottom: 22px;
		width: calc(50% - 5px);

		h1 {
			margin: 0;
			text-align: center !important;
			font-size: 28px !important;
		}

		&:nth-child(2), &:nth-child(4) {
			margin-right: 5px;
		}

		&:nth-child(2), &:nth-child(3) {
			margin-bottom: 10px;
		}
	}
}

.corporate-venue-info-container{
	.top-hero-image {
		position:relative;
		.flexslider {
			border:0;
			margin:0;
			.flex-control-nav {
				bottom:10px;
				&.flex-control-paging li {
					a {
						background-color:#FFF;
						opacity:1;
						&.flex-active {
							background-color:#000;
						}
					}
				}
			}
		}
		.page-title {
			background-color:$arts-medium-grey;
			color:#FFF;
		}
		img {
			object-fit: cover;
			height: 200px;
		}
		.hero-content-wrap {
		    width: 100%;
		    margin: auto;
		    position: absolute;
		    top: 0;
		    left: 0;
		    bottom: 0;
		    right: 0;
		    height: 45%;
			h1 {
				color:#FFF;
				font-size:36px;
				text-align:center;
				margin:10px 0;
			}
		}
	}
	.info-separator {
		padding-bottom:50px;
	}
	.info-separator,
	#more {
		>h2 {
			color:$corporate-header;
			border-bottom: 1px solid #e6e6e6;
			padding-bottom:1em;
			margin-bottom:1em;
			text-align:left;
			font-size:2em;
			width:100%;
			float:left;
		}
		border-bottom: 1px solid #e6e6e6;
		margin-bottom: 50px;
		h3 {
			font-size: 1.75em;
		}
		h4 {
			padding-bottom: 0.5rem;
			margin-bottom: 0;
		}
		p {
			line-height: 2em;
			margin-bottom: 0;
		}
	}
	.tabs-local-info {
        padding: 0 0.5rem;
        ul {
            list-style: none;
            padding-left: 0 !important;
            li {
                display: inline;
                width: 100%;
                text-align: center;
                padding: 1rem;
                float: left;
                border-bottom: 5px solid #e6e6e6;
                cursor: pointer;
                a {
                    font-family: $bold-font;
                    color: $arts-light-grey !important;
                }
                &:hover {
                    border-color: $arts-medium-grey;
                    a {
                        color: $arts-medium-grey !important;
                    }
                }
                &:active {
                    border-color: $arts-medium-grey !important;
                    a {
                        color: $arts-medium-grey !important;
                    }
                }
            }
        }
    }
	.google-map {
		iframe {
			float:left;
			width:100%;
		}
	}
	.content {
		.field-name-field-list-item  {
			>.field-items {
				float:left;
				width:100%;
				>.field-item {
					width:100%;
					float:left;
					padding-bottom:2em;
					margin-bottom:50px;
				}
			}
			.field-name-field-list-item-title {
				color:$corporate-header;
				font-size:64px;
				font-family:$bold-font;
				padding-bottom:10px;
			}
		}
		.field-item {
			.field-collection-view {
				text-align:center;
				color:$grey-font;
				font-size:90%;
				margin:0;
				border:0;
				img {
					height:auto;
				}
				.field-name-field-spotlight-title {
					color:$corporate-header;
					font-size:32px;
					font-family:$bold-font;
					margin:0.5em 0;
				}
			}
		}
	}
}

.corporate-venue-info-container {
	.body-copy {
		.field-type-text-with-summary{
			text-align:left;
			margin-bottom:1em;
			p {
				font-size:100%;
				line-height: 2em;
			}
		}
	}
	.field-collection-item-field-list-item{
		.content{
			min-height: 230px;

		}
		.field-name-field-list-item-text{
			.field-item{
				line-height: 1.5em;

			}
		}
	}
}

@media (min-width: 40em) {
.corporate-venue-info-container {
	.top-hero-image {
		img {
			height:300px;
		}
		.hero-content-wrap {
			h1 {
				font-size:75px;
				margin:20px 0;
			}
		}
	}
	.content-wrap {
		padding:2em 4em;
	}
	.content {
		.field-name-field-list-item  {
			>.field-items {
				>.field-item {
					width:33%;
					padding:0 2em;
				}
			}
		}
		.field-item {
			&.even {
				.field-collection-view  {
					margin-right:0.5em;
				}
			}
			&.odd {
				.field-collection-view  {
					margin-left:0.5em;
				}
			}
		}
	}
}
}

@media (min-width: 40em) {
	.corporate-venue-info-container {
		.tabs-local-info {
			ul li {
				width:50%;
			}
		}
	}
}

@media (min-width: 52em) {
	.corporate-venue-info-container {
		.top-hero-image {
			img {
				height:500px;
			}
		}
		.tabs-local-info {
			ul li {
				width:25%;
				padding: 2rem;
			}
		}
	}
}
