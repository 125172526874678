

.rescheduled-events {
  .block {
    text-align: center;

    h3.title {
      font-family: $light-font;
      font-size: 180%;
    }
  }
}
