@charset "UTF-8";

#content {
	.on-o2-container {
		.field-collection-container {border: none;}
		.top-hero-image {
			position:relative;
			img {
				object-fit: cover;
				height: 200px;
			}
			.hero-content-wrap {
			    width: 100%;
			    margin: auto;
			    position: absolute;
			    top: 0;
			    left: 0;
			    bottom: 0;
			    right: 0;
			    height: 45%;
				h1 {
					color:#FFF;
					font-size:36px;
					text-align:center;
					margin:10px 0;
				}
				.tagline {
					color:#FFF;
					text-align:center;
					display:none;
				}
			}
		}
		#content {
			width:70%;
			margin:0 auto;
		}
		.body-copy {
			text-align:center;
			padding:3em 2em 1.5em 2em;
			p {
				font-size:100%;
				line-height: 1.5em;
			}
		}
		.group-o2-spotlight-wrapper {
			>h3 {
				display:none;
			}
		}
		.content {
			.field-name-field-on-o2-spotlight {
				>.field-items {
					float:left;
					width:100%;
					>.field-item {
						width:100%;
						float:left;
						padding-bottom:2em;
					}
				}
			}
			.field-item {
				.field-collection-view {
					text-align:center;
					color:$grey-font;
					font-size:90%;
					margin:0;
					border:0;
					img {
						object-fit: cover;
				    width: 100%;
						max-height:400px;
						height:400px;
				    overflow: hidden;
					}
					.field-name-field-spotlight-title {
						color:$medium-blue;
						font-size:32px;
						font-family:$bold-font;
						margin:0.75em 0 0.5em 0;
						padding-left: 1rem;
					}
					.field-name-field-spotlight-text {
						padding:0 20px;
						line-height: 1.75em;
					}
				}
			}
		}
	}
}

@media (min-width: 40em) {
	#content {
		.on-o2-container {
			.top-hero-image {
				img {
					height:300px;
				}
				.hero-content-wrap {
					h1 {
						font-size:75px;
						margin:20px 0;
					}
					.tagline {
						display:block;
					}
				}
			}
			.content {
				.field-group-format-wrapper{ text-align: left; }
				.field-name-field-on-o2-spotlight {
					>.field-items {
						>.field-item {
							width:50%;
						}
					}
				}
				.field-item {
					&.even {
						.field-collection-view  {
							padding-right:0.25em;
						}
					}
					&.odd {
						.field-collection-view  {
							padding-left:0.25em;
						}
					}
				}
			}
		}
	}
}

@media (min-width: 52em) {
	#content {
		.on-o2-container {
			.top-hero-image {
				img {
					height:400px;
				}
			}
		}
	}
}

@media (min-width: 64em) {
	#content {
		.on-o2-container {
			.on-o2-body {
				.body-copy {
					width:60%;
					margin:0 auto;
				}
				>.content {
					width:100%;
					margin:0 auto;
				}
			}
		}
	}
}
