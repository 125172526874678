@charset "UTF-8";

// Arts Club Styles
body {
	&.domain-academymusicgroup-com-artsclubliverpool {
		.work-for-us-container {
			.content {
				.field-name-field-download-item {
					.field-name-field-download-title {
						color:$arts-medium-grey;
					}
				}
			}
		}
	}
}

.work-for-us-container {
	.rtejustify {text-align: left;}
	.top-hero-image {
		position:relative;
		img {
			object-fit: cover;
			height: 200px;
		}
		.hero-content-wrap {
		    width: 100%;
		    margin: auto;
		    position: absolute;
		    top: 0;
		    left: 0;
		    bottom: 0;
		    right: 0;
		    height: 45%;
			h1 {
				color:#FFF;
				font-size:36px;
				text-align:center;
				margin:10px 0;
			}
			.tagline {
				color:#FFF;
				width:100%;
				text-align:center;
				display:none;
			}
		}
	}
	.body-copy {
		text-align:center;
		padding:2em;
		// border-bottom:1px solid $grey-border;
		margin-bottom:1em;
		padding-top:0;
		text-align: center;
		p {
			font-size:100% !important;
			text-align: center;
			line-height: 1.5;
			&:nth-child(2){
				line-height: 0;
			}
		}
		h2 {
			font-size:2pc;
			margin-bottom: 2rem;

		}
	}
	.content {
		.field-name-field-download-item  {
			>.field-items {
				float:left;
				width:100%;
				>.field-item {
					width:100%;
					float:left;
					padding-bottom:2em;
					&.even{
						padding-right: 0.5rem;
					}
				}
			}
			.field-name-field-list-item-title {
				color:$medium-blue;
				font-size:64px;
				font-family:$bold-font;
				padding-bottom:10px;
			}
			.field-name-field-download-title {
				color:$medium-blue;
				font-family:$bold-font;
				font-size:32px;
				margin:0.75em 0 0.5em 0;
			}
		}
		.field-item {
			.field-collection-view {
				text-align:center;
				color:$grey-font;
				font-size:90%;
				margin:0;
				border: none;
				img {
					height:auto;
					width: 100%;

				}
				.field-name-field-spotlight-title {
					color:$medium-blue;
					font-size:32px;
					font-family:$bold-font;
					margin:0.5em 0;
				}
			}
		}
	}
}

@media (min-width: 52em) {
.work-for-us-container {
	.top-hero-image {
		img {
			height:300px;
		}
		.hero-content-wrap {
			h1 {
				font-size:75px;
				margin:20px 0;
			}
			.tagline {
				display:block;
			}
		}
	}
	.content-wrap {
		padding:2em 0rem;
		>.content {
		width: 100%;
		margin: 0 auto;
		}
	}
	.body-copy {
	width: 100%;
	padding: 0 10rem 20px;
	margin-bottom:0px;
	}
	.content {
		.field-name-field-download-item  {
			>.field-items {
				>.field-item {
					padding:0;
					width: 50%;
					font-size: 2pc;
				}
			}
			.field-name-field-download-image {
				float:left;
				width:100%;
				// margin-right:5%;
				height: 300px;
				overflow: hidden;
			}
			.field-name-field-download-title {
				float:left;
				width:59.5%;
				text-align:left;
				margin:0.75em 0 0.5em;
				padding-left: 1rem;
				color: $medium-blue;
			}
			.field-name-field-download-text {
				float:left;
				width:80%;
				text-align:left;
				margin-bottom:1em;
				line-height:1.5;
				padding-left: 1rem;
			}
			.field-name-field-download-document {
				float:left;
				width:80%;
				text-align:left;
				font-size: 0.85rem;
				padding-left: 1rem;
				img{
					width: auto !important;

				}
			}
		}
		.field-item {
			&.even {
				.field-collection-view  {
					// margin-right:0.5em;
				}
			}
			&.odd {
				.field-collection-view  {
					// margin-left:0.5em;
				}
			}
		}
	}
}
}

@media (min-width: 52em) {
.work-for-us-container {
	.top-hero-image {
		img {
			height:400px;
		}
	}
}
}
@media (max-width: 64em) {
	.work-for-us-container .content .field-item .field-collection-view p{ font-size: 13px; line-height: 1.5em;}
}

@media (max-width: 52em) {
.work-for-us-container .content .field-name-field-download-item>.field-items>.field-item { width: 50%; }
.node-work-for-us.p2 {padding: 0;}
.work-for-us-container .content .field-item .field-collection-view img {width: auto;}
.work-for-us-container .content .field-item .field-collection-view p {padding: 0 1rem;}
.work-for-us-container .content .field-name-field-download-item .field-name-field-download-title {text-align: left; padding: 0 1rem;}
.work-for-us-container .content .field-item .field-name-field-download-document {text-align: left; padding-left: 1rem;}
}
@media (max-width: 40em){
	.work-for-us-container .body-copy p:nth-child(2) { line-height: 1.5em;}
	.work-for-us-container .body-copy {padding:1rem;}
	.work-for-us-container .content .field-name-field-download-item>.field-items>.field-item { width: 100%; }
	.work-for-us-container .content .field-name-field-download-item>.field-items>.field-item.even { padding-right: 0;}
	.work-for-us-container .content .field-item .field-collection-view img { width: auto; }
	.work-for-us-container .content .field-item .field-collection-view p{ padding: 0 1rem; font-size: 13px; line-height: 1.5em;}

}
