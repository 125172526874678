@charset "UTF-8";

.domain-academymusicgroup-com-corporate,
.page-company-venues,
.corporate-venue-page,
.domain-vded-acmugr-005-servers-wirehive-net-corporate,
.node-type-corporate-venue-info,
body[class*="domain-academymusicgroup-com-company"],
body[class*="domain-vded-acmugr-005-servers-wirehive-net-company"] {
    .ad-wrapper {
        display: none;
    }
    #content .gallery-page {
        .content .gallery-details {
            background-color: #1a1a1a;
        }
        .left-col {
            padding-right: 0;
        }
        .right-col.ad-area {
            display: none;
        }
    }
}

.view-corporate-venue-info {
    width:100%;
    padding:0.5em;
    .view-header {
        padding-bottom:0.5em;
        float:left;
        width:100%;
        img {
            float:left;
        }
    }
    .view-content {
        float:left;
        width:100%;
    }
	.views-row {
		width:100%;
		float:left;
		min-height:400px;
		color:$grey-font;
		text-align:center;
		.views-field-title {
			font-family:$bold-font;
			color:$arts-dark-grey;
			font-size:20px;
			padding-left: 1rem;
			padding-bottom: 0.5rem;
			padding-top: 20px;
            float: left;
            width: 100%;
			a {
				color:$arts-dark-grey;
				font-weight: bold;
				font-size: 21px;
				margin: 0px 0px 10px;
                width:100%;
                float:left;
			}
		}
		.views-field-field-corporate-local-address{
			padding-left: 1rem;
			font-size: 90%;
			line-height: 1.5;
			letter-spacing: 0;
            float:left;
            width:100%;
		}
		img {
			object-fit: cover;
			height: 200px;
			width:280px;
            float:left;
		}
	}
}

.views-field-field-venue-image {
	cursor: pointer;
}

#content {
	.view-corporate-venue-info {
        .node-hero-banner {
            position:relative;
            img {
                object-fit: cover;
                height:250px;
            }
        }
		a {
			color:$arts-dark-grey;
			font-family: $bold-font;
			font-weight: normal;
		}

	}
}

@media (min-width: 52em) {
	.view-corporate-venue-info {
		.views-row {
			width:24.4375%;
			margin-right:0.75%;
			text-align:left;
			&:nth-of-type(4n+4) {
				margin-right:0;
			}
			img {
				width:100%;
			}
		}
	}
}

@media (min-width: 40em) {
    #content {
        .view-corporate-venue-info {
            .node-hero-banner {
                img {
                    height: 400px;
                }
            }
        }
    }
}

@media (max-width: 52em) {
	.view-corporate-venue-info .views-row{
		width: 50%;
		&:nth-child(2n+1){
			padding-right: 0.5rem;
		}
		img{
			width: 100%;

		}
	}
}

@media (max-width: 40em) {
	.view-corporate-venue-info .views-row {

		width: 100%;
		&:nth-child(n+1){
			padding-right: 0;
		}
		img{
			width: 100%;
			max-width: 100%;
		}
	}
	.view-corporate-venue-info .views-row .views-field-title { padding-left: 0; }
	.view-corporate-venue-info .views-row .views-field-field-corporate-local-address { padding-left: 0;}

	.view-corporate-venue-info .node-hero-banner {
		margin-bottom: 0.25rem;
		h2{
			font-size: 45px;
			padding-top: 0;
		}
	}
}
