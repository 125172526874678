@charset "UTF-8";



.blog-container {
	.node-blog { width: 100%; }
	.blog-hero {
		position:relative;
		.article-content-wrap {
			position:absolute;
			bottom:20px;
			left:20px;
			right:20px;
			color:#FFF;
			width:95%;
			padding-bottom: 20px;

			h2 {
				font-size:70px;
				margin-bottom:0;
				a {
					color:#FFF;
					font-family: $bold-font;
				}
			}
			p {
				color:#FFF;
			}
			.summary {
				width:80%;
				display:none;
			}
			.date {
				display:none;
			}
			.view-all {
				border-radius:5px;
				padding:0 10px;
				font-family: $bold-font;
				font-size:90%;
				text-decoration:none;
				display:inline-block;
				text-align:center;
				height:36px;
				line-height:36px;
				background-color:#FFF;
				float:left;
				clear:both;
				transition: all 0.1s linear;
				line-height: 2.75;
				padding-left: 1.5rem;
				padding-right: 1.5rem;
				color:#FFF;
				&:after{display: none;}

				&:hover {
					background-color:$light-blue;
					color:#FFF;
				}
			}
		}
		.image-wrapper {
			position:relative;
			&:after {
				position: absolute;
				content:"";
				top:0;
				left:0;
				width:100%;
				height:100%;
				opacity:0.5;
				background-color: $dark-blue;
			}
			img {
				float:left;
				width:100%;
				height:auto;
			}
		}
	}
	.view-content {
		.views-row {
			float:left;
			width:100%;
			color:$grey-font;
			padding-bottom:20px;
			h2 {
				a {
					color:$medium-blue;
				}
			}
			img {
				height:auto;
				width:100%;
			}
			.date {
				font-size:90%;
			}
		}
	}

	.left-col-wrapper {
		h1 {
			font-size:50px;
			margin-bottom: 0.6em;
			line-height: 0.9;
		}
		.image-wrapper {
			img {
				height:auto;
			}
		}
		.date{
			font-family: $bold-font;
			span{
				font-family: $regular-font;
			}
		}
	}

	.body-content {
		float: right;
		padding-bottom: 10px;

		&.fullwidth {
			width: 100%;
		}
	}

	.sidebar-blog{
		padding-right: 0;
		color: #2ea1d8;
		font-family: $bold-font;
		padding-top: 200px;
		float: left;

		&.fullwidth {
			display: none;
		}

		.field-name-field-testimonial-blog{
			&:before{
				content: ' " ';
				color: #0b1b54;
				line-height: 0;
				margin-left: -20px;
				font-size: 70px;
				font-style: italic;

			}
		}

		.field-name-field-testimonial-name-blog{
			font-family: $regular-font;
			font-style: italic;
			color: $grey-font;
			font-size: 13px;
			margin-top: 20px;
			&:before{
				content: '-';
				display: inline;
				float: left;
				padding-right: 5px;
			}
		}

		.field-name-field-image-sidebar-blog{
			margin-top: 70px;

			img{
				height: auto;
			}
		}

		.field-name-field-image-sidebar-blog-caption{
			font-family: $regular-font;
			font-style: italic;
			color: $grey-font;
			font-size: 13px;
			margin-top: 10px;
		}

	}

	.body-content{


		.field-name-field-sub-heading-blog{
			font-family: $bold-font;
			color: $medium-blue;
			font-size: 24px;
			line-height: 1.25;
			margin-bottom: 35px;
			margin-top: 15px;

		}

		.field-type-text-with-summary {
			p{
				margin-bottom: 2rem;
				line-height: 1.5em;
			}

			h3{
				a{
					font-family: $bold-font;
				}
			}
		}

	}
}

.tab-community {
	display:none;
}

#content{
	.blog-container{
		.p2{
			padding: 0.5rem;
		}
		.blog-hero-wrapper{
			.article-list{
				margin-bottom: 0.5rem;
			}
			.content{
				.image-wrapper{
					&:after{
						display: none;
					}
					img{
						transition: none;
						transform: none;
						max-height: 600px;

					}
					&:hover{
						img{
							transition: none;
							transform: none !important;
						}
					}
				}
				.article-content-wrap{
					h2{
						line-height: 1;
						padding-left: 1rem;
						margin-bottom: 3rem;
						font-size: 70px;
						height: auto;
						&:hover{
							color: white;
						}
						a{
							color: white;
						}
					}
					.date{
						display: none;
					}
					.summary{
						padding-left: 1rem;
						line-height: 1.5em;
						font-size: 18px;
					}
					.view-all{
						margin-top: 0;
						margin-left: 1rem;
						margin-top: 40px;
					}
				}
			}
		}
	}
}
.node-type-blog{
	#content {
		.block{
			clear: both;
			padding: .5rem;
			.flippy{
				width: 75%;
				float: left;
				padding-right:0;
				padding-left:0;
				padding-top: 40px;
				padding-bottom: 2.5rem;
				border-top: 1px solid #eee;
				margin-bottom: 0;
				li{
					color: $light-blue;
					display: inline-block;
					text-decoration: none;
					border: 1px solid #eee;
					border-radius: 5px;
					height: 50px;
					line-height: 50px;
					padding: 0 20px;
					display:inline-block;
					vertical-align:middle;
					&:hover {
						background: #32a3d9;
						color: #fff;
						a {
							color:#FFF;
						}
						&:after {
							color:#FFF;
						}
						&:before {
							color:#FFF;
						}
					}
					&:before {
						color: $light-blue;
					}
					&:after {
						color: $light-blue;
					}
					&.prev{
						text-align: left;
						float: left;
						&:before{
							font-size: 0.75rem;
							content: "e";
							font-family: "amg" !important;
							font-style: normal !important;
							font-weight: normal !important;
							font-variant: normal !important;
							text-transform: none !important;
							speak: none;
							line-height: 1;
							-webkit-font-smoothing: antialiased;
							-moz-osx-font-smoothing: grayscale;
							margin-bottom:2px;
							margin-right:5px;
							vertical-align:middle;
						}
					}
					&.next{
						text-align: right;
						float: right;
						&:after{
							font-size: 0.75rem;
							content: "f";
							font-family: "amg" !important;
							font-style: normal !important;
							font-weight: normal !important;
							font-variant: normal !important;
							text-transform: none !important;
							speak: none;
							line-height: 1;
							-webkit-font-smoothing: antialiased;
							-moz-osx-font-smoothing: grayscale;
							margin-left:5px;
							vertical-align:middle;
						}
					}
					a{
						font-family: $bold-font;
    					text-transform: capitalize;
    					font-size:90%;
					}
				}
				p{
					text-align: center;
					color: #32a3d9;
					transition: all .1s linear;
					line-height: 2em;

					a{
						display: inline-block;
						text-decoration: none;
						border: 1px solid #eee;
						border-radius: 5px;
						height: 50px;
						line-height: 50px;
						padding: 0 20px;
						font-family:$bold-font;
						font-size:90%;
						&:after{
							color: $light-blue;
							font-size: 0.75rem;
							content: "f";
							font-family: "amg" !important;
							font-style: normal !important;
							font-weight: normal !important;
							font-variant: normal !important;
							text-transform: none !important;
							speak: none;
							padding-left: 5px;
							line-height: 1;
							-webkit-font-smoothing: antialiased;
							-moz-osx-font-smoothing: grayscale;
							display:inline-block;
							vertical-align:middle;
							margin-left:5px;
						}
					}
				}
			}
		}
	}
}

#header-region .block{
	padding: 0 !important;
}

.blog-container{
	.ad-wrapper{
		margin-top: 0.5rem;
	}
}

#content .share-list{
	padding-left: 0;
}

#disqus_thread{
	width: 75%;
	padding-left: 2rem;
}
.share-links{
	p{
		&.mr2{
			margin-right: 0.75rem;
		}
	}
}

.page-blog {
	#content {
		.event-ad-panel {
			.external-ads {
				max-height:300px;
			}
			.title {
				display:none;
			}
			img {
				width: 100%;
				height: 100%;
				vertical-align: top;
			}
			.left-col {
				.first {
					.ad-inner {
						padding:0;
					}
				}
			}
		}
	}
}

.blog-venue-title{
	clear: both;
	color: $dark-blue;
	font-size: 32px;
	font-family: $bold-font;
	padding: 1.75rem 2rem 2rem 2rem;
}

@media (min-width: 40em) {
	.blog-container {
		.blog-hero {
			.article-content-wrap {
				// bottom:40px;
				.date, .view-all {
					display:inline-block;
				}
				h2 {
					margin-bottom:10px;
					font-size:50px;
				}
			}
		}
		.view-content {
			.views-row {
				width:49%;
				margin-right:1%;
				&:nth-of-type(2n+2) {
					margin-right:0;
				}
			}
		}
	}
}

@media (min-width: 52em) {
	.blog-container {

		.blog-hero {
			.article-content-wrap {
				.summary {
					display:inline-block;
				}
			}
		}
		.view-content {
			.views-row {
				width:49.5%;
				&:nth-of-type(2n+2) {
					margin-right:0rem;
				}
			}
		}
	}
}

@media (max-width: 64em){
	#content .follow-panel, .right-col-wrapper{
		width: 100%;
	}
}

@media (max-width: 52em){
	#content .blog-container .blog-hero-wrapper .content .article-content-wrap h2{font-size: 50px;}
}

@media (max-width: 52em){
	#content{
		.follow-panel{
			width: 100%;
		}
	}

	.body-content{
		background: transparent;
		margin-top: 0;
		padding-top: 0;
	}

	.node-type-blog .block .flippy{
		width: 100%;
	}

}

@media (max-width: 40em){
	.body-content.col-8{
		width: 100%;
		padding-left: 2rem;
		padding-right: 2rem;
	}
	.sidebar-blog.col-4{
		width: 100%;
		padding-top: 20px;
		padding-bottom: 60px;
		padding-left: 2rem;
		padding-right: 2rem;

		.field-name-field-testimonial-blog{
			font-size: 16px;
		}
	}
	.blog-container .blog-article{
		padding-right: 0;

		.content{
			.px4{
				padding-left: 2rem;
				padding-right: 2rem;
				h1{
					line-height: 55px;
				}
			}
			.share-links{
				width: 100%;
				margin-top: 10px;
			}
		}
	}
	.node-type-blog .block .flippy p{
		margin-top: 80px;
	}
	.right-col-wrapper{
		width: 100%;
	}

	#content .blog-container .blog-hero-wrapper .content .article-content-wrap h2 { font-size: 30px; margin-bottom:0; }
	.blog-container .blog-hero .article-content-wrap {bottom: 0; top:10px;}
	#content .view-blog .article-list h2 { height: auto !important;}
}


.all-blog-posts {
	a{
	&:hover{
		background: $light-blue;
		color: white !important;
		&:after{
			color: white !important;
		}
		}
	}
}

/* Blog Lister */

#content{
	.view-blog{
		.views-row{
			margin-bottom: 4rem;
		}
		.article-list{
			.image-wrapper img {
				vertical-align: top;
			}
				&:hover .image-wrapper:after {opacity: 1;}
				//&:hover .image-wrapper img{ transform: scale(1.15);-webkit-transition-duration: 0.6s; /* Safari */ transition-duration: 0.6s; }
				//&:hover .article-content-wrap h2 a{color: $light-blue; }

			.image-wrapper{
						position: relative;
						overflow: hidden;
				 &:after{
						content: '';
						background: rgba(0, 3, 45 , 0.5);
						position: absolute;
						width: 100%;
						height: 100%;
						left: 0;
						top:0;
						opacity: 0;
						-webkit-transition: all 0.2s ease-in;
						-moz-transition: all 0.2s ease-in;
						-ms-transition: all 0.2s ease-in;
						-o-transition: all 0.2s ease-in;
						transition: all 0.2s ease-in;
					}
					// img{
					// 	transition: all .2s ease-in-out;
					// 	transform: scale(1.06);
					//
					// }
				}


			h2{

				padding-left: 2rem;
				padding-right: 2rem;
				padding-top: 1.75rem;
				padding-bottom: 0;
				margin-bottom: 1rem;
				font-size: 32px;
				line-height: 1;

				a{
					color: $dark-blue;
					font-family: $bold-font;
					&:hover{
						color: $light-blue;
					}
				}
			}
			span{
				&.date{
					padding-left: 2rem;
					padding-right: 2rem;
					margin-bottom: 1.25rem;
					padding-bottom: 0;

				}
			}
			.summary{
				padding-left: 2rem;
				padding-right: 2rem;
				line-height: 1.5;
				p{
					margin-bottom: 2rem;
					padding-bottom: 0;

				}
			}
			.view-all{
				padding-left: 2rem;
				padding-right: 2rem;
				margin-top: 4rem;
				margin-bottom: 2rem;
				font-family: $bold-font;
			}
		}

	}


	.blog-hero-wrapper{
			.article-list{
				&:hover .article-content-wrap h2 a{color: white; }
				.view-all {
					&:hover {
						color:#FFF;
					}
				}
				&:hover .image-wrapper img{ transform: none;-webkit-transition-duration: 0.6s; /* Safari */ transition-duration: 0.6s; }
			}
	}

	.blog-container .node-blog {
		.col.col-12.px4.mt3 {
			padding: 0 0.5em;
			margin-top: 1em;
		}
	}
	.blog-container .left-col-wrapper h1 {
		font-size: 40px;
		margin-bottom: .6em;
		line-height: 1.2;
	}


}


@media (max-width: 40em) {
	#content .view-blog .article-list {
		span.date, h2, .summary, .view-all {
			padding-left: 20px;
			padding-right: 20px;
		}
		h2 {
			margin-top: 0;
			margin-bottom: 0.3em;
			padding-top: 25px;
			font-size: 1.5rem;
		}
	}
}
