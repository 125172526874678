@charset "UTF-8";

body.page-venues {
	.node-hero-banner {
		position:relative;
	}
}

.venue-list-content {
	>.views-row {
		width:100%;
		float:left;
		min-height:400px;
		color:$grey-font;
		text-align:center;
		.views-field-field-venue-name {
			font-family:$bold-font;
			color:$dark-blue;
			font-size:20px;
			padding-left: 1rem;
			padding-bottom: 0.5rem;
			margin-top: 25px;
			a {
				color:$dark-blue;
				font-weight: bold;
				font-size: 21px;
				margin: 25px 0px 10px;
				height: 5pc;
			}
		}
		.views-field-field-local-address{
			padding-left: 1rem;
			font-size: 90%;
			line-height: 1.5;
			letter-spacing: 0;
		}
		img {
			object-fit: cover;
			height: 200px;
			width:280px;
		}
	}
}

.venue-header-wrapper{
	padding-left: 1.5rem;
	display: none;
	h1{
		font-weight: normal;
		margin: .67em 0 .67em;
	}
}

.view-venue-list{
	.node-hero-banner{
		padding: 0;
	}
}

.views-field-field-venue-image {
	cursor: pointer;
}



#content {
	.venue-list-content {
		a {
			color:$dark-blue;
			font-family: $bold-font;
			font-weight: normal;
		}

	}
	.field-name-field-hero-image{
		img{
			width: 100%;
			height: 100%;
		}
	}
}

@media (min-width: 52em) {
	.venue-list-content {
		>.views-row {
			width:32.66%;
			margin-right:1%;
			text-align:left;
			&:nth-of-type(3n+3) {
				margin-right:0;
			}
			img {
				width:100%;
			}
		}
	}
}

@media (max-width: 52em) {
	.venue-list-content>.views-row{
		width: 50%;
		&:nth-child(2n+1){
			padding-right: 0.5rem;
		}
		img{
			width: 100%;

		}
	}
}

@media (max-width: 40em) {
	.venue-list-content>.views-row {

		width: 100%;
		&:nth-child(2n+1){
			padding-right: 0;
		}
		img{
			width: 100%;
			max-width: 100%;
		}
	}
	.venue-list-content .views-row .views-field-field-venue-name { padding-left: 0; }
	.venue-list-content .views-row .views-field-field-local-address { padding-left: 0;}

	.view-venue-list .node-hero-banner {
		margin-bottom: 0.25rem;
		h2{
			font-size: 45px;
			padding-top: 0;
		}
	}
}
