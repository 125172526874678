@charset "UTF-8";

// Arts Club Styles
body {
	&.domain-academymusicgroup-com-artsclubliverpool, &.domain-academymusicgroup-com {
        #content {
    		.promo-page-container {
    			.content {
    				.field-name-field-accordion-item {
    					.field-name-field-accordion-title {
    						color:$arts-medium-grey;
    					}
    				}
    			}
				.promo-list {
					>.content {
						.field-type-link-field {
							.field-items {
								&:after {
									color:$arts-medium-grey;
								}
							}
							a {
								color:$arts-medium-grey;
							}
						}
					}
				}
    		}
        }
	}
}

// Halloween Promo Styles
body.page-halloweek {
	background-image:url(#{$images}/optimized/halloween-bg.png);
	background-repeat: no-repeat;
	background-size: contain;
	header#header {
		.top-links {
			background:transparent;
		}
	}
}

#content {
    .promo-page-container {
    	.top-hero-image {
    		position:relative;
    		img {
    			object-fit: cover;
    			height: 200px;
    			width:100%;
    		}
    		.hero-content-wrap {
    		    width: 100%;
    		    margin: auto;
    		    position: absolute;
    		    top: 0;
    		    left: 0;
    		    bottom: 0;
    		    right: 0;
    		    height: 45%;
    			h1 {
    				color:#FFF;
    				font-size:36px;
    				text-align:center;
    				margin:10px 0;
    			}
    			.tagline {
    				color:#FFF;
    				text-align:center;
    				display:none;
    			}
    		}
    	}
    	.promo-list {
    		.body-copy {
    			width:100%;
    			margin:0 auto;
    			p {
    				font-size:90%;
    			}
    		}
    		>.content {
    			width:100%;
    			margin:0 auto;
                .field-collection-view {
                    border-bottom:0;
                }
    			.field-collection-container {
    				border:0;
    			}
    			.promo-section-title {
    				border-top:1px solid #e7e7e7;
    				font-family:$bold-font;
    				text-align:center;
    				font-size:36px;
    				padding:10px 0;
    			}
    			.field-type-field-collection {
					text-align:center;
    				float:left;
    				width:100%;
                    >.field-items {
                        >.field-item {
                            width:100%;
							vertical-align:top;
                            display:inline-block;
                        }
                    }
    				img {
    					object-fit: cover;
    					height: 200px;
    					width:100%;
    				}
    				.field-type-text {
    					font-family:$bold-font;
						text-align:left;
						padding: 15px 15px 0;
					    font-size: 20px;
					    width: 100%;
					    float: left;
    				}
					.field-type-text+.field-type-text {
						font-size:100%;
					}
					.field-type-datetime {
						font-family:$bold-font;
						text-align:left;
					    padding: 15px 15px 0;
					    width: 100%;
					    float: left;
					    font-size: 90%;
					}
    				.field-type-text-long {
						padding: 15px 15px 0;
						text-align:left;
					    font-size: 90%;
					    color: gray;
					    width: 100%;
					    float: left;
    				}
    				.field-type-link-field {
    					padding:20px 15px;
						text-align:left;
    					width:100%;
    					float:left;
    					.field-items {
    						float:left;
    						.field-item {
    							float:left;
    							padding-right:5px;
    						}
    						&:hover {
    							&:after {
    								color:$dark-blue;
    							}
    						}
    						&:after {
    							content: "f";
    							vertical-align:middle;
    							color:$light-blue;
    							font-family: "amg" !important;
    							font-style: normal !important;
    							font-weight: normal !important;
    							font-variant: normal !important;
    							text-transform: none !important;
    							speak: none;
    							line-height: 1;
    							-webkit-font-smoothing: antialiased;
    						}
    					}
    				}
    			}
                .field-type-link-field {
                    &:hover {
                        a {
                            color:$dark-blue;
                        }
                        .field-items {
                            &:after {
                                color:$dark-blue;
                            }
                        }
                    }
                }
    		}
    	}
    }
}

@media (min-width: 40em) {
    #content {
    	.promo-page-container {
    		.top-hero-image {
    			img {
    				height:300px;
    			}
    			.hero-content-wrap {
    				h1 {
    					font-size:75px;
    					margin:20px 0;
    				}
    				.tagline {
    					display:block;
    				}
    			}
    		}
    		.promo-list {
    			.body-copy {
    				margin: 40px auto 50px;
    				width:70%;
    			}
    			>.content {
    				width:100%;
    				.field-type-field-collection {
                        >.field-items {
                            >.field-item {
                                width:50%;
                            }
                        }
    				}
    			}
    		}
    	}
    }
}

@media (min-width: 52em) {
    #content {
    	.promo-page-container {
    		.top-hero-image {
    			img {
    				height:400px;
    			}
    		}
    		.promo-list {
    			>.content {
                    .promo-section-title {
                        padding:30px 0;
                    }
    				.field-type-field-collection {
    					>.field-items {
                            >.field-item {
                                width:33%;
                            }
                        }
    				}
    			}
    		}
    	}
    }
}

@media (min-width: 64em) {
    #content {
    	.promo-page-container {
    		.promo-list {
    			>.content {
    				.field-type-field-collection {
                        >.field-items {
                            >.field-item {
                                width:25%;
                            }
                        }
    				}
    			}
    		}
    	}

    }
}

// Beat the January blues promo
body.page-beat-the-blues {
	background: #040404 url(../../images/optimized/Beat-the-Blues.jpg) center top no-repeat;
	background-attachment: fixed;
	header#header .top-links {
		padding-top: 1rem;
		margin-top: 0;
	}
}


body.page-content-fireball-fuelling-fire-tour-2018 {
	background: url(#{$images}/optimized/Fireball-2018.jpg) center top no-repeat;
	background-attachment: fixed;
	header#header .top-links {
	  padding-top: 1rem;
	  margin-top: 0;
	}

}

body.page-competition-open-fireball-fuelling-fire-tour-2018 {
  background: url(#{$images}/optimized/Fireball-2018-comp.jpg) center top no-repeat;
  background-attachment: fixed;
  header#header .top-links {
    padding-top: 1rem;
    margin-top: 0;
  }

}
