@charset "UTF-8";

// Arts Club Styles
body {
	&.domain-academymusicgroup-com-artsclubliverpool {
		.about-container {
			.field-name-field-list-item {
				.field-name-field-list-item-title {
					color:$arts-medium-grey;
				}
			}
		}
	}
}

.about-container {
	.top-hero-image {
		position:relative;
		img {
			object-fit: cover;
			height: 200px;
		}
		.hero-content-wrap {
		    width: 100%;
		    margin: auto;
		    position: absolute;
		    top: 0;
		    left: 0;
		    bottom: 0;
		    right: 0;
		    height: 45%;
			h1 {
				color:#FFF;
				font-size:36px;
				text-align:center;
				margin:10px 0;
			}
		}
	}

	.content {
		.field-name-field-list-item  {
			>.field-items {
				float:left;
				width:100%;
				>.field-item {
					width:100%;
					float:left;
					padding-bottom:2em;
					margin-bottom:50px;
				}
			}
			.field-name-field-list-item-title {
				color:$light-blue;
				font-size:64px;
				font-family:$bold-font;
				padding-bottom:10px;
			}
		}
		.field-item {
			.field-collection-view {
				text-align:center;
				color:$grey-font;
				font-size:90%;
				margin:0;
				border:0;
				img {
					height:auto;
				}
				.field-name-field-spotlight-title {
					color:$medium-blue;
					font-size:32px;
					font-family:$bold-font;
					margin:0.5em 0;
				}
			}
		}
	}
}

#content{
	.node-about-us{
		.body-copy {
			margin-bottom: 60px;
			.field-type-text-with-summary{
			text-align:center;
			padding:2em;
			border-bottom:1px solid $grey-border;
			margin-bottom:1em;
				p {
					font-size:100%;
					line-height: 2em;
				}
			}
		}
		.field-collection-item-field-list-item{
			.content{
				min-height: 230px;

			}
			.field-name-field-list-item-text{
				.field-item{
					line-height: 1.5em;

				}
			}
		}
	}
}

@media (min-width: 40em) {
.about-container {
	.top-hero-image {
		img {
			height:300px;
		}
		.hero-content-wrap {
			h1 {
				font-size:75px;
				margin:20px 0;
			}
		}
	}
	.content-wrap {
		padding:2em 4em;
	}
	.content {
		.field-name-field-list-item  {
			>.field-items {
				>.field-item {
					width:33%;
					padding:0 2em;
				}
			}
		}
		.field-item {
			&.even {
				.field-collection-view  {
					margin-right:0.5em;
				}
			}
			&.odd {
				.field-collection-view  {
					margin-left:0.5em;
				}
			}
		}
	}
}
}

@media (min-width: 52em) {
.about-container {
	.top-hero-image {
		img {
			height:400px;
		}
	}
}
}
