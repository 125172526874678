@charset "UTF-8";

#startmonday-jobs-widget {
	iframe:nth-child(2) {
		display: none;
	}
}

.basic-page-container {
	.top-hero-image {
		position:relative;
		img {
			object-fit: cover;
			height: 200px;
		}
		.hero-content-wrap {
		    width: 100%;
		    margin: auto;
		    position: absolute;
		    top: 0;
		    left: 0;
		    bottom: 0;
		    right: 0;
		    height: 45%;
			h1 {
				color:#FFF;
				font-size:36px;
				text-align:center;
				margin:10px 0;
			}
		}
	}
	.body-copy {
		text-align:center;
		padding:2em;
		// border-bottom:1px solid $grey-border;
		margin-bottom:1em;
		p {
			font-size:90%;
		}
	}
	.content {

	}
}

.node-type-work-for-us {
	.field-collection-container {
		margin: 0;
		border: 0;
	}
}

@media (min-width: 40em) {
.basic-page-container {
	.top-hero-image {
		img {
			height:300px;
		}
		.hero-content-wrap {
			h1 {
				font-size:75px;
				margin:20px 0;
			}
		}
	}
	.content-wrap {
		padding:2em 4em;
	}
	.content {

	}
}
}

@media (min-width: 52em) {
.basic-page-container {
	.top-hero-image {
		img {
			height:400px;
		}
	}
}
}

// Basic Page - Access

#content{
  .basic-page-container{
    .body-copy{
      font-size: 100%;
      p{
        text-align: left;
        font-size: 1rem;
        line-height: 2em;
        font-size: 100%;
      }
      h2{
        font-size: 1.75em;
        text-align: left;
        margin-top: 2em;
        margin-bottom: .5em;
      }
    }
  }
}
