@charset "UTF-8";




header {
	color:#FFF;
	z-index: 99!important;
	position:relative;
	.top-links {
		background-color:$dark-blue;
		//padding-top:0;
		#secondary {
			li {
				padding:0 1em;
				a {
					font-family:$bold-font;
					font-size:90%;
				}
			}
		}
		.social-media {
			// margin-top: 10px;
			li {
				margin-right:1em;
				display: inline-block;
				border: 2px solid white;
				height: 32px;
				width:32px;
				border-radius: 32px;
				text-align:center;
				a {
					color:#FFF;
				}
				i {
					display: inline-block;
					padding: 2px;
					line-height:32px;
					font-size:18px;
				}
			}
		}
		.right {
			position: relative;
			top: 7px;
		}
	}
	.menu {
		li {
			a {
				height:100%;
				display:inline-block;
			}
		}
	}
	.top-head {
		background-color:$medium-blue;
		padding-left:1em;
		padding-right:1em;
		height:70px;
		#logo {
			text-align:center;
			padding-bottom:0;
			height:50px;
			padding:0 2em;
			img {
				height:35px;
				//height:100%;
			}
		}
		.icon-search {
			font-size: 18px;
			line-height: 0;
			cursor: pointer;
			padding: 10px;
			&:before{
				width: 28px;
				height: 30px;
			}
		}
		.icon-menu {
			font-size:28px;
			width: 28px;
			height: 30px;
		}
	}
	a {
		color:#FFF;
		text-decoration:none;
	}
	ul {
		margin:0;
		li {
			display:inline-block;
			&:last-child {
				margin:0;
			}
		}
	}
	.search-bar {
		right: 0;
		position: absolute;
		width: 100%;
		top: 100%;
		background-color: $medium-blue;
		border-top: 1px solid $dark-blue;
		padding-right: 1em;
		padding-left: 1em;
		font-size:16px;
		transition: transform .3s cubic-bezier(0.42, 0, 0.46, 1.07),opacity .3s cubic-bezier(0.6, 0.29, 0.21, 0.99);
		-webkit-transform: translate3d(0,-100%,0);
		transform: translate3d(0,-100%,0);
		z-index: -1;
		opacity: 0;
		height: 100%;
		&.display {
			-webkit-transform: translate3d(0,0%,0);
			transform: translate3d(0,0%,0);
			opacity: 1;
		}
		form {
			float:right;
			position:relative;
			width:100%;
			margin: 0;
			.form-actions {
				position: absolute;
				right: 11px;
				top: 10px;
				width: auto;
				z-index: 1;
			}
			.form-item {
				margin: 0;
			}
			input[type=text] {
				border:0;
				border-radius: 3px;
				background-color:#17275d;
				padding: 10px 35px 10px 15px;
				width:100%;
				line-height: 40px;
				height: 40px;
				color:#ffffff;
				color: rgba(#ffffff, 0.8);

				transition:all .4s cubic-bezier(0.43, 0.35, 0.03, 0.99);
				position: relative;
				z-index: 2;

				width: 40px;
				float: right;
				background-color: transparent;


				&::-webkit-input-placeholder {
					color:#ffffff;
				   color: rgba(#ffffff, 0.8);
				}
				&:-moz-placeholder { /* Firefox 18- */
					color:#ffffff;
				   color: rgba(#ffffff, 0.8);
				}
				&::-moz-placeholder {  /* Firefox 19+ */
					color:#ffffff;
				   color: rgba(#ffffff, 0.8);
				}
				&:-ms-input-placeholder {
					color:#ffffff;
				   color: rgba(#ffffff, 0.8);
				}
				&:focus, &.active {
					color:#ffffff;
					background-color:#17275d;
					width: 200px;
					z-index: 0;
				}
			}
		}
	}
}

.mm-slideout {
	overflow:hidden;
}

@media (min-width: 40em) {
	header#header {
		.top-head {
			height:75px;
			#logo {
				img {
					height:50px;
				}
			}
		}
	}
}

@media (min-width: 64em) {

	header#header {
		.top-head {
			padding-left:2em;
			padding-right:2em;
			height:84px;
			#logo {
				text-align:left;
				padding-top:1em;
				height:84px;
				padding-bottom:1em;
				padding-left:0;
				padding-right:0;
				align-items: center;
				    display: flex;
			}
		}
		#desktop-nav {
			padding:0 1em;
		}
		.search-bar {
			display: block;
	    position: relative;
	    width: 16.66667%;
	    float: right;
	    right:inherit;
	    padding-left:0;
	    padding-right:0;
			z-index: 1;
			opacity: 1;
		}
	}

}

@media (max-width: 64em) {

	// Arts Club Styling
	body {
		&.domain-academymusicgroup-com-artsclubliverpool {
			header#header {
				.search-bar {
					form {
						input[type=text] {
							background-color:$arts-medium-grey;
						}
					}
				}
			}
		}
	}
	header#header .search-bar form .form-actions {
		display: none;
	}
	header#header .search-bar form input[type=text] {
		font-size: 0.9em;
		background-color: #17275d;
		width: 100% !important;
	}
}
