// Arts Club Styles

.domain-academymusicgroup-com-artsclubliverpool {

  background-color: $arts-darker-grey;

  header#header {
    .top-head, .search-bar {
      background-color: $arts-dark-grey;

      form {
        input[type=text] {
          &:focus, .active {
            background-color: #202020;
          }
        }
      }
    }

    .top-links {
      background-color: $arts-darker-grey;
    }
  }

  // Navigation

  #mobile-nav {
    background-color: $arts-dark-grey;

    .secondary-m {
      background-color: $arts-light-grey;
      border-bottom: 1px solid $arts-dark-grey;
    }

    .mm-navbar {
      a {
        background-color: $arts-light-grey;
      }
    }

    .mm-listview {
      li {
        .mm-next, a {
          border-bottom: 1px solid $arts-light-grey;
        }
      }
    }
  }


  #desktop-nav {
    ul {
      > li {

        a {
          &:hover,
          &:active,
          &.active {
            background-color: $arts-yellow;
            color: $arts-dark-grey;
          }
        }

        &:hover {
          background-color: $arts-darker-grey;
        }

        ul {
          background: $arts-darker-grey;
        }
      }
    }
  }


  &.page-content-403 .top-hero-image .hero-content-wrap h1,
  &.page-content-404 .top-hero-image .hero-content-wrap h1 {
    background-image: none;
    -webkit-text-fill-color: $arts-dark-grey;
    color: $arts-dark-grey;
  }


  &.page-search {
    #content {
      a {
        color: #FFF;
      }

    }
  }


  //#content {
  // Change error color to O2 shade of red
  form .form-item input.error, form .form-item textarea.error, form .form-item select.error {
    border: 2px solid $arts-error;
  }

  div.error, table tr.error {
    background-color: $arts-error !important;
    border-color: $arts-error !important;
  }

  label.error {
    color: $arts-error;
  }

  div.status, table tr.ok {
    background-color: $arts-ok !important;
  }

  //a {
  //  color: $arts-medium-grey;
  //}

  .follow-panel {
    background-color: $arts-light-grey;

    .follow-links {
      border-color: #9D9D9D;;

      li {
        border-color: #9D9D9D;

        &.twitter {
          border-bottom: 1px solid #9D9D9D;
        }

        &.instagram {
          border-right: 1px solid #9D9D9D;
        }
      }
    }
  }


  .node-hero-banner {
    h2 {
      color: #FFF;
      text-align: center;
    }
  }

  h1, h2 {
    color: $arts-medium-grey;
  }

  h3, h4, h5 {
    color: $arts-medium-grey-font;
  }


  // User Menu
  .amg-user-menu {
    a {
      color: #ffffff;
    }

  }


  // Buttons
  .btn {
    &.white {
      &:hover {
        background-color: $arts-light-grey;
        border-color: $arts-medium-grey;
      }
    }

    &.blue {
      background-color: $arts-yellow;
      color: $arts-dark-grey;

      &:hover {
        background-color: darken($arts-yellow, 10%) !important;
        color: #FFF;
      }
    }

    &.dark-blue {
      background-color: #666666;
      color: $arts-yellow !important;

      &:hover {
        color: #FFF;
        background-color: darken($arts-yellow, 10%) !important;
      }
    }

    &.purple {
      background-color: $arts-yellow !important;

      &:hover {
        //color: #FFF;
        background-color: darken($arts-yellow, 10%) !important;
      }
    }

  }



  .newsletter-panel .email svg {
    color: $arts-yellow !important;
    opacity: 0.8;
  }


  #content {
    .field-name-field-notification-message {
      a {
        color: $arts-darker-grey;
      }
    }

    a {
      color: $arts-darker-grey;
    }

    .follow-panel {
      background-color: $arts-yellow !important;
    }
  }


  .top-panel {
    border-bottom: 2px solid $arts-yellow !important;

    .view-options {

      a {
        color: #ccc;

        &.grid-view {
          color: #ccc;
        }

        &.list-view {
          color: $arts-yellow;
        }
      }
    }
  }

  h1 {
    color: $arts-yellow !important;
  }

  .pane-bundle-article-panel .field-name-field-article-link {
    color: $arts-yellow !important;
  }

  .event-panel-background .group-event-details .field-name-field-event-link {
    color: $arts-yellow !important;
  }

  .pane-bundle-event-panel a.group-event-link-wrap:after {
    background-color: $arts-medium-grey !important;
  }

  .pane-bundle-event-panel a.group-event-link-wrap:hover:after {
    background-color: $arts-medium-grey !important;
  }

  .article-panel-background:after {
    background-color: $arts-medium-grey !important;
  }

  .notifications .pane-bundle-notification-panel {
    background-color: $arts-dark-grey !important;
  }

  #content .notifications .pane-bundle-notification-panel .field-label {
    color: $arts-yellow;
  }

  .hero-header {
    .event-date {
      background-color: $arts-yellow !important;

      p {
        color: $arts-dark-grey !important;
      }
    }
  }


  .newsletter-panel {

    background-color: $arts-dark-grey !important;
    padding-top: 1em;
    padding-bottom: 1em;


    .header {
      background-color: #000000;

      svg {
        color: $arts-yellow !important;
      }
    }

    .buttons {
      & > div {
        a {
          background-color: $arts-yellow !important;

          span {
            font-size: 130%;
          }
        }
      }

    }
  }


  .related-videos-panel {
    background-color: $arts-dark-grey !important;

    a {
      color: #ffffff !important;
    }

    .o2videos {
      display: none !important;
    }
  }

  #content {
    .events-calendar {
      .date-heading {
        span {
          color: $arts-medium-grey;
        }
      }

      .view-calendar {
        .view-empty {
          p {
            color: $arts-light-grey;
          }
        }
      }

      .more-link {
        .view-all {
          border: 0;
          color: $arts-medium-grey;

          &:hover {
            background-color: transparent;
          }
        }
      }
    }

  }


  #footer {
    .left-col {
      background-color: $arts-medium-grey;
    }

    .right-col {
      //.top-footer {
      //  background-color: #FFF;
      //}

      .bottom-footer {
        background-color: $arts-darker-grey;
      }
    }

    .menu-footer-links {
      background-color: #666666;
    }

    .menu-venue-list {
      background-color: $arts-darker-grey;
    }
  }


  #content {
    .event-item {
      .event-date {

        span {
          color: $arts-yellow;

        }

      }

      .event-info {
        color: #808080;

        h3 {

          a {

            color: $dark-blue;

          }
        }

      }
    }
  }



  .local-info {
    .top-section {
      h1 {
        color: $arts-medium-grey;
      }
    }

    .tabs-local-info {
      ul {
        a {
          color: $arts-light-grey !important;
        }

        li {
          &:hover {
            border-color: $arts-medium-grey !important;

            a {
              color: $arts-medium-grey !important;
            }
          }

          &:active {
            border-color: $arts-yellow !important;
          }
        }
      }
    }
  }

  #content {
    .blog-container {

      .sidebar-blog {
        color: $arts-medium-grey-font;
      }

      .sidebar-blog {
        color: $arts-medium-grey-font;
      }

      .body-content {
        .field-name-field-sub-heading-blog {
          color: $arts-medium-grey;
        }
      }

      .sidebar-blog {
        .field-name-field-testimonial-blog {
          &:before {
            color: $arts-medium-grey;
          }
        }
      }

      .blog-hero .article-content-wrap .view-all:hover {
        background-color: $arts-dark-grey;
      }
    }


    .block {
      .flippy {
        p {
          a {
            &:hover {
              background-color: $arts-medium-grey;
              color: #FFF;
            }

            &:after, &:before {
              color: $arts-medium-grey;
            }
          }
        }

        li {
          color: $arts-medium-grey;

          &:hover {
            background-color: $arts-medium-grey;
            color: #FFF;
          }

          &:after, &:before {
            color: $arts-medium-grey;
          }

          a {
            &:after {
              color: $arts-medium-grey;
            }
          }
        }
      }
    }

    .view-blog {
      .article-list {
        h2 {
          a {
            color: $arts-medium-grey;

            &:hover {
              color: $arts-medium-grey;
            }
          }
        }

        .image-wrapper {
          &:after {
            background: rgba(26, 26, 26, 0.5);
          }
        }
      }
    }

  }


  .item-list {
    .pager {



      a {
        color: $arts-dark-grey;
      }

      .pager-current {
        color: $arts-yellow;
      }

      .pager-first,
      .pager-previous,
      .pager-item,
      .pager-next,
      .pager-last {

        a {


          &:hover {
            background: $arts-yellow;
            color: white!important;

            //&:before,
            //&:after {
            //  color: white;
            //}
          }

        }

      }

      .pager-next,
      .pager-last {

        a:after {
          color: $arts-yellow;
          content: "f";
          font-family: "amg" !important;
        }
      }

      .pager-last a:after {
        content: "ff";
      }

      .pager-previous,
      .pager-first {
        a:before {
          color: $arts-yellow;
          content: "e";
          font-family: "amg" !important;
        }
      }

      .pager-first a:before {
        content: "ee";
      }

      .pager-last, .pager-first, .pager-current, .pager-ellipsis {
        //display: none;
      }
    }
  }


}
