@charset "UTF-8";

body.page-competition-win-chance-open-less-jake-your-home-town {
    background-image:url(#{$images}/optimized/fireball_left.png), url(#{$images}/optimized/fireball_right.png);
    background-position: left 10%, right 10%;
    background-repeat:no-repeat;
}

.competition-page {
  .field-name-body {
      margin-top:50px;
      margin-bottom:50px;
      h4 {
          margin-top:0;
          margin-bottom:20px;
          color:$light-blue;
      }
  }
  .form-radios {
      float:left;
      width:100%;
      label {
          width:auto;
      }
  }
  .webform-component {
      width:100%;
      label {
          margin-right:0;
      }
  }
  .webform-component-radios {
    .form-type-radio {
      input[type="radio"] {
        float:left;
        margin-top:15px;
      }
      label {
        padding-left:20px;
      }
    }
  }
  .field-name-field-competition-user-details {
    legend {
      display:none;
    }
  }
  .left-col-wrapper {
    img {
      margin-bottom:1em;
      height:auto;
    }
    .webform-component-textfield, .webform-component-email {
      label {
        margin-bottom:0.5em;
      }
    }
  }
  .competition-disclaimer {
    margin:20px 0;
    font-size:80%;
  }
}

.view-competition-list {
  .competition-page-list-item {
    img {
      object-fit: cover;
      margin-bottom:0.5em;
      height: 250px;
    }
    .read-more {
      font-family:$bold-font;
    }
  }
}
.field-name-field-competition-image {
    float: left;
    width: 100%;
}
.field-name-field-competition-logo {
    float:left;
    padding-left:30px;
    padding-right:50px;
    width:30%;
}
.head-info {
    width:70%;
    h1 {
        margin-top:0;
    }
}

#content {
  .webform-confirmation {
    text-align:center;
    padding-top:3em;
    +.links {
      text-align:center;
      padding-top:1em;
      padding-bottom:3em;
      a {
        border-radius: 3px;
        padding: 0 10px;
        font-family: "FrutigerLTPro-Bold",sans-serif;
        font-size: 90%;
        text-decoration: none;
        display: inline-block;
        text-align: center;
        transition: all 0.1s linear;
        color:#FFF;
        height: 36px;
        line-height: 36px;
        background-color:$light-blue;
        &:hover {
          background-color:$medium-blue;
        }
      }
    }
  }
}

.competition-list-page {
  .competition-header-wrapper {
    background-image:url(#{$images}/optimized/hero-placeholder.png);
    background-size:cover;
    height:200px;
    width:100%;
    position:relative;
    h1 {
      position:absolute;
      top:35%;
      color:#FFF;
      text-align:center;
      font-size:36px;
      width:100%;
    }
  }
}

@media (max-width: 40em) {
    .competition-page {
        .top {
            .field-name-field-competition-logo {
                text-align: center;
                margin: 0 auto;
                width: 100%;
            }
            .head-info {
                width:100%;
                text-align:center;
            }
            .share-links {
                width:100%;
            }
        }
    }
}

@media (min-width: 52em) {
  .competition-page {
      .webform-component {
          &.webform-component-textfield {
              input {
                  width:40%;
              }
          }
      }
      .top {
          .head-info {
              h1 {
                  font-size:48px;
                  margin-bottom:0;
              }
          }
      }
      .form-textarea-wrapper {
          width:70%;
      }
     .webform-component-radios {
         >label {
             width:30%;
         }
     }
     .form-radios {
         width:70%;
     }
     .competition-disclaimer {
         margin:20px 50px;
     }
     .field-name-body {
         margin:50px;
     }
     .webform-client-form {
         margin:0 50px;
     }
    .left-col-wrapper {
      .content {
        padding-right: 1em;
      }
      .webform-component-textfield {
        width:100%;
        float:left;
        margin-right:2%;
        label {
          margin-bottom:0.5em;
          margin-right:0;
          width:30%;
        }
        label, input {
          //width:100%;
          float:left;
        }
      }
      .webform-component-email {
        width:60%;
        float:left;
        margin-top:0;
        label {
          margin-bottom:0.5em;
        }
        label, input {
          width:100%;
          float:left;
        }
      }
    }
  }
  .competition-list-page {
    .competition-header-wrapper {
      height:400px;
      h1 {
        top:25%;
        font-size:75px;
      }
    }
  }
}
