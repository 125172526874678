// TBC

.crm-api-2-forms {
  margin: 3em;
  //

  legend {
    margin: 0;
    padding: 0 0.5em 0.5em 0;
    font-size: 1.2em;
    color: #969696;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

  fieldset {
    background-color: #efefef;
    border-radius: 1em;
    border: 1px solid silver;
    margin: 0 0 1.5em;
    padding: 1.5em 1em;

    .fieldset-wrapper {
    }

    .fieldset-description {
      line-height: 1.5em;
      padding-bottom: 0.5em;
      color: #666;
      font-weight: 300;
    }
  }

  .form-actions {
    margin: 0 0 2em;
  }

  .form-type-password-confirm {
    label[for="edit-password"] {
      display: none;
    }
  }

  #edit-marketing-preferences {

    .form-type-checkboxes {
      .description {
        margin: 0;
        padding: 0;
        color: #666;
      }
      label {
        margin: 0 0 0.5em 0;
        padding: 0;
        width: 100%;
      }

    }



    .form-type-checkbox {
      width: auto;

      //width: 50%;
      //@media (min-width: 52em) {
      //  width: 50%;
      //}

      margin: 0;
      button {
        margin: 0 0.5em 0.5em 0;
        border-radius: 5px;
        line-height: 1em;
        transition: all 0.1s linear;
        &:hover {
          border: 1px solid #cccccc;
        }
        &.selected {
          background-color: #32a3d9;
          color: #fff;
        }
      }
    }

    .form-item-unsubscribe {
      margin-top: 0.5em;
      border-top: 1px dashed silver;
      padding-top: 1em;
      width: 100%;
    }

  }

  .form-type-checkbox {
    input[type=checkbox] {
      margin: 0 1em 0 0;
    }
    label {
      margin: 0;
      padding: 0;
      width: 70%;
    }
    .description {
      padding: 0.5em 0 0;
      margin: 0;
      color: #666;
    }
  }

  .helper {
    margin: 0 0 1em 0;
    color: #666;
  }

}
#amg-crmapiv2-register-form {
}
body.domain-academymusicgroup-com-artsclubliverpool form input[type=submit], body.domain-academymusicgroup-com form input[type=submit] {
  background-color: #1a1a1a;
}