@charset "UTF-8";

// Arts Club Styles
body {
	&.domain-academymusicgroup-com-artsclubliverpool {
		#content {
			.view-gallery {
				.view-content {
					.views-row {
						h2 {
							a {
								color:$arts-medium-grey;
							}
						}
					}
				}
			}
			.related-gallery-event-block .view-gallery .views-row h3 {
				background:rgba(49, 49, 49, .5);
			}
			.related-gallery-event-block .view-gallery {
				border-top:1px solid #313131;
			}
		}
	}
}

#content {
	.view-gallery {
		&.relative-wrap {
			// min-height: 0;
		}
		.gallery-filter {
			label {
				display:none;
			}
			input {
				margin-top:0;
			}
			input[type="submit"] {
				transition: all 0.1s linear;
				&:hover {
					background-color:$dark-blue;
				}
			}
			.selectize-input {
				width:180px;
			}
		}
		.gallery-item {
			img {
				height:auto;
			}
			.views-field-title {
				span {
					a {

						font-size:1.5em;
						float: left;
						width: 100%;

					}
				}
			}
		}
		.field-name-field-gallery-hero {
			img {
				object-fit: cover;
				height: auto;
			}
		}
		.view-content {
			.views-row {
				color:$grey-font;
				font-size:90%;
				width:100%;
				float:left;
				min-height: 350px;
				&:nth-of-type(3n+3) {
					margin-right:0;
				}
				h2 {
					font-size:21px;
					margin:25px 20px 10px;
					//height: 80px;
					a {
						color:$dark-blue;
						font-family: $bold-font;
					}
				}
				.field-name-body {
					margin:0 20px;
				}
				.field-name-field-album-location {
					margin:0 20px 10px;
				}
				p {
					font-size:100%;
				}
				.views-field-node-gallery-media {
					img {
						height: 200px;
						width:100%;
						object-fit: cover;
					}
				}
				.views-field-name {
					display:none;
				}
				.views-field-title {
					padding: 5px 10px 0;
					display: inline-block;
					width: 100%;

				}
				.views-field-field-album-location {
					padding: 0 10px;
					display: inline-block;
				}
				.views-field-body {
					padding: 10px;
					display:inline-block;
				}
				.views-field-created {
					padding:5px 10px;
				}
				.views-field-title {
					//margin:0.5em;
					font-size:21px;
					padding: 20px .5em 10px;
					a {
						font-size:21px;
						line-height:1.2;
						color:$dark-blue;
						font-family: $bold-font;
					}
				}
			}
		}
		//.pager {
		//	padding-left:0;
		//	.pager-ellipsis, .pager-last, .pager-first  {
		//		display:none;
		//	}
		//	.pager-next {
		//		float:right;
		//	}
		//	.pager-previous {
		//		float:left;
		//	}
		//}
	}
}


.gallery-header-wrapper {
	padding-left: 0.5rem;
	//display: none;
	h1{
		font-weight: normal !important;
		margin: 0.4em 4rem 0.67em 0;
	}
	.gallery-header {
		background-image:url(#{$images}/optimized/hero-placeholder.png);
		background-size:cover;
		height:300px;
		position:relative;
		h1 {
			position:absolute;
			top:30%;
			width:100%;
			text-align:center;
			color:#FFF;
			font-size:52px;
			font-weight: normal !important;
			font-family: $bold-font;
		}
	}
}

.page-gallery {
	.view-content {
		//  {
		//	width:100%;
		//}
    .node-node-gallery-gallery,
		.node-youtube-videos,
    .node-video-upload {
			min-height:450px;
			&.node-teaser {
				width:100%;
				.content {
					margin:0;
				}
			}
			img {
				width:100%;
        height: auto;
			}
		}
		//.node-video-upload {
		//	&.node-teaser {
		//		width:100%;
    //
		//	}
		//}
	}
}

#content {
	.related-videos-panel {
		.header {
			height:66px;
		}
		.block {
			h3 {
				display:none;
			}
		}
		h3 {
			//margin: 20px;
			color: #fff;
			line-height:1.4em;
		}
		.view-gallery {
			.view-content {
				.views-row {
					width:25%;
					margin:0;
					float:left;
					position:relative;
					padding: 0 2%;
					min-height:auto;
					height:auto;
					&.first-element {
						p {
							font-size:1em;
							color:#FFF;
							a {
								color:#FFF;
							}
						}
					}
					p {
						font-size: .8em;
						margin: 1em .5em;
						text-align: center;
						a {
							color:#FFF;
						}
					}
					&.first-element {
						width:100%;
						margin-right:0;
						float:left;
						margin-bottom:10px;
						padding:0;
						.video-thumb {
							border:0;
							iframe {
								height:300px;
							}
							.overlay {
								z-index:-9;
								background-color:rgba(11, 27, 84, 0);
							}
						}
					}
					.video-thumb {
						position:relative;
						border-width: 1px;
						border-style: solid;
						border-color: rgba(255, 255, 255, 0.15);
						iframe {
							width:100%;
							height:100px;
							display:block;
						}
						.overlay {
							transition: all .2s linear;
							position:absolute;
							top:0;
							left:0;
							right:0;
							bottom:0;
							z-index:9;
							background-color:rgba(11, 27, 84, 0.2);
						}
					}
				}
			}
		}
	}
}

#content {
	.gallery-page {
		.view-node-gallery-gallery-item-views {
			.pager {
				display:none;
			}
		}
		.related-galleries {
			width:100%;
			padding-right:0;

			.views-row {
				img {
					height: 200px;
					width: 100%;
					object-fit: cover;
				}
			}
		}
		.hero-image {
			img {
				height:auto;
			}
		}
		.content {
			.flexslider {
				margin: 0;
				padding: 0;
				border: 0;
			}
			.flex-control-nav {
				position:absolute;
				bottom:0;
				li {
					a {
						background: transparent;
						border: 2px solid #fff;
						box-shadow: none;
						&.flex-active {
							background-color:#FFF;
						}
					}
				}
			}
			.views-row {
				width:33.33%;
				float:left;
				padding-right:1em;
				padding-bottom:1em;
				img {
					height:auto;
					float:left;
				}
			}
			.gallery-details {
				background-color:$medium-blue;
				color:#FFF;
				.author {
					display:inline-block;
					margin-left:1em;
					display:none;
				}
				h2 {
					color:#FFF;
					display:inline-block;
					margin:0;
				}
				.field {
					display:inline-block;
					margin-left:2em;
					.field-label, .field-items {
						display:inline-block;
					}
				}
				a, p {
					color:#FFF;
					margin:0;
				}
			}
		}
	}
}

.field-name-field-video-thumbnail {
	img {
		height:auto;
	}
}

.video-page {
	.content {
		iframe {
			width:100%;
			height:500px;
		}
	}
}

// Galleries block
.related-galleries {
	padding-right:1em;
	h3 {
		&.title {
			font-size:2em;
			font-weight: normal;
			font-family: $bold-font;
			letter-spacing: 0;
			margin: .67em 0 .67em;
			padding-left: 1.75rem;
		}
	}
	.views-row{
		a{
			font-weight: normal;
			font-family: $bold-font;
			padding-left: 0.5rem;
		}
	}
}

.related-gallery-block {
	.views-row  {
		width:48%;
		margin-bottom:1em;
		float:left;
		margin-right:4%;
		height: 330px;
		&.views-row-even {
			margin-right:0;
		}
		img {
			height:auto;
			width:100%;
			float:left;
		}
		p {
			margin-bottom:10px;
		}
		h3 {
			margin-top:10px;
			margin-bottom:5px;
			float:left;
			width:100%;
			font-weight: normal;
			font-family: $bold-font;
			letter-spacing: 0;
		}
	}
}

.related-gallery-event-block {
	position:relative;
	.view-all {
		position:absolute;
		right:1em;
		top:1em;
	}
	h3 {
		float:left;
		padding:1em;
		height:66px;
		margin:0;
	}
	.view-gallery {
		padding:10px 1em 0;
		border-top:1px solid $medium-blue;
		.views-row {
			position:relative;
			&:hover {
				h3 {
					opacity:1;
				}
			}
			h3 {
				transition: all .2s linear;
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				text-align: left;
				height: 100%;
				margin: 0;
				background: rgba(11,27,84,.5);
				vertical-align: middle;
				opacity: 0;
				color: #fff;
				padding-top: 0.7em;
				font-size: 1em;
			}
		}
		p {
			display:none;
		}
		.views-row {
			&:nth-of-type(4n+4) {
				margin-right:0 !important;
			}
		}
	}
	.pager {
		display:none;
	}
}

// Specific Heights

.view-display-id-photo_gallery, .view-display-id-venue_gallery{
	.views-row{
		height: 330px;
	}
}

.view-display-id-related_block{
	.views-row{
		h3{
			padding: 0 10px 0;
			color: $dark-blue;
		}
		p{
			padding: 0 10px 0;
      clear: both;
      &.body {

      }
		}
	}
}

// Colorbox styles
#colorbox {
	#cboxWrapper {
		background:none;
	}
	#cboxNext, #cboxPrevious {
		bottom:45%;
		background-repeat:no-repeat;
		background-position:center;
		width:60px;
		height:60px;
	}
	#cboxPrevious {
		left:0;
		background-image:url(#{$images}/optimized/gallery-arrow-left.png);
	}
	#cboxNext {
		right:0;
		left:auto;
		background-image:url(#{$images}/optimized/gallery-arrow-right.png);
	}
	#cboxClose {
		top:0;
		background-image:url(#{$images}/optimized/gallery-exit.png);
		background-repeat:no-repeat;
	    width: 60px;
	    height: 60px;
	    background-position: center;
	}
	#cboxCurrent {
		display:none!important;
	}
	#cboxLoadedContent {
		margin:0;
	}
	#cboxTitle {
		display:none!important;
	}
}

@media (min-width: 40em) {
	#content .view-gallery .view-content .views-row {
	    width: 32.6666%;
	    margin-right: 1%;
	}
	.related-gallery-block {
		.views-row  {
			width:32%;
			margin-right:2%;
			&.views-row-even {
				margin-right:2%;
			}
			&:nth-of-type(3n+3) {
				margin-right:0;
			}
		}
	}
	#content {
		.view-gallery {
			.view-content {
				.views-row {
					//width: 32.6666%;
					//margin-right:1%;
					&:nth-of-type(3n+3) {
						margin-right:0;
					}
				}
			}
		}
	}
}

@media (min-width: 52em) {
	.related-gallery-block {
		.views-row  {
			width:23.75%;
			margin-right:1.66%;
			&.views-row-even {
				margin-right:1.66%;
			}
			&:nth-of-type(3n+3) {
				margin-right:1.66%;
			}
			&:nth-of-type(4n+4) {
				margin-right:0;
			}
			p {
				font-size:90%;
			}
		}
	}
}

@media (min-width: 64em) {
	#content {
		.gallery-page {
			position:relative;
			min-height:1000px;
			.ad-area {
				width:300px;
				position:absolute;
				right:8px;
			}
			.left-col {
				padding-right:308px;
			}
		}
	}
}

@media (max-width: 40em) {
	#content .view-gallery .gallery-filter input[type=submit] {
		min-width: 90px;
	}
	#content .view-gallery .view-content .views-row h2 {
		margin: 0.5em 20px 10px;
	}
	.flex-control-nav li {
		margin: 0 4px;
	}
	.flex-control-paging li a {
		width: 6px;
		height: 6px;
	}
	.view-display-id-related_block .views-row h3 {
		padding: 0.5em 16px 0px;
	}
	.related-galleries .views-row a {
		padding-left: 0;
	}
	#content .view-gallery .view-content .views-row {
	    margin: 0 0 1em;
	    height: auto;
	    min-height: inherit;
	}
	#content .gallery-page .content .gallery-details .field {
		margin-left: 0;
	}
	.related-galleries h3.title {
		padding-left: 0;
	}
	.related-gallery-event-block .view-gallery .views-row h3 {
		opacity: 1;
		font-size: 1.5em;
	}
	#content .related-gallery-event-block .view-gallery .view-content .views-row {
		height: 110px;
		overflow: hidden;
		width: 49%;
	}
	#content .related-gallery-event-block .view-gallery .view-content .views-row.views-row-odd {
		margin-right:1%;
	}
	#content .related-gallery-event-block .view-gallery .view-content .views-row.views-row-even {
		margin-left:1%;
	}
	#content .related-videos-panel {
		overflow:hidden;
	}
	#content {
		.related-videos-panel {
			.view-gallery {
				.view-content {
					.views-row {
						width:48%;
						margin:0 1%;
						min-height:150px;
					}
				}
			}
		}
	}
}

@media (min-width: 40em) {
	#content {
		.related-videos-panel {
			height:610px;
		}
	}
}

body.node-type-event-calendar div.related-gallery-event-block div.related-gallery-block img {
  height: 92px;
}
