@charset "UTF-8";

#navigation {
  padding-top: 15px;
}

body {
  &.logged-in {
    #mobile-nav {
      a.log-in {
        display: none;
      }
    }
  }

  &.not-logged-in {
    #mobile-nav {
      a.my-account {
        display: none;
      }
    }
  }
}


#mobile-nav {
  background-color: $dark-blue;
  color: #FFF;
  border-top: 1px solid #050c40;
  //top: 185px;
  > .mm-panel .mm-navbar .mm-title {
    //display: none;
  }

  &.mm-hasnavbar-top-1 {
    .mm-panel {
      top: 0px;
      padding-top: 50px;
    }
  }

  .mm-navbar {
    border-color: #FFF;
    height: 50px;
    padding: 0;
    border: 0;

    &.mm-navbar-top {
      border: 0;
    }

    a {
      color: #FFF;
      height: 50px;
      line-height: 50px;
      padding: 0;
      font-size: 130%;
      background-color: rgba(11, 27, 84, 0.8);
    }
  }

  .mm-panel {
    .mm-prev {
      background: none;

      &:before {
        border-color: #FFF;
      }
    }
  }

  .mm-listview {
    border-color: #FFF;
    text-align: center;
    font-size: 130%;

    li {
      margin: 0;
      height: 60px;

      &.mm-selected {
        a {
          background-color: rgba(11, 27, 84, 0.8);
        }
      }

      a {
        width: 100%;
        height: 60px;
      }

      &:after {
        border: 0;
      }

      .mm-next {
        position: absolute;
        right: 0;
        top: 0;
        border-left: 1px solid #1a1b43;

        &:before {
          border: 0;
        }
      }

      .mm-next + a {
        margin: 0;
      }

      a {
        height: 60px;
        padding: 0 10px;
        line-height: 60px;
        border-bottom: 1px solid #1a1b43;
      }
    }

    .mm-arrow {
      &:after {
        border-color: #FFF;
      }
    }

    .mm-next {
      &:after {
        border-color: #FFF;
      }
    }
  }

  .mm-navbar-top {
    a {
      &.account-btn {
        width: 100%;
        float: left;
        height: 50px;
        line-height: 50px;
        background-color: $medium-blue;
        text-align: center;
        color: #FFF;
        font-size: 130%;
        border-top: 1px solid $dark-blue;
      }

      &.close-btn {
        position: absolute;
        right: 10px;
        top: 10px;
        color: #FFF;
        z-index: 9999;
      }
    }
  }
}


.menu-icon {
  display: inline-block;
  padding: 8px;
  margin-top: 4px;
  cursor: pointer;
  position: relative;
  z-index: 100;

  span {
    display: block;
    height: 2px;
    width: 20px;
    background-color: #fff;
    margin: 0 auto;
    margin-bottom: 4px;
    transition: all 0.2s ease-in-out;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.mm-opening {
  .menu-icon {
    span {
      transform: translate3d(0, 0, 0);
    }

    .mm1 {
      transform: rotate(45deg) translateX(6px) translateY(5px);
    }

    .mm2 {
      width: 0;
    }

    .mm3 {
      transform: rotate(-45deg) translateX(3px) translateY(-3px);
    }
  }
}

#mobile-nav.mm-menu.mm-offcanvas {
  position: absolute;
}

#mobile-nav.mm-hasnavbar-top-1 .mm-highest.mm-opened {
  top: 50px;
}

#mobile-nav {
  .secondary-m {
    position: relative;
    padding: 0;
    background-color: #0a1955;

    li {
      list-style: none;
    }
  }
}

.logged-in {
  #mobile-nav {
    .secondary-m {
      // The logout icon
      .last {
        position: absolute;
        top: 17px;
        right: 26px;

        a {
          font-size: 0;
          height: auto;
          line-height: 0;

          &:before {
            font-family: "amg" !important;
            font-style: normal !important;
            font-weight: normal !important;
            font-variant: normal !important;
            text-transform: none !important;
            speak: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: "s";
            font-size: 20px;
          }
        }
      }
    }
  }
}

.not-logged-in {
  .secondary-m {
    li {
      display: inline-block;
      padding: 0 1em;
    }
  }
}

@media (max-width: 64em) {
  header#header .top-links {
    display: none;
  }
}

html {
  body {
    #mobile-nav {
      &.mm-opened {
        top: 120px;
      }
    }

    &.page-amg-landing {
      #mobile-nav {
        &.mm-opened {
          top: 70px;
        }
      }
    }
  }
}

.page-amg-landing {
  #header .top-head .icon-search {
    display: none;
  }
}

@media (min-width: 40em) {
  html body #mobile-nav {
    &.mm-opened {
      top: 125px;
    }
  }
}

@media (min-width: 768px) {
  html {
    body {
      #mobile-nav {
        &.mm-opened {
          top: 165px;
        }
      }

      &.page-amg-landing {
        #mobile-nav {
          &.mm-opened {
            top: 75px;
          }
        }
      }
    }
  }
}

@media (min-width: 64em) {


  #mobile-nav {
    display: none;
  }

  #desktop-nav {
    ul {
      > li {
        position: relative;
        height: 84px;
        line-height: 84px;
        transition: all .1s linear;
        margin: 0;
        padding: 0;
        vertical-align: top;

        a {
          padding: 0 15px;

          &:hover,
          &:active,
          &.active {
            background-color: $light-blue;
            color: #ffffff;
          }
        }


        & > ul {
          display: none;
          //opacity:0;
          transition: all 0.1s linear;
        }

        &:hover {
          background-color: $dark-blue;

          & > ul {
            //opacity:1;
            display: block;
          }
        }

        ul {
          position: absolute;
          left: 0;
          top: 100%;
          z-index: 99;
          width: 300px;
          background: $dark-blue;
          padding: 0;

          li {
            width: 100%;
            margin: 0;
            height: 60px;
            line-height: 60px;
            float: left;


            a {
              width: 100%;
              display: inline-block;
            }
          }
        }
      }
    }
  }

  header#header {
    .top-head {
      padding-bottom: 0;
      padding-top: 0;
    }
  }

}
