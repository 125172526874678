
.view-calendar {

  position: relative;

  //.rescheduled-events {
  //  .block {
  //    text-align: center;
  //
  //    h3.title {
  //      font-family: $light-font;
  //      font-size: 180%;
  //    }
  //  }
  //}

  padding: 2em;

  @media (max-width: 40em) {
    padding: .5em;
  }

  &.view-display-id-events_block {

    .view-header {
      margin-bottom: 10px;

      .date-nav-wrapper {
        width: auto;
        float: left;
      }
    }

    .view-content {
      clear: both;
    }
  }


}
