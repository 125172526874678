@charset "UTF-8";

// Arts Club Styles
body {
	&.domain-academymusicgroup-com-artsclubliverpool {
		#content {
			.account-navigation {
				li {
					a {
						&:hover {
							color:$arts-dark-grey;
						}
					}
				}
			}
		}
		&.page-user-wishlist {
			#content {
				.account-navigation {
					li.my-wishlist {
						a {
							color:$arts-dark-grey;
						}
					}
				}
			}
		}
		&.page-user-newsletter {
			#content {
				.account-navigation {
					li.my-subscriptions {
						a {
							color:$arts-dark-grey;
						}
					}
				}
			}
		}
		&.page-user-edit {
			#content {
				.account-navigation {
					li.account-details {
						a {
							color:$arts-dark-grey;
						}
					}
				}
				.profile-form-wrapper {
					.form-wrap>h1:after {
						border-left: 125px solid $arts-yellow;
					}
					.form-item-pass:after {
						border-left: 110px solid $arts-yellow;
					}
					.field-name-field-birth-date:after {
						border-left: 75pt solid $arts-yellow;
					}
					.form-wrap {
						input#edit-submit {
							background-color:$arts-medium-grey;
						}
					}
				}
			}
		}
	}
}

#content {
	.account-navigation {
		display:inline-block;
		padding:3em 0;
		li {
			display:inline-block;
			padding:0 1em;
			border-right:1px solid #E5E5E5;
			&.last {
				border:0;
			}
			a {
				color:#CCC;
				font-size:28px;
				&:hover {
					color:$medium-blue;
				}
			}
		}
	}
}

.profile-form-wrapper {
	margin-bottom:2em;
	fieldset.collapsed {
		height:auto!important;
	}
	.fieldset-description {
		display:none;
	}
	.form-item-pass {
		position:relative;
		&:before {
			content:'Password';
			display:inline-block;
			width:100%;
			margin: 20px 0 30px;
			padding-bottom:20px;
			font-size:24px;
			border-bottom:2px solid $grey-border;
			font-family:$bold-font;
			color:$dark-blue;
		}
		&:after {
			content: '';
	    height: 2px;
	    float: left;
	    border-left: 110px solid #32A3D9;
	    position: absolute;
	    left: 0;
	    margin-bottom: 20px;
	    width: 100%;
	    top: 68px;
		}
	}
	.field-name-field-birth-date {
		position:relative;
		&:before {
			content:'Personal';
			display:inline-block;
			width:100%;
			margin: 20px 0 30px;
			padding-bottom:20px;
			font-size:24px;
			border-bottom:2px solid $grey-border;
			font-family:$bold-font;
			color:$dark-blue;
		}
		&:after {
			content: '';
	    height: 2px;
	    float: left;
	    border-left: 100px solid #32A3D9;
	    position: absolute;
	    left: 0;
	    margin-bottom: 20px;
	    width: 100%;
	    top: 68px;
		}
	}
	#edit-field-birth-date {
		float:left;
		width:100%;
		margin-top:1em;
		.date-combo {
			margin-left:0;
		}
	}
	.form-wrap {
		width:100%;
		margin:0 auto;
		>h1 {
			font-size:24px;
			border-bottom:2px solid $grey-border;
			padding-bottom:20px;
			margin-bottom:20px;
			position:relative;
			&:after {
				content: '';
		    height: 2px;
		    float: left;
		    border-left:125px solid #32A3D9;
		    position: absolute;
		    left: 0;
		    margin-bottom: 20px;
		    width: 100%;
		    top: 50px;
			}
		}
		input#edit-submit {
			background:$light-blue;
			font-family:$bold-font;
			border:0;
			height:50px;
			line-height:50px;
			padding:0 10px;
			text-transform:capitalize;
			text-shadow:none;
			font-weight:normal;
			width:120px;
			font-size:90%;
			transition: all 0.1s linear;
			&:hover {
				-webkit-box-shadow:none;
				background:$medium-blue;
			}
		}
	}
	#edit-subfolders {
		display:none;
	}
	#edit-contact {
		display:none;
	}
	#edit-timezone {
		display:none;
	}
	#edit-timezone {
	    padding-bottom: 1em;
	    width: 100%;
	    min-height: 70px;
	    a {
	    	color:$medium-blue;
	    }
	    &.collapsible {
	    	.fieldset-legend {
	    		background-position:0 65%;
	    	}
	    }
	    &.collapsed  {
	    	.fieldset-legend {
	    		background-position:0 50%;
	    	}
	    }
	}
	.form-actions {
		margin-bottom:3em;
	}
}
.newsletter-form-wrapper {
	.form-wrap {
		width:100%;
		margin:0 auto;
		.tagline {
			margin:10px 0 40px 0;
			border-bottom:1px solid $grey-border;
			padding:10px 0;
		}
		.form-item-unsub {
			label {
				width:auto;
			}
		}
		.form-actions {
			border-top:1px solid $grey-border;
			padding: 30px 0;
			margin-top: 50px;
		}
		label {
			width:90%;
		    height: 50px;
		    padding-top: 0;
		    line-height: 50px;
		}
		#edit-venues {
			input[type=checkbox] {
				margin-left:0;
			}
		}
	}
}


body {
	&.page-user-edit {
		#content {
			.account-navigation {
				li {
					&.account-details {
						a {
							color:$medium-blue;
						}
					}
				}
			}
		}
	}

	&.page-user-wishlist {
		#content {
			.account-navigation {
				li {
					&.my-wishlist {
						a {
							color:$medium-blue;
						}
					}
				}
			}
		}
	}
	&.page-user-newsletter {
		#content {
			.account-navigation {
				li {
					&.my-subscriptions {
						a {
							color:$medium-blue;
						}
					}
				}
			}
		}
	}
}

.page-account-resetpassword {
	#content-area {
		width: 100%;
		padding: 2em;
	}
}


@media (min-width: 40em) {
	.profile-form-wrapper {
		.form-wrap {
			width:60%;
		}
	}
	.newsletter-form-wrapper {
		.form-wrap {
			width:60%;
		}
	}
	.page-account-resetpassword {
		//#content-area {
		//	width: 60%;
		//	padding: 4em 2em;
		//	float: left;
		//}
	}
}

@media (min-width: 52em) {
	.newsletter-form-wrapper {
		#edit-venues {
			-webkit-column-count: 2;
			-moz-column-count: 2;
			column-count: 2;
			-webkit-column-gap: 2em;
			-moz-column-gap: 2em;
			column-gap: 2em;
			margin-bottom: 20px;
			border-bottom: 1px solid $grey-border;
			padding-bottom: 40px;
			float: left;
		}
		.form-item-unsub {
			float:left;
			width:64%;
			margin-top:0;
			#edit-unsub {
				margin-left:0;
				float:left;
			}
		}
		#edit-actions {
			float:right;
			width:36%;
			margin-top:0;
			border:0;
			padding-top:0;
		}
	}
}
