@charset "UTF-8";

form .selectize-control.error .selectize-dropdown, form .selectize-dropdown.error .selectize-dropdown {
	padding: 0;
	position: absolute;
}
.contact-page-container {
	.top-hero-image {
		position:relative;
		img {
			object-fit: cover;
			height: 200px;
		}
		.hero-content-wrap {
		    width: 100%;
		    margin: auto;
		    position: absolute;
		    top: 0;
		    left: 0;
		    bottom: 0;
		    right: 0;
		    height: 45%;
			h1 {
				color:#FFF;
				font-size:36px;
				text-align:center;
				margin:10px 0;
			}
		}
	}
	fieldset {
		border:0;
		input {
			border:1px solid $grey-border;
			//width:100%;
			height:50px;
			line-height:50px;
			border-radius:5px;
		}
		textarea {
			//width:100%;
			border-radius:5px;
			border:1px solid $grey-border;
			float: none;
		}
		.grippie {
			display: none;
		}
		input[type="submit"] {
			background-color:$light-blue;
			width:120px;
			color:#FFF;
			padding:0 15px;
			float:right;
			border:0;
		}
	}
	.selectize-input {
		input {
			height: auto;
			line-height: auto;
			font-size: 1rem;
		}
		> .item {
			font-size: 1rem;
		}
	}
	.body-copy {
		width:100%;
		margin:0 auto;
	}
	.content {
		&.contact-form-wrapper {
			form {
				fieldset {
					label {
						margin-bottom:0.5em;
					}
					float:left;
					width:100%;
					margin-bottom:1em;
				}
			}
		}
	}
}

@media (min-width: 40em) {
	.contact-page-container {
		.top-hero-image {
			img {
				height:300px;
			}
			.hero-content-wrap {
				h1 {
					font-size:48px;
					margin:20px 0;
				}
			}
		}
	}
}

@media (min-width: 52em) {
.contact-page-container {
	.top-hero-image {
		img {
			height:400px;
		}
	}
}
}
