@charset "UTF-8";


/* Hide error messages */
// .messages.error {
// 	display:none!important;
// }

* {
  outline: none !important;
}

/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box;

  body {
    font-size: 80%;
    min-width: 320px;
    line-height: 1.2;

    &.admin-menu {
      margin-top: 0 !important;

      #mobile-nav {
        //top:55px;
      }
    }

    #mfPreviewBar {
      display: none !important;
    }
  }
}

#admin-menu {
  display: none;
}

.ad-area {
  .ad-wrapper {
    //max-width:300px;
    float: right;
  }

  .follow-panel {
    //max-width:300px;
    float: right;
  }
}

// Disable scroll on google maps
.field-name-field-local-google-map {
  float: left;
  width: 100%;

  iframe {
    pointer-events: none;
  }
}

.ad-wrapper {
  position: relative;
  z-index: 1;
  text-align: center;
  width: 320px;
  //margin: 1rem auto 0;
}

#ad-slot-501 {
  div {
    margin: 0 auto;
  }
}

body {
  font-family: $regular-font;
  //letter-spacing:0.3px;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  //text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
  line-height: 1.2;

  strong, b {
    font-weight: normal;
    font-family: $bold-font;
  }
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {

  h1, h2, h3, h4, h5 {
    font-family: $bold-font;
    letter-spacing: 0;
    font-weight: normal;
    font-style: normal;
    color: $medium-blue;

    a {
      color: $medium-blue;
    }
  }
}

h1 {
  letter-spacing: 0;
}

a {
  text-decoration: none;
  font-family: $regular-font;

  &.view-all {
    color: $light-blue;
    transition: all 0.1s linear;

    &:hover {
      color: $medium-blue;
    }
  }

  &.read-more-link {
    color: $light-blue;
    text-decoration: none;
    transition: all 0.1s linear;

    &:hover {
      color: #FFF;
    }
  }
}

p {
  //font-size: 12px;
  color: $grey-font;
}

//ul {
//  padding: 0;
//
//  li {
//    list-style-type: none;
//  }
//}

#teconsent {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 9;

  img {
    float: left;
  }
}

#admin-menu {
  .dropdown {
    font-size: 12px;
  }
}

.btn {
  border-radius: 3px;
  padding: 0 5px;
  font-family: $bold-font;
  font-size: 90%;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  transition: all 0.1s linear;
  width: 100%;

  &.sml {
    height: 36px;
    line-height: 36px;
    font-size: 80%;
  }

  &.med {
    height: 40px;
    line-height: 40px;
    font-size: 100%

  }

  &.lrg {
    height: 50px;
    line-height: 50px;
    font-size: 120%;
  }

  &.blue {
    background-color: $light-blue;
    color: #FFF;

    a {
      color: #FFF;
    }

    &:hover {
      background-color: darken($light-blue, 10%);
    }
  }

  &.purple {
    background-color: $purple;
    color: #FFF;

    &:hover {
      background-color: #771F60;
    }
  }

  &.white {
    background-color: #FFF;
    color: $light-blue;
    border: 1px solid #e5e5e5;

    a {
      color: $light-blue;
    }

    &:hover {
      background-color: $light-blue;
      border-color: $light-blue;
      color: #FFF;

      a {
        color: #FFF !important;
      }
    }
  }

  &.black {
    background-color: #000;
    color: #ece635;
  }

  &.dark-blue {
    background-color: $dark-blue;
    color: #FFF;
  }
}

.ticket-sold-out {
  font-family: $bold-font;
  color: #000033;
  background-color: #FFF;
}

// Change error color to O2 shade of red
form .form-item input.error, form .form-item textarea.error, form .form-item select.error {
  border: 2px solid $amg-pink;
}

label.error {
  color: $amg-pink;
}

.icon-chevron-left-after {
  &:before {
    display: inline-block;
    font-size: 12px;
    vertical-align: middle;
    margin-right: 5px;
    margin-bottom: 2px;
  }
}

.icon-chevron-right-after {
  &:after {
    display: inline-block;
    font-size: 12px;
    vertical-align: middle;
    margin-left: 10px;
    margin-bottom: 2px;
  }
}

body {

  background: $dark-blue;
  font-size: $font-size;
  line-height: $line-height;

  .wrapper {
    max-width: 1250px;
    margin-right: auto;
    margin-left: auto;

    #main {
      background-color: #FFF;
    }
  }
}

#content {
  .lazyloader-icon {
    width: 16px !important;
    height: 16px !important;
  }

  //ul {
  //  //padding-left: 20px;
  //
  //  li {
  //    list-style-type: disc;
  //  }
  //}

  a {
    color: $light-blue;
    transition: all 0.1s linear;

    &:hover {
      color: $medium-blue;
    }

    &.blue {
      color: #FFF;

      &:hover {
        color: #FFF;
      }
    }

    &.purple {
      color: #FFF;

      &:hover {
        color: #FFF;
      }
    }

    &.white {
      &:hover {
        color: #FFF;
      }
    }
  }

  .follow-panel {
    background-color: $light-blue;
    color: #FFF;

    h3 {
      color: #FFF;
    }

    .follow-links {
      display: inline-block;
      border-top: 1px solid  #4dafde;
      padding-left: 0;

      li {
        list-style-type: none;
        height: 110px;

        a {
          color: #FFF;
        }

        &.facebook {
          border-right: 1px solid  #4dafde;
          border-bottom: 1px solid  #4dafde;
        }

        &.youtube {
          border-bottom: 1px solid  #4dafde;
        }

        &.twitter {
          border-right: 1px solid #4dafde;
        }

        a {
          color: #FFF;
          font-size: 28px;

          i {
            &:before {
              line-height: 3;
            }
          }
        }
      }
    }
  }
}

// Hero Banners

.node-hero-banner {
  padding: 0;

  &.p2 {
    padding: 0;
  }

  h2 {
    position: absolute;
    z-index: 90;
    font-size: 70px;
    color: white;
    font-family: $bold-font;
    padding-top: 80px;
    width: 100%;
    text-align: center;
  }

}

.all-link {
  display: inline-block;
  width: 100%;
  height: 100%;
  z-index: 90;
  position: relative;
}

#content {
  .back-to-home {
    display: none;
  }
}

.field-collection-view-links {
  font-size: 15px !important;
}

.share-list {
  display: inline-block;

  li {
    display: inline-block;
    color: $light-blue;
    margin-right: 15px;
  }
}

// 404 & 403 Errors
body {
  &.page-content-404, &.page-content-403 {
    .top-hero-image {
      .hero-content-wrap {
        height: 320px;
        position: relative;

        h1 {
          background: url(#{$images}/optimized/404.png);
          background: -o-linear-gradient(transparent, transparent);
          background-position: center;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 320px;
          font-family: $bold-font;
          margin: 100px 0 0 0;
          line-height: 1;
        }
      }
    }

    #content {
      .body-copy {
        padding-top: 0;
        padding-bottom: 0;
        border-bottom: 0;

        h2, h3 {
          margin: 0 0 10px;
          text-align: center;
        }

        p {
          text-align: center;
        }

        a.back-to-home {
          width: auto;
          padding: 0 15px;
        }
      }

      .back-to-home {
        text-align: center;
        display: block;
        margin: 0 auto;
        margin-bottom: 100px;
        width: 150px;
      }
    }
  }
}

@media (min-width: 40em) {
  html {
    body {
      font-size: 100%;
    }
  }
  .btn {
    width: auto;

    &.sml {
      width: 160px;
    }
  }
}

@media (max-width: 40em) {
  .front {
    .ad-wrapper {
      width: 300px;
      margin-top: 0.5em;
    }

    .top-house-ad {
      float: left;
      text-align: center;

      .inside {
        .group-advert-wrap {
          img {
            float: none;
            margin: 0 auto;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .ad-wrapper {
    width: auto;
    //margin: 1rem auto auto;
  }
}

@media (min-width: 64em) {
  html {
    body {
      font-size: 100%;

      &.admin-menu {
        margin-top: 29px !important;

        #mobile-nav {
          top: 29px;
          display: none !important;
        }
      }
    }
  }
  #admin-menu {
    display: block;
  }

  // For absolute positioned right column (maintain advert width)
  .relative-wrap {
    position: relative;
    min-height: 1000px;

    .left-col-wrapper {
      padding-right: 308px;
    }

    .right-col-wrapper {
      width: 300px;
      position: absolute;
      right: 8px;
      top: 0;
      bottom: 0;
    }
  }
}


div.status, table tr.ok {
  background-color: $amg-green !important;
  color: white;
  border: none;
  padding: 1.5em;
  padding-left: 4em;
  background-image: none;
  position: relative;

  &:after {
    font-family: "amg" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "r";
    position: absolute;
    left: 15px;
    top: 17px;
    color: white;
    font-size: 30px;
  }

  h2 {
    color: white !important;
    text-transform: uppercase;
  }
}

div.error, table tr.error {
  background: none;
  padding: 1.5em;
  padding-left: 4em;
  position: relative;
  background-color: $amg-pink !important;
  border-color: $amg-pink !important;
  color: #FFF;

  &:after {
    font-family: "amg" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "u";
    position: absolute;
    left: 15px;
    top: 17px;
    color: white;
    font-size: 30px;
  }

  ul {
    list-style: none;
  }

  li {
    list-style-type: none !important;
    color: white;
    //text-transform: uppercase;
    margin: 0.25rem 0;
  }
}

div.warning, table tr.warning {
  background: none;
  padding: 1.5em;
  padding-left: 4em;
  position: relative;
  background-color: $amg-amber !important;
  border-color: $amg-amber !important;
  color: #FFF;

  &:after {
    font-family: "amg" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\76";
    position: absolute;
    left: 15px;
    top: 17px;
    color: #FFF;
    font-size: 30px;
  }

  ul {
    list-style: none;
  }

  li {
    list-style-type: none !important;
    color: white;
    text-transform: uppercase;
    margin: 0.25rem 0;
  }
}

table tr.warning {
  &:after {
    content: "\76";
  }
}

table tr.error {
  &:after {
    content: "u";
  }
}

table tr.ok {
  &:after {
    content: "r";
  }
}

// Square bullets fix
.flex-control-nav {
  li a {
    overflow: hidden !important;
  }
}


// jQuery date picker styling
#ui-datepicker-div {
  background: #fff;
  border: 1px solid $light-blue;
  box-shadow: 0 2px 10px 1px rgba(163, 200, 218, .6);
  font-family: FrutigerLTPro-Roman, sans-serif;
  padding: .5em;
  width: auto;
  margin-top: -1px;
  border-top: 1px solid rgba(50, 163, 217, 0.5);
  /* border-radius: 5px 0 5px 5px; */
}

.ui-datepicker {
  .ui-datepicker-header {
    background: #fff;
    border: none;
    color: $dark-blue;
    padding: .5em 0 1em;
    margin-bottom: 1em;
    border-bottom: 1px solid $grey-border;
    border-radius: 0;

    a {
      top: 6px !important;
      right: 2px !important;

      &:hover {
        border: none;
        border-radius: 0;
        background: none;
        cursor: pointer;

        span:after {
          color: $light-blue;
        }
      }

      span { // Icon
        background: none !important;
        text-indent: 0;
        font-size: 0;

        &:after {
          font-family: "amg" !important;
          content: attr(data-icon);
          font-style: normal !important;
          font-weight: normal !important;
          font-variant: normal !important;
          text-transform: none !important;
          speak: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "f";
          color: $dark-blue;
          font-size: 14px;
          transition: .05s all linear;
        }
      }

      &.ui-datepicker-prev {
        left: 2px !important;
        right: auto !important;

        span:after {
          content: 'e';
        }
      }
    }
  }

  .ui-state-default {
    background: #fff;
    border: 2px solid transparent;
    padding: 0;
    border-radius: 50px;
    display: inline-block;
    width: 38px;
    height: 38px;
    line-height: 36px;
    text-align: center;
    transition: .05s all linear;
    font-size: 1.1em;

    &.ui-state-hover, &.ui-state-active {
      border-color: $light-blue;
      color: $dark-blue;
    }
  }

  tbody {
    border: none;
  }

  th {
    color: #000;
    font-weight: normal;
    font-family: $bold-font;
    font-size: 1.1em;
    padding: 0.2em .3em;
  }
}


#sliding-popup .popup-content {
  max-width: 95%;
}

#sliding-popup {
  height: auto !important;
}

#sliding-popup .popup-content #popup-text, #sliding-popup .popup-content #popup-buttons {
  max-width: 50%;
}

#sliding-popup .popup-content #popup-buttons button {
  margin: 5px;
}

@media screen and (max-width: 600px) {
  #sliding-popup .popup-content #popup-buttons button {
    margin-bottom: 0;
  }
  #sliding-popup .popup-content #popup-buttons, #sliding-popup .popup-content #popup-text {
    max-width: none;
  }
  #sliding-popup .popup-content #popup-buttons button.agree-button {
    margin-bottom: 0;
  }
  #sliding-popup .popup-content #popup-text {
    text-align: center;
  }
}

@media screen and (min-width: 1100px) {
  #sliding-popup .popup-content {
    max-width: 1100px;
  }
}


// IE10 & 11 Hack
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  :-ms-input-placeholder {
    color: transparent !important;
  }
  header#header .search-bar form input[type=text]:focus {
    color: transparent !important;
  }
  header#header .search-bar form input[type=text] {
    color: transparent !important;
  }
  #footer .right-col .menu-footer-links > ul {
    float: left;
    padding: 0;
    margin: 0;
  }
  #footer .right-col .top-footer > ul {
    float: left;
    padding: 0;
    margin: 0;
  }
  #footer-sitemap ul li {
    float: left;
  }

}
