@charset "UTF-8";



.local-info {
    .top-section {
        border-bottom: 1px solid $grey-border;
        h1 {
            color: $light-blue;
            font-size: 24px;
            font-family: $bold-font;
        }
        .field-name-field-local-address {
            padding-left: 7.5rem;
            margin-top: 30px;
        }
        iframe {
            float: left;
            height: 200px;
            width: 100%;
        }
        .info-buttons {
            padding-left: 7.5rem;
            margin-top: 30px;
            .btn {
                clear: both;
                float: left;
            }
        }
    }
    .content {
        ul {
            li {
                padding: 0.5em 0;
            }
        }
        h2 {
            padding: 1em 0;
        }
    }
    .tabs-local-info {
        padding: 0 0.5rem;
        ul {
            list-style: none;
            padding-left: 0 !important;
            li {
                display: inline;
                width: 25%;
                text-align: center;
                padding: 2rem;
                float: left;
                border-bottom: 5px solid #e6e6e6;
                cursor: pointer;
                a {
                    font-family: $bold-font;
                    color: $dark-blue !important;
                }
                &:hover {
                    border-color: $light-blue;
                    a {
                        color: $light-blue !important;
                    }
                }
                &:active {
                    border-color: $light-blue !important;
                    a {
                        color: $light-blue !important;
                    }
                }
            }
        }
    }
    .info-separator,
    #more {
        >h2 {
            border-bottom: 1px solid #e6e6e6;
            font-size:2em;
        }
        border-bottom: 1px solid #e6e6e6;
        margin-bottom: 50px;
        h3 {
            font-size: 1.75em;
        }
        h4 {
            padding-bottom: 0.5rem;
            margin-bottom: 0;
        }
        p {
            line-height: 2em;
            margin-bottom: 0;
        }
    }
}
#content {
    .backto {
        color: #d6d6d6;
        font-size: 0.75rem;
        line-height: 3;
        float: right;
    }
}
@media (min-width: 64em) {
    .local-info {
        .top-section {
            h1 {
                font-size: 55px;
                margin-top: 20px;
                padding-left: 2rem;
            }
            iframe {
                height: 350px;
            }
        }
    }
}
@media (max-width: 64em) {
    .local-info .top-section h1 {
        font-size: 40px;
		margin-top: 0;
    }
}
@media (min-width: 52em) {
    .local-info {
        .top-section {
            iframe {
                width: 100%;
            }
        }
        h1,
        .field-name-field-local-address,
        .info-buttons {
            width: 33.333%;
            float: left;
        }
    }
}
@media (max-width: 52em) {
	.local-info {
		.top-section {
		    .field-name-field-local-address,
		    .info-buttons {
		        padding-left: 2rem;
		    }
		}
	    .tabs-local-info {
	        margin: 0 3.5rem;
			ul li {
		        width: 100%;
		        padding: 1rem;
		        border-width: 1px;
		        text-align: left;
				padding-left: 0;
		    }
	    }
	}
}
@media (max-width: 40em) {
    .content.px4 {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .local-info {
		.top-section {
	        .field-name-field-local-address,
	        .info-buttons {
	            padding-left: 0;
	            font-size: 1.2em;
	        }
		}
        .tabs-local-info ul li {
            width: 100%;
            padding: 1rem;
            border-width: 1px;
            text-align: left;
            padding-left: 0;
        }
        .tabs-local-info {
            margin: 0 1.5rem;
        }
    }
}
