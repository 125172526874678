@charset "UTF-8";

// Arts Club Styling
body {
	&.domain-academymusicgroup-com-artsclubliverpool, &.domain-academymusicgroup-com  {
		.selectize-dropdown {
			border-color: $arts-medium-grey;
			background-color: $arts-medium-grey;
			.active {
				background-color: $arts-medium-grey;
			}
		}
		div.form-item {
			div.password-suggestions {
				border-color: $arts-medium-grey;
			}
		}
		form {
			textarea:focus {
				border-color:$arts-medium-grey;
			}
			label {
				color:$arts-medium-grey;
			}
			input[type=submit] {
				background-color:$arts-medium-grey;
				&:hover {
					background-color:$arts-light-grey!important;
				}
			}
			input[type=datetime-local], input[type=datetime], input[type=email], input[type=month], input[type=number], input[type=password], input[type=search], input[type=tel], input[type=text], input[type=time], input[type=url], input[type=week] {
				&:focus {
					border-color:$arts-medium-grey;
				}
			}
		}
		.selectize-control {
			&.error {
				.selectize-input {
					border-color:$arts-error;
				}
				.selectize-dropdown {
					z-index:9999;
					background: $arts-medium-grey !important;
					&:after {
						display: none;
					}
				}
			}
			.selectize-input {
				&.input-active {
					border-color: $arts-medium-grey;
					.selectize-dropdown {
						border-top: 1px solid $arts-medium-grey;
					}
				}
			}
		}
	}
}

form {
	input[type=datetime-local], input[type=datetime], input[type=email], input[type=month], input[type=number], input[type=password], input[type=search], input[type=tel], input[type=text], input[type=time], input[type=url], input[type=week] {
		border:1px solid $grey-border;
		background-color:#FFF;
		border-radius:5px;
		height:50px;
		line-height:50px;
		padding:10px;
		width:100%;
		float:left;
		transition: all 0.1s linear;
		&:focus {
			border-color: $light-blue;
			// -webkit-box-shadow: 0 0 0 1px $dark-blue;
			// box-shadow: 0 0 0 1px $dark-blue;
		}
	}
	textarea {
		border:1px solid $grey-border;
		background-color:#FFF;
		border-radius:5px;
		padding:10px;
		width:100%;
		float:left;
		transition: all 0.1s linear;
		&:focus {
			border-color: $light-blue;
			// -webkit-box-shadow: 0 0 0 1px $dark-blue;
			// box-shadow: 0 0 0 1px $dark-blue;
		}
	}
	input[type=checkbox] {
		float: left;
		/* height: 50px; */
		/* line-height: 50px; */
		margin-right: 10px;
		margin-left: 0;
		margin-top:16px
	}
	label, legend {
		color:$dark-blue;
		font-family:$regular-font;
		width:100%;
		float:left;
		padding:15px 15px 0 0;
		font-weight:normal;
		&.option {
			width:60%;
		}
	}
	input[type=submit] {
		background-color:$light-blue;
		font-family:$regular-font;
		color:#FFF;
		border:0;
		height:50px;
		line-height:50px;
		text-align:center;
		padding:0 25px;
		min-width:150px;
		border-radius:5px;
		transition: all 0.1s linear;
		&:hover {
			background-color:darken($light-blue,10);
		}
		&:active {
			background-color:lighten($light-blue,10);
		}
	}
	input:-webkit-autofill {
	    -webkit-box-shadow: 0 0 0px 1000px white inset;
	}
	input[disabled] {
		border:0;
	}
	fieldset {
		border:0;
		padding:0;
		.fieldset-wrapper {
			float:left;
			width:100%;
		}
		&.date-combo {
			.date-no-float {
				margin:0;
				width:100%;
			}
			.container-inline-date {
				.form-type-date-popup {
					width:100%;
					margin:0;
					.form-item {
						width:100%;
					}
					.date-padding {
						padding:0;
						width:100%;
						.form-item {
							width:100%;
							input {
								float:left;
								width:100%;
								margin:0;
							}
						}
						label {
							display:none;
						}
					}
				}
				.description {
					padding-left:0;
				}
			}
		}
	}
	.selectize-control {
		.selectize-input {
			transition:0.1s border-color linear;
			&.input-active {
				border-color: $light-blue;
				.selectize-dropdown {
					border-top: 1px solid $light-blue;
				}
			}
			> .item {
				font-size: 1rem;
				color:#000;
				padding-right: 2px;
			}
			input {
				float: none;
				height: auto;
				font-size: 1rem;
				width: 100% !important;
			}
		}
		&.mutli {
			.selectize-input {
			    padding: 0 10px;
			    line-height: 50px;
			    height:auto;
			    &.has-items {
				    padding: 0 10px;
				    line-height: 50px;
			    }
			}
		}
	}
	.selectize-control.multi .selectize-input>div {
		height:30px;
		line-height:30px;
	}
	div.form-item {
		width:100%;
		float:left;
		margin-bottom:0.5em;
		.description {
			float:left;
			width:100%;
			margin-top:0.5em;
		}
		div.password-suggestions {
			float:right;
			width:100%;
			padding: 0.7em 1em;
			border-radius: 5px;
			border-color: $amg-pink;
			font-size: 1em;
			font-family: $bold-font;
			border-width: 2px;
			ul {
				font-size: 0.8em;
				margin-top: 0.5em;
				line-height: 1.4;
				font-family: $regular-font;
			}
		}
	}
	.form-actions {
		float:left;
		width:100%;
	}
	#edit-actions {
		text-align:right;
	}
	.password-strength, .password-confirm, .password-suggestions {
		float: right;
		width: 100%;
		margin-bottom: 1em;
		margin-top:0;
	}
	#addressfield-wrapper {
		legend {
			display:none;
		}
		.fieldset-wrapper {
			width:100%;
		}
	}
	.selectize-control {
		&.error {
			background:none !important;
			padding: 0;
			.selectize-input {
				border-color:$amg-pink;
			}
			.selectize-dropdown {
				z-index:9999;
				background: $light-blue !important;
				&:after {
					display: none;
				}
			}
			.selectize-dropdown-content {
				// background:#FFF;
			}
		}
	}
	.selectize-control {
		float:left;
		width:100%;
		&.multi {
			.selectize-input {
				height:auto;
				padding:14px 15px;
			}
		}
		.selectize-input {
			padding:15px;
			height:50px;
			border: 1px solid #e5e5e5;
			box-shadow:none;
		}
	}
	.selectize-dropdown {
		margin: 0 !important;
		border-top: none !important;
		width: 100% !important;
		border-color: $light-blue;
		background-color: $light-blue;
		color:#fff;
		.active {
			background-color: #49AFE0;
		}
	}
	.selectize-dropdown .optgroup-header, .selectize-dropdown [data-selectable] {
		color:#fff;
		padding: 5px 15px;
		font-size: 1.1em;
	}
	#edit-back {
		float:left;
	}
}

form .selectize-control.error .selectize-dropdown, form .selectize-dropdown.error .selectize-dropdown {
	border-color:$amg-pink;
}

.selectize-dropdown .optgroup-header, .selectize-dropdown [data-selectable] {
	color:#303030;
}

.resizable-textarea .grippie {
	background-color: transparent;
	border-color: transparent;
	opacity: 0.5;
	transition:0.2s opacity linear;
	background-position: bottom center;
	height: 11px;
	&:hover {
		opacity: 1;
	}
}

.password-strength {
	font-size: 0.9em;
}
.password-strength-text {
	font-weight: normal;
	font-family: $bold-font;
}
.password-indicator {
	margin-top: 0.3em;
	background-color: $grey-border;
	div {
		transition: 0.3s width ease-out;
	}
}

form .register-form-wrapper label.error {
	font-size: 0.9em;
}

@media (min-width: 52em) {
	form {
		input[type=datetime-local], textarea, input[type=datetime], input[type=email], input[type=month], input[type=number], input[type=password], input[type=search], input[type=tel], input[type=text], input[type=time], input[type=url], input[type=week] {
			width:70%;
		}
		label, legend {
			width:30%;
		}
		fieldset {
			.fieldset-wrapper {
				width:70%;
			}
		}
		div.form-item {
			.description {
				float:left;
				padding-left:30%;
			}
			div.password-suggestions {
				margin-left:30%;
				width:70%;
			}
		}
		.password-strength, .password-confirm, .password-suggestions {
			float: right;
			width: 100%;
			margin-bottom: 1em;
			margin-top:0;
			padding-left:30%;
		}
		input[type=checkbox] {
			margin-left:30%;
		}
		.selectize-control {
			width:70%;
		}
	}
}
