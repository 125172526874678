@charset "UTF-8";

.amg-streetteam-form-wrapper {
	padding:0 0.5em;
	.top-hero-image {
		&.street-team {
			background-image:url(#{$images}/optimized/street-team-courteeners.jpg);
			background-size:cover;
			height:200px;
			width:100%;
			position:relative;
			h1 {
				position:absolute;
				top:35%;
				color:#FFF;
				text-align:center;
				font-size:36px;
				width:100%;
			}
		}
	}
	.form-wrap {
		.form-textarea-wrapper {
			width:100%;
		}
	}
}


@media (min-width: 40em) {
	#content {
		.amg-streetteam-form-wrapper {
			.top-hero-image {
				&.street-team {
					height:300px;
					h1 {
						margin:10px 0;
						font-size:75px;
					}
				}
			}
		}
	}
}

@media (min-width: 52em) {
	#content {
		.amg-streetteam-form-wrapper {
			.top-hero-image {
				&.street-team {
					height:400px;
				}
			}
			.form-wrap {
				.form-textarea-wrapper {
					width:70%;
					float:left;
				}
			}
		}
	}
}
