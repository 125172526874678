

body {
  &.domain-academymusicgroup-com,
  &.node-type-corporate-venue-info {


    background-color: #000;


    header#header {
      .top-head, .search-bar {
        background-color:$corporate-header;
        .search-bar {
          display:none;
        }
      }
      .top-links {
        background-color: transparent;
      }
    }
    .secondary-m {
      display:none;
    }

    #desktop-nav {
      ul {
        > li {

          a {
            padding: 0 15px;

            &:hover,
            &:active,
            &.active {
              background-color: $arts-light-grey;
              color: #ffffff;
            }
          }
        }
      }
    }

    &.page-content-403 .top-hero-image .hero-content-wrap h1, &.page-content-404 .top-hero-image .hero-content-wrap h1 {
      background-image: none;
      -webkit-text-fill-color: $arts-dark-grey;
      color: $arts-dark-grey;
    }

    //#content {
    // Change error color to O2 shade of red
    form .form-item input.error, form .form-item textarea.error, form .form-item select.error {
      border: 2px solid $arts-error;
    }

    div.error, table tr.error {
      background-color: $arts-error !important;
      border-color: $arts-error !important;
    }

    label.error {
      color: $arts-error;
    }

    div.status, table tr.ok {
      background-color: $arts-ok !important;
    }

    //a {
    //  color: $arts-medium-grey;
    //}

    .follow-panel {
      background-color: $arts-light-grey;

      .follow-links {
        border-color: #9D9D9D;;

        li {
          border-color: #9D9D9D;

          &.twitter {
            border-bottom: 1px solid #9D9D9D;
          }

          &.instagram {
            border-right: 1px solid #9D9D9D;
          }
        }
      }
    }

    .btn {
      &.white {
        &:hover {
          background-color: $arts-medium-grey;
          border-color: $arts-medium-grey;
        }
      }

      &.blue {
        background-color: $arts-dark-grey;
        color: #FFF;

        &:hover {
          background-color: darken($arts-dark-grey, 10%);
          color: #FFF;
        }
      }

      &.dark-blue {
        background-color: #666666;
        color: #FFF;
      }

      &.purple {
        color: #FFF;
        background-color: #666666;
      }
    }

    footer {
      .left-col {
        background-color: $arts-medium-grey;
      }

      .right-col {
        //.top-footer {
        //  background-color: #FFF;
        //}

        .bottom-footer {

          background-color: $arts-darker-grey;
        }
      }

      .menu-footer-links {
        background-color: #666666;
      }

      .menu-venue-list {
        background-color: $arts-darker-grey;
      }
    }


  }
}

