@charset "UTF-8";


footer {
  color: #FFF;

  p {
    color: #FFF;
    margin-top: 7px;
  }

  .left-col {
    background-color: $medium-blue;

    p {
      font-size: 80%;
    }

    a {
      color: $light-blue;
    }

    img {
      max-height: 50px;
    }
  }

  .right-col {
    //.top-footer {
    //  background-color: $light-blue;
    //}

    .bottom-footer {
      background-color: $dark-blue;
      height: 70px;

      a {
        color: #fff;
      }

      .social-media {
        li {
          margin-left: 1em;
          display: inline-block;
          border: 2px solid white;
          height: 32px;
          width: 32px;
          border-radius: 32px;
          text-align: center;

          a {
            color: #FFF;
          }

          i {
            display: inline-block;
            padding: 2px;
            line-height: 32px;
            font-size: 18px;
          }
        }
      }
    }
  }
}

//.fs-root-link {
//  margin-bottom: 20px;
//  float: left;
//  font-family: $bold-font;
//}


footer {
  .right-col {

    .footer-menus {
      padding: 2em 1em 1em;

      > ul {
        float: left;
        -moz-column-count: 4;
        //-moz-column-gap: 20px;
        -webkit-column-count: 4;
        //-webkit-column-gap: 20px;
        column-count: 4;
        //column-gap: 20px;

        margin: 0;
        padding: 0;

        > li {
          float: left;

          width: 100%;
          padding: 0;
          margin: 0;

          //height: 100%;

          -webkit-column-break-inside: avoid;
          page-break-inside: avoid; /* Firefox */
          break-inside: avoid-column; /* CSS3, IE10+ */

          //
          ////&.menu-1051,
          //&.partners {
          //  //height: 58%;
          //  //height: 60%;
          //}
          //
          ////&.menu-3759,
          //&.charity {
          //  //height: 42%;
          //  //height: 60%;
          //}
          //
          ////&.menu-1053,
          //&.sitemap {
          //  //height: 58%;
          //  //height: 60%;
          //}
          //
          ////&.menu-3758,
          //&.legal {
          //  //height: 60%;
          //  //height: 42%;
          //}


          &.expanded {
            list-style: none;

            > a {
              width: 100%;
              font-family: $bold-font;
              cursor: default;
              font-size: 16px;
              margin-bottom: 0.4em;
              display: block;
            }
          }


          ul {

            clear: both;

            margin: 0 0 1.5em 0;
            padding: 0;


            li {

              clear: both;
              margin-left: 0;
              list-style: none;
              margin-top: 0;
              margin-bottom: 0.2em;

              a {

                font-size: 0.8em;
                float: none;
                display: block;
              }
            }
          }
        }
      }
    }

    .menu-footer-links {

      background-color: $light-blue;

      ul {

        //padding: 0;
      }

      > ul {
        height: 220px;

        a {
          color: #ffffff;
        }
      }
    }

    .menu-venue-list {
      background-color: $dark-blue;

      > ul {
        height: 250px;
        //margin: 0;
        //padding: 0;

        a {
          color: #FFFFFF
        }
      }
    }
  }


  .copyright {
    a {
      font-size: 0.8em;
    }
  }
}

.page-amg-landing {
  footer {
    .legal {
      height: 100%;
    }
  }
}


/**********/

@media (max-width: 60em) {


  footer {
    .right-col {
      .menu-footer-links {

        > ul {
          -moz-column-count: 3;
          //-moz-column-gap: 20px;
          -webkit-column-count: 3;
          //-webkit-column-gap: 20px;
          column-count: 3;
          //column-gap: 20px;
          //height: 200px;
        }
      }

      .menu-venue-list {
        > ul {
          column-count: 3;
          height: 300px;
        }
      }
    }


  }

  .footer-menus ul > li {
    &.partners,
    &.charity,
    &.sitemap,
    &.legal {
      height: auto;
    }
  }

}


/**********/


@media (max-width: 40em) {
  footer {


    .right-col {
      .menu-footer-links {

        > ul {
          -moz-column-count: 2;
          //-moz-column-gap: 20px;
          -webkit-column-count: 2;
          //-webkit-column-gap: 20px;
          column-count: 2;
          height: auto;
        }
      }

      .menu-venue-list {
        > ul {
          -moz-column-count: 2;
          //-moz-column-gap: 20px;
          -webkit-column-count: 2;
          //-webkit-column-gap: 20px;
          column-count: 2;
          //column-gap: 20px;
          height: auto;
        }
      }
    }


    //.menu-footer-links {
    //  > ul {
    //    height: 200px;
    //  }
    //}
    //
    //.menu-venue-list {
    //  > ul {
    //    height: 220px;
    //  }
    //}

    //font-size: 1.2em;
    //
    //.right-col .bottom-footer {
    //  height: auto;
    //
    //  p {
    //    font-size: .9em;
    //  }
    //}
    //
    //.bottom-footer p, ul.social-media {
    //  float: none;
    //  text-align: center;
    //}
    //
    //ul.social-media {
    //  margin-top: 1em;
    //}
    //
    //.left-col.footer-panel {
    //  > div {
    //    margin: 0;
    //    text-align: left;
    //    padding-left: 0.3em;
    //    padding-right: 0.5em;
    //
    //    > p {
    //      margin-bottom: 0;
    //    }
    //  }
    //
    //  > a {
    //    padding: 1em 0.3em;
    //    text-align: left;
    //  }
    //}
  }
}


/**********/

/*
@media (max-width: 831px) {
  body {
    &.domain-academymusicgroup-com {
      .footer-menus > div > ul > li {
        border-bottom: 1px solid $arts-medium-grey;
      }
    }
  }
  .top-footer.p2 {
    padding: 0;
  }
  .footer-menus {
    margin: 0;
  }
  .footer-menus > div {
    > ul {
      // Menu item
      > li {
        margin: 0;
        width: 100%;
        position: relative;
        border-bottom: 1px solid #73bee6;

        &:last-child {
          border: none;
        }

        &:before {
          position: absolute;
          top: 0;
          right: 0;
        }

        &:before {
          content: "d";
          font-family: "amg" !important;
          font-style: normal !important;
          font-weight: normal !important;
          font-variant: normal !important;
          text-transform: none !important;
          speak: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          top: 23px;
          right: 1em;
        }

        > a {
          padding: 20px;
          float: left;
          width: 100%;
          z-index: 1;
          font-size: 1.2em;

          &.fs-root-link {
            float: none;
            margin: 0;
            display: block;
            padding: 1em;
            font-size: 1.2em;
            position: relative;
            z-index: 1;
          }

          &.active {
            float: none;
            margin: 0;
            display: block;
            padding: 1em;
            font-size: 1.2em;
            position: relative;
            z-index: 1;
          }
        }

        &.open {
          &:before {
            content: "g";
          }

          > ul {
            display: block;
          }
        }

        // Submenu
        > ul {
          display: none;
          padding: 0 1em 1em;
          float: none;
          margin-top: -0.5em;
          // Submenu item
          > li {
            float: none;
            width: auto;
            padding: .2em 0;
          }
        }
      }
    }
  }
}
*/


/**********/

//@media (max-width: 1555px) {
//  footer {
//    display: inline-block;
//    padding-bottom: 30px;
//  }
//}
