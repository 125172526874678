
$test: #ffcc00;

// Variables
$images : "/sites/all/themes/AMG/images";

// Fonts
$font-size:100%;
$line-height: $font-size * 1.6;
$light-font: 'FrutigerLTPro-Light',sans-serif;
$regular-font: 'FrutigerLTPro-Roman',sans-serif;
$bold-font: 'FrutigerLTPro-Bold',sans-serif;

// Colors
$dark-blue:#00032d;
$medium-blue:#0b1b54;

$light-blue:#32a3d9; // amg light blue

$purple:#b23391;

$barclaycard: #5224a0;

//$grey-border:#e5e5e5;

$amg-green:#99cc22;
$amg-pink:#CC0099;
$amg-amber:#EA9923;

$arts-dark-grey:#141414;
$arts-medium-grey:#1a1a1a;
$arts-light-grey:#b3b3b3;
$arts-darker-grey:#000000;
$arts-medium-grey-font:#999;
$arts-yellow:#f1d34d;
$arts-error:red;
$arts-ok:green;

$corporate-header:#151515;

// Borders
$grey-border:#e6e6e6;

// Text colors
$grey-font:#808080;

// Social Media
