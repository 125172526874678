@charset "UTF-8";

.register-form-wrapper {
	.form-item-status {
		display:none;
	}
	.form-item-notify {
		display:none;
	}
	#top-submit {
		padding: 0;
		line-height: initial;
		background: none;
		color: #32a3d9;
		text-decoration: underline;
		margin: 20px 0 50px;
	}
	.date-combo {
		margin-top:1em;
		float:left;
		width:100%;
	}
	label {
		margin-bottom:10px;
		&.error {

		}
	}
	.multipage-pane-title {
		display:none;
		color:$dark-blue;
		font-size:24px;
		em {
			font-style:normal;
		}
	}
	h1 {
		color:$dark-blue;
		font-size:24px;
	}
	.fieldset-description {
		margin:30px 0;
	}
	.first-page-desc {
		.fieldset-description {
			margin-bottom:30px;
		}
	}
	.container-inline-date {
		.form-item {
			width:100%;
			#edit-field-birth-date {
				width:100%;
				input {
					width:100%;
					margin:0;
				}
			}
		}
	}
	.multipage-button {
		float:right;
		margin:40px 0;
		border-top:1px solid $grey-border;
	    padding: 20px 0;
	    width: 100%;
		.multipage-link-previous {
			float:left;
		}
		.multipage-link-next {
			float:right;
		}
	}
	.form-actions {
		margin-top:40px;
	}
	.multipage-controls-list {
		input[type='button'], #edit-submit {
			background:$light-blue;
			color:#FFF;
			border-radius:3px;
			border:0;
			height:50px;
			line-height:50px;
			text-shadow:none;
			text-shadow:none;
			padding:0 20px;
			font-size:15px;
			color:$light-blue;
			color:#FFF;
			text-transform:capitalize;
			transition: all 0.1s linear;
			&:hover {
				background:$dark-blue;
			}
		}
	}
	.form-item-field-terms-conditions-und {
		position:relative;
		label {
			&.error {
				position: absolute;
				bottom: -20px;
			}
			&.option {
				width:90%;
			}
		}
	}
}

.date-no-float {
	width:100%;
	#edit-field-birth-date-und-0-value {
		width:100%;
		input {
			clear:none;
			width:100%;
			margin-right:0;
		}
	}
}

#user-profile-form {
	.container-inline-date {
		.form-item {
			width:100%;
			input {
				width:100%;
				margin-right:0;
			}
		}
	}
}

.section-registration-thank-you, .page-content-thank-you-signing {
	#content {
		.basic-page-container {
			padding:100px 0;
			.top-hero-image {
				.hero-content-wrap {
					position:relative;
					h1 {
						color:$medium-blue;
						font-size:42px;
					}
				}
			}
			.body-copy {
				padding-top:0;
				p {
					text-align:center;
				}
			}
		}
	}
}

@media (min-width: 40em) {
	.register-form-wrapper {
		h1 {
			font-size:42px;
		}
		>.form-wrapper {
			padding:4em;
		}
		.multipage-pane-title {
			font-size:42px;
			padding-bottom:30px;
		}
	}
}

@media (min-width: 52em) {
	form {
		.register-form-wrapper {
		    input {
				width:40%;
			}
			input[type=submit], input[type=checkbox] {
				width:auto;
			}
			.password-strength {
				padding-right: 30%;
			}
			.selectize-control {
				width:40%;
			}
			.date-combo {
				.fieldset-wrapper {
					width:40%;
				}
			}
			label {
				&.error {
					padding-left:20px;
					float:right;
				}
			}
			.form-item {
				.password-suggestions {
					margin-left: 30%;
					width: 40%;
					margin-right: 30%;
				}
			}
			.container-inline-date {
				.form-item {
					#edit-field-birth-date {
						width:40%;
					}
					#edit-field-birth-date-datepicker-popup-0 {
						width:100%;
					}
					.description {
						padding-left:30%;
					}
				}
			}
			.form-item-field-terms-conditions-und {
				label {
					&.option {
						width:30%;
					}
				}
			}
			.form-item-field-terms-conditions-und {
				position:relative;
				label {
					&.error {
						position: relative;
						bottom: initial;
					}
				}
			}
			#edit-actions {
				padding-right:30%;
			}
		}
	}
	.date-no-float {
		#edit-field-birth-date-und-0-value {
			input {
				width:40%;
			}
		}
	}
	#user-profile-form {
		.container-inline-date {
			.form-item {
				input {
					width:70%;
				}
			}
		}
	}
}
