
.item-list {
  .pager {

    //padding-left: 1.5rem;
    //padding-right: 4rem;
    padding: 2em 0 1em;

    border-top: 1px solid #eee;

    margin: 1em 0 3em;

    font-size: 14px;

    a {
      color: $dark-blue;
    }

    .pager-current {
      color: $light-blue;
    }

    li {
      padding: 0;
      margin: 0;
      display: inline-block;
    }

    .pager-current {
      padding: 8px 10px;
    }

    .pager-first,
    .pager-previous,
    .pager-item,
    .pager-next,
    .pager-last {

      a {
        text-decoration: none;
        text-transform: uppercase;
        border: 1px solid #eee;
        border-radius: 5px;
        padding: 10px 16px 10px;
        line-height: 1em;
        font-size: 14px;

        &:hover {
          background: $light-blue;
          color: white!important;

          &:before,
          &:after {
            color: white;
          }
        }

      }

    }

    .pager-next,
    .pager-last {

      a:after {
        position: relative;
        top: 2px;
        padding-left: 7px;
        color: $light-blue;
        font-size: 0.75rem;
        content: "f";
        font-family: "amg" !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-variant: normal !important;
        text-transform: none !important;
        speak: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

      }
    }

    .pager-last a:after {
      content: "ff";
    }

    .pager-previous,
    .pager-first {
      a:before {
        position: relative;
        top: 2px;
        padding-right: 7px;
        color: $light-blue;
        font-size: 0.75rem;
        content: "e";
        font-family: "amg" !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-variant: normal !important;
        text-transform: none !important;
        speak: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }

    .pager-first a:before {
      content: "ee";
    }

    .pager-last, .pager-first, .pager-current, .pager-ellipsis {
      //display: none;
    }
  }
}

