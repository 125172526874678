@charset "UTF-8";

#content {	
	.view-wishlist-page {
		width:100%;
		margin:0 auto;
		padding-bottom:50px;
		.view-empty {
			text-align:center;
		}
		.view-content {
			width:100%;
			margin:0 auto;
			.wishlist-top {
				border-bottom:1px solid $grey-border;
				.share-links {
					text-align:right;
					span {
						color:$grey-font;
					}
					ul {
						padding:0;
						display:inline-block;
						li {
							display:inline-block;
							list-style-type:none;
							padding:0 0.5em;
							a {
								color:$light-blue;
							}
						}
					}
				}
			}
			.node-event-calendar {
				border-bottom:1px solid $grey-border;
				font-size:1rem;
				.flag-wishlist {
					a {
						float:right;
					}
				}
			}
		}
		.event-item {
			.event-actions {
				margin-top:10px;
			}
		}
	}
}

@media (min-width: 40em) {
	#content {
		.view-wishlist-page {
			.view-content {
				width:70%;
			}
			.event-item {
				.event-actions {
					margin-top:0;
				}
			}
		}
	}
}
