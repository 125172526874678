@charset "UTF-8";

// Arts Club Styling
body {
  &.domain-academymusicgroup-com-artsclubliverpool.page-search {
    #content-area {
      background-color: $arts-darker-grey;
      color: #FFF;

      .search-page-wrap .head {
        background-color: $arts-darker-grey;
      }

      .search-result {
        border-bottom: 1px solid $arts-light-grey;
      }
    }
  }
}

html {
  &.js {
    .page-search {
      fieldset {
        display: none;

        &.collapsed {
          height: auto;
          padding-bottom: 1em;
        }
      }
    }
  }
}

.page-search {
  #content-area {
    background-color: $dark-blue;

    .search-form {
      label {
        display: none;
      }

      div.form-item {
        width: auto;
        float: none;

        input {
          width: auto;
          padding: 10px 15px;
        }
      }
    }
  }

  //#content {
  //  .pager {
  //    padding-left: 0;
  //
  //    li {
  //      list-style: none;
  //      display: inline-block;
  //      padding: 0;
  //      vertical-align: baseline;
  //
  //      a {
  //        display: block;
  //        padding: 0.5em;
  //
  //        &:hover {
  //          color: #fff;
  //        }
  //      }
  //
  //      &.pager-current {
  //        padding: 0.5em;
  //        color: #fff;
  //        font-weight: normal;
  //      }
  //    }
  //
  //    .pager-ellipsis {
  //      color: #88A4B1;
  //    }
  //
  //    .pager-next {
  //      float: right;
  //
  //      a {
  //        &:hover {
  //          color: #FFF;
  //
  //          &:after {
  //            color: #FFF !important;
  //          }
  //        }
  //
  //        &:after {
  //          color: #32a3d9;
  //          font-size: 9pt;
  //          font-size: .75rem;
  //          content: "f";
  //          font-family: amg !important;
  //          font-style: normal !important;
  //          font-weight: 400 !important;
  //          font-variant: normal !important;
  //          text-transform: none !important;
  //          speak: none;
  //          padding-left: 5px;
  //          line-height: 1;
  //          -webkit-font-smoothing: antialiased;
  //          -moz-osx-font-smoothing: grayscale;
  //          display: inline-block;
  //          vertical-align: middle;
  //          margin-left: 5px;
  //        }
  //      }
  //    }
  //
  //    .pager-previous {
  //      float: left;
  //
  //      a {
  //        &:hover {
  //          color: #FFF;
  //
  //          &:before {
  //            color: #FFF !important;
  //          }
  //        }
  //
  //        &:before {
  //          color: #32a3d9;
  //          font-size: 9pt;
  //          font-size: .75rem;
  //          content: "e";
  //          font-family: amg !important;
  //          font-style: normal !important;
  //          font-weight: 400 !important;
  //          font-variant: normal !important;
  //          text-transform: none !important;
  //          speak: none;
  //          padding-right: 5px;
  //          line-height: 1;
  //          -webkit-font-smoothing: antialiased;
  //          -moz-osx-font-smoothing: grayscale;
  //          display: inline-block;
  //          vertical-align: middle;
  //          margin-right: 5px;
  //        }
  //      }
  //    }
  //
  //    .pager-last, .pager-first {
  //      display: none;
  //    }
  //  }
  //}

}

.search-page-wrap {
  .head {
    background-color: #060e42;
    padding: 36px;
    margin-bottom: 1em;

    h2 {
      margin: 0;
      color: #fff;
    }

    span.no {
      float: right;
      color: #454d7b;
    }
  }
}

.page-search #content .search-page-wrap {
  &.no-results {
    color: #fff;

    ul {
      padding-left: 3em;
    }
  }
}

.page-search #content {
  .search-form {
    float: right;
    padding-top: 25px;
    padding-right: 2em;
    display: none;

    input[type=submit] {
      margin-left: 10px;
    }
  }

  .search-results {
    padding: 0;
  }

  .search-loading {
    text-align: center;
    font-size: 20px;

    p {
      color: #fff;
      padding: 30px 0;
    }
  }

  .sector_1 {
    span {
      display: none;
    }
  }

  .sector_2, .sector_3 {
    display: none;
  }

  .narrow-down-results {
    text-align: center;
    background: #060e42;
    padding: 20px;
    margin: 0 0 20px;

    p {
      margin: 0;
      color: #fff;
    }
  }

  .search-result {
    border-bottom: 1px solid #1f2247;
    position: relative;

    &:last-child {
      border: none;
    }

    // Highlight
    i.h {
      background: #32a3d9;
      font-style: normal;
    }

    .content-type {
      color: #fff;
      padding-left: 36px;
    }

    .image {
      text-align: center;
      min-height: 1px;

      a {
        display: block;

        margin-left: 1em;
        margin-right: 1em;
      }
    }

    .event-info {
      color: #fff;
      font-size: 0.9em;
      text-align: left;
      padding-left: 1em;

      > div > span {
        font-family: $bold-font;
        margin-bottom: 2px;
        display: block;
      }

      div p {
        margin-left: 20px;
      }

      .label:before {
        margin-right: 5px;
        vertical-align: top;
      }

      p {
        font-size: 1em;
        color: #fff;

        span {
          display: block;
        }
      }
    }

    .title {
      margin: 0 0 0.5em;
      font-size: 1.5em;

      a {
        color: #fff;
        font-family: $bold-font;
      }
    }

    h4 {
      color: #fff;
      margin: 0 0 15px;
    }

    .description p {
      font-size: 0.9em;
      line-height: 1.4;
    }

    .description, .description p, .venue {
      color: #fff;
    }

    .link {
      text-align: center;

      a {
        color: #fff;
        position: absolute;
        top: 50%;
        display: block;
        margin-top: -32px;
        padding: 20px;
        //margin-left: 3em;
        right: 0;
      }
    }
  }
}


.page-search #content .search-result {
  list-style: none;
  margin: 0;
  margin-bottom: 1em;
}

body.page-search .wrapper #main {
  background-color: transparent;
}

.page-search #content .search-page-wrap .no-results {
  color: #fff;
}

.page-search #content .search-page-wrap .no-results ul {
  padding-left: 3em;
}

@media (max-width: 52em) {
  .search-page-wrap .head {
    h2 {
      font-size: 1.4em;
      line-height: 1.4;
    }
  }
}

@media (max-width: 64em) {
  .search-form input[type="image"] {
    display: none;
  }
}

@media (max-width: 40em) {
  .search-results li {
    padding: 0 15px;
  }
  .search-page-wrap .head {
    padding: 20px;
  }
  .page-search #content {
    .search-form {
      float: none;
      padding: 15px;
      background-color: #060e42;

      div.form-item input {
        width: 75%;
      }

      input[type=submit] {
        margin-left: 0;
        width: 25%;
        min-width: 0;
      }
    }

    .search-result {

      .title,
      h4,
      .description {
        margin-left: 1em;
        margin-right: 1em;
      }

      .event-info {
        font-size: 0.8em;
        padding: 0.5em 0;

        margin-left: 1em;
        margin-right: 1em;
      }

      .event-info p {
        font-size: 1em;
      }

      .link {
        display: none;
      }

      .image {
        text-align: left;
      }

      .image a {
        //margin-right: 0;
        margin-bottom: 1em;
      }
    }
  }
}

@media (max-width: 400px) {
  .page-search #content .search-form div.form-item input {
    width: 70%;
  }
  .page-search #content .search-form input[type=submit] {
    width: 30%;
  }
}
